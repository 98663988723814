<template>
  <DocumentWrapper :title="t('politic')">
    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      1. Общие положения
    </h4>

    <ul class="text-justify list-unstyled">
      <li class="list-unstyled">
        Настоящая политика обработки персональных данных составлена в
        соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ
        «О персональных данных» и определяет порядок обработки персональных
        данных и меры по обеспечению безопасности персональных данных АНО
        «Институт проблем образовательной политики Эврика» (далее – Оператор).
      </li>
      <li class="list-unstyled">
        Оператор ставит своей важнейшей целью и условием осуществления своей
        деятельности соблюдение прав и свобод человека и гражданина при
        обработке его персональных данных, в том числе защиты прав на
        неприкосновенность частной жизни, личную и семейную тайну.
      </li>
      <li class="list-unstyled">
        Настоящая политика Оператора в отношении обработки персональных данных
        (далее – Политика) применяется ко всей информации, которую Оператор
        может получить о посетителях веб-сайта
        <a href="https://navigator.eurekacenter.ru"
          >https://navigator.eurekacenter.ru</a
        >
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      2. Основные понятия, используемые в Политике
    </h4>

    <ul class="text-justify list-unstyled">
      <li class="list-unstyled">
        Автоматизированная обработка персональных данных – обработка
        персональных данных с помощью средств вычислительной техники;
      </li>
      <li class="list-unstyled">
        Блокирование персональных данных – временное прекращение обработки
        персональных данных (за исключением случаев, если обработка необходима
        для уточнения персональных данных);
      </li>
      <li class="list-unstyled">
        Веб-сайт – совокупность графических и информационных материалов, а также
        программ для ЭВМ и баз данных, обеспечивающих их доступность в сети
        интернет по сетевому адресу
        <a href="https://navigator.eurekacenter.ru" class="card-link"
          >https://navigator.eurekacenter.ru</a
        >;
      </li>
      <li class="list-unstyled">
        Информационная система персональных данных — совокупность содержащихся в
        базах данных персональных данных, и обеспечивающих их обработку
        информационных технологий и технических средств;
      </li>
      <li class="list-unstyled">
        Обезличивание персональных данных — действия, в результате которых
        невозможно определить без использования дополнительной информации
        принадлежность персональных данных конкретному Пользователю или иному
        субъекту персональных данных;
      </li>
      <li class="list-unstyled">
        Обработка персональных данных – любое действие (операция) или
        совокупность действий (операций), совершаемых с использованием средств
        автоматизации или без использования таких средств с персональными
        данными, включая сбор, запись, систематизацию, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передачу
        (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление, уничтожение персональных данных;
      </li>
      <li class="list-unstyled">
        Оператор – государственный орган, муниципальный орган, юридическое или
        физическое лицо, самостоятельно или совместно с другими лицами
        организующие и (или) осуществляющие обработку персональных данных, а
        также определяющие цели обработки персональных данных, состав
        персональных данных, подлежащих обработке, действия (операции),
        совершаемые с персональными данными;
      </li>
      <li class="list-unstyled">
        Персональные данные – любая информация, относящаяся прямо или косвенно к
        определенному или определяемому Пользователю веб-сайта
        <a href="https://navigator.eurekacenter.ru/" class="card-link"
          >https://navigator.eurekacenter.ru</a
        >;
      </li>
      <li class="list-unstyled">
        Пользователь – любой посетитель веб-сайта
        <a href="https://navigator.eurekacenter.ru/" class="card-link"
          >https://navigator.eurekacenter.ru/</a
        >;
      </li>
      <li class="list-unstyled">
        Предоставление персональных данных – действия, направленные на раскрытие
        персональных данных определенному лицу или определенному кругу лиц;
      </li>
      <li class="list-unstyled">
        Распространение персональных данных – любые действия, направленные на
        раскрытие персональных данных неопределенному кругу лиц (передача
        персональных данных) или на ознакомление с персональными данными
        неограниченного круга лиц, в том числе обнародование персональных данных
        в средствах массовой информации, размещение в
        информационно-телекоммуникационных сетях или предоставление доступа к
        персональным данным каким-либо иным способом;
      </li>
      <li class="list-unstyled">
        Трансграничная передача персональных данных – передача персональных
        данных на территорию иностранного государства органу власти иностранного
        государства, иностранному физическому или иностранному юридическому
        лицу;
      </li>
      <li class="list-unstyled">
        Уничтожение персональных данных – любые действия, в результате которых
        персональные данные уничтожаются безвозвратно с невозможностью
        дальнейшего восстановления содержания персональных данных в
        информационной системе персональных данных и (или) результате которых
        уничтожаются материальные носители персональных данных.
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      3. Оператор может обрабатывать следующие персональные данные Пользователя
    </h4>

    <ul class="text-justify list-unstyled">
      <li class="list-unstyled">Фамилия, имя, отчество, фото профиля;</li>
      <li class="list-unstyled">Фото профиля и видео материалы;</li>
      <li class="list-unstyled">Данные о уровне образования и компетенциях;</li>
      <li class="list-unstyled">Электронный адрес;</li>
      <li class="list-unstyled">Номера телефонов;</li>
      <li class="list-unstyled">
        Также на сайте происходит сбор и обработка обезличенных данных о
        посетителях (в т.ч. файлов «cookie») с помощью сервисов
        интернет-статистики (Яндекс Метрика и Гугл Аналитика и других). Выше
        перечисленные данные далее по тексту Политики объединены общим понятием
        Персональные данные.
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      4. Оператор может обрабатывать следующие персональные данные Пользователя
    </h4>

    <ul class="text-justify list-unstyled">
      <li class="list-unstyled">
        По своему усмотрению и необходимости создавать, изменять, отменять
        правила;
      </li>
      <li class="list-unstyled">
        Ограничивать доступ к любой информации на сайте;
      </li>
      <li class="list-unstyled">Создавать, изменять, удалять информацию;</li>
      <li class="list-unstyled">Удалять учетные записи;</li>
      <li class="list-unstyled">
        Отказывать в регистрации без объяснения причин.
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      5. Правовые основания обработки персональных данных
    </h4>

    <ul class="text-justify list-unstyled">
      <li class="list-unstyled">
        Оператор обрабатывает персональные данные Пользователя только в случае
        их заполнения и/или отправки Пользователем самостоятельно через
        специальные формы, расположенные на сайте
        <a href="https://navigator.eurekacenter.ru/" class="card-link"
          >https://navigator.eurekacenter.ru/</a
        >. Заполняя соответствующие формы и/или отправляя свои персональные
        данные Оператору, Пользователь выражает свое согласие с данной
        Политикой. Оператор обрабатывает обезличенные данные о Пользователе в
        случае, если это разрешено в настройках браузера Пользователя (включено
        сохранение файлов «cookie» и использование технологии JavaScript).
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      6. Порядок сбора, хранения, передачи и других видов обработки персональных
      данных
    </h4>

    <ul class="text-justify list-unstyled">
      <li class="list-unstyled">
        Безопасность персональных данных, которые обрабатываются Оператором,
        обеспечивается путем реализации правовых, организационных и технических
        мер, необходимых для выполнения в полном объеме требований действующего
        законодательства в области защиты персональных данных.
      </li>
      <li class="list-unstyled">
        Оператор обеспечивает сохранность персональных данных и принимает все
        возможные меры, исключающие доступ к персональным данным
        неуполномоченных лиц.
      </li>
      <li class="list-unstyled">
        Персональные данные Пользователя никогда, ни при каких условиях не будут
        переданы третьим лицам, за исключением случаев, связанных с исполнением
        действующего законодательства.
      </li>
      <li class="list-unstyled">
        В случае выявления неточностей в персональных данных, Пользователь может
        актуализировать их самостоятельно, путем направления Оператору
        уведомление на адрес электронной почты Оператора /conference@geekbase.ru
        с пометкой «Актуализация персональных данных».
      </li>
      <li class="list-unstyled">
        Срок обработки персональных данных является неограниченным. Пользователь
        может в любой момент отозвать свое согласие на обработку персональных
        данных, направив Оператору уведомление посредством электронной почты на
        электронный адрес Оператора
        <a class="card-link" href="mailto:navigator@eurekanet.ru"
          >conference@geekbase.ru</a
        >
        с пометкой «Отзыв согласия на обработку персональных данных».
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      7. Трансграничная передача персональных данных
    </h4>

    <ul class="text-justify list-unstyled">
      <li class="list-unstyled">
        Оператор до начала осуществления трансграничной передачи персональных
        данных обязан убедиться в том, что иностранным государством, на
        территорию которого предполагается осуществлять передачу персональных
        данных, обеспечивается надежная защита прав субъектов персональных
        данных.
      </li>
      <li class="list-unstyled">
        Трансграничная передача персональных данных на территории иностранных
        государств, не отвечающих вышеуказанным требованиям, может
        осуществляться только в случае наличия согласия в письменной форме
        субъекта персональных данных на трансграничную передачу его персональных
        данных и/или исполнения договора, стороной которого является субъект
        персональных данных.
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      8. Заключительные положения
    </h4>
    <li class="list-unstyled">
      Пользователь может получить любые разъяснения по интересующим вопросам,
      касающимся обработки его персональных данных, обратившись к Оператору с
      помощью электронной почты
      <a class="card-link" href="mailto:navigator@eurekanet.ru"
        >navigator@eurekanet.ru</a
      >. В данном документе будут отражены любые изменения политики обработки
      персональных данных Оператором. Политика действует бессрочно до замены ее
      новой версией. Актуальная версия Политики в свободном доступе расположена
      в сети Интернет по адресу
      <a href="https://navigator.eurekacenter.ru/politic" class="card-link"
        >https://navigator.eurekacenter.ru/politic</a
      >.
    </li>
  </DocumentWrapper>
</template>

<script>
import DocumentWrapper from "@/components/DocumentWrapper.vue";

import { t } from "@/utils/translate";

export default {
  components: {
    DocumentWrapper,
  },
  setup() {
    return {
      t,
    };
  },
};
</script>
