<template>
  <div class="app-title">
    <p>
      <slot name="top"></slot>
    </p>

    <h1 v-if="mode === 'h1'">
      <slot name="title"></slot>
    </h1>

    <h2 v-if="mode === 'h2'">
      <slot name="title"></slot>
    </h2>

    <h3 v-if="mode === 'h3'">
      <slot name="title"></slot>
    </h3>

    <h4 v-if="mode === 'h4'">
      <slot name="title"></slot>
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.app-title {
  font-weight: 700;
  text-align: center;

  p,
  h2,
  h3,
  h4 {
    &:empty {
      display: none;
    }
  }
  
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
  }

  p {
    color: $accent-1;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 4px;
  }

  h3 {
    font-size: 30px;
    line-height: 40px;
  }

  h2 {
    font-size: 40px;
    line-height: 50px;
  }

  h1 {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 22px;
      line-height: 32px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

    h2 {
      font-size: 34px;
      line-height: 44px;
    }

    h1 {
      font-size: 40px;
      line-height: 50px;
    }
  }
}
</style>
