<template>
  <div class="n-side-menu flex">
    <div class="n-side-menu__content">
      <LinksList :links="navigation" @link="$emit('menu-close')"/>
    </div>
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { useUserStore } from "@/store/userStore";
import LinksList from "@/elements/LinksList.vue";
import { onMounted } from "vue";
import { onUnmounted } from "vue";

export default {
  props: {
    navigation: {
      type: Array,
      required: true,
    },
  },
  emits: ["menu-close"],
  setup() {
    const userStore = useUserStore();
    const { isAdmin } = userStore;

    onMounted(() => {
      document.body.style.overflow = 'hidden'
    })

    onUnmounted(() => {
      document.body.style.overflow = 'auto'
    })

    return {
      t,
      isAdmin,
    };
  },
  components: { LinksList },
};
</script>

<style lang="scss">
.n-side-menu {
  position: fixed;
  z-index: 100;
  @include full-bg;
  bottom: 0;

  &__content {
    background-color: #fff;
    z-index: 101;
    padding: 30px 16px;
    flex-grow: 1;
    overflow: scroll;
  }
}
</style>
