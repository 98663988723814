<template>
  <div class="webinar-layout" v-if="currentWebinar">
    <div class="wrapper">
      <AppBackButton :linkMode="true" to="/salon"></AppBackButton>
    </div>
    <PageTitle v-if="currentWebinar.salonName" :title="currentWebinar.salonName" />


    <div class="wrapper webinar-layout__wrapper">
      <NTabs
        :tabs="webinarTabs"
        :menuIsShowed="menuIsShowed"
        :startUrl="`/webinar/${$route.params.id}`"
        @toggleMenu="menuIsShowed = !menuIsShowed"
        @closeMenu="menuIsShowed = false"
      />
      <router-view v-slot="{ Component }">
        <transition name="slide-from-right" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import { webinarTabs } from "@/app-data/static";
import { t } from "@/utils/translate";
import { onMounted } from "vue";

import { ref } from "vue";
import { customAxios } from "@/service/customAxios";
import { useRoute } from "vue-router";

import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

import PageTitle from "@/elements/PageTitle.vue";
import NTabs from "@/components/NTabs.vue";
import AppBackButton from "@/elements/AppBackButton.vue";

export default {
  components: {  NTabs, AppBackButton, PageTitle },
  setup() {
    const userStore = useUserStore();
    const { currentWebinar } = storeToRefs(userStore);
    const { setCurrentWebinar } = userStore;

    const menuIsShowed = ref(false);
    const route = useRoute();

    async function getWebinarData() {
      try {
        const r = await customAxios.getWithAuth(
          `salon/public/${route.params.id}`
        );

        setCurrentWebinar(r.data);
      } catch (e) {
        console.log(e);
      }
    }

    onMounted(async () => {
      await getWebinarData();
    });

    return { t, currentWebinar, menuIsShowed, webinarTabs };
  },
};
</script>

<style lang="scss">
.webinar-layout {
  &__wrapper {
    @include page;
  }
}
</style>
