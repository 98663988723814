<template>
  <div class="app-tooltip">
    <slot></slot>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.app-tooltip {
  margin: 40px 0;
  border-left: 2px solid $accent-2;
  padding: 8px 16px;
  text-align: left;

  p {
    font-size: 18px;
    line-height: 30px;
  }

  strong {
    color: $accent-2;
  }

  a {
    color: $accent-1;
    font-weight: 700;
    transition: all 0.2s;
    &:hover {
      color: $accent-2;
    }
  }
}
</style>
