<template>
    <div class="admin-item-shedule">
      <div class="admin-item-shedule__selector">
      </div>

      <div class="admin-item-shedule__selector">
        <AppTitle mode="h4" class="admin-item-shedule__subtitle"><template v-slot:title>Выбор даты</template>
        </AppTitle>

        <div class="admin-item-shedule__btns flex">
          <AppButton @click="dateSelectorMode = 'one'" :class="{ 'active': dateSelectorMode === 'one' }">Добавить 1 день
          </AppButton>
          <AppButton @click="dateSelectorMode = 'range'" :class="{ 'active': dateSelectorMode === 'range' }">Добавить
            диапазон дней</AppButton>
        </div>

        <Datepicker v-if="dateSelectorMode === 'range'" id="picker-date" :model-value="date" range
          :month-change-on-scroll="false" @update:model-value="setDate" inline-with-input :enable-time-picker="false" />

        <Datepicker v-if="dateSelectorMode === 'one'" id="picker-one-date" :model-value="date"
          :month-change-on-scroll="false" @update:model-value="setDate" inline-with-input :enable-time-picker="false" />
      </div>

      <div class="admin-item-shedule__selector">
        <AppTitle mode="h4" class="admin-item-shedule__subtitle"><template v-slot:title>Выбор времени</template>
        </AppTitle>

        <Datepicker id="picker-time" v-model="time" time-picker inline-with-input />
      </div>
    </div>
</template>

<script>
import { t } from "@/utils/translate";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { ref, onMounted, watch } from "vue"
import AppTitle from "@/elements/AppTitle.vue";
import AppButton from "@/elements/AppButton.vue";

export default {
  components: { Datepicker, AppTitle, AppButton },
  setup() {
    const date = ref();
    const time = ref({ 
            hours: new Date().getHours(),
            minutes: new Date().getMinutes()
        });

    const dateSelectorMode = ref('one')

    const setDate = (values) => {
      date.value = values;
    }

    onMounted(() => {
      setPlaseholderDate()
    })

    function setPlaseholderDate() {
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 1));

      if (dateSelectorMode.value === 'one') {
        date.value = startDate;
      } else {
        date.value = [startDate, endDate];
      }
    }

    watch(dateSelectorMode, () => {
      setPlaseholderDate()
    })

    return { date, setDate, t, time, dateSelectorMode };

  }
};
</script>

<style lang="scss"></style>