<template>
  <div v-if="user">
    <div class="course-lessons" v-if="isJoined">
      <div v-if="sortedEvents.length">
        <LessonPreview
          v-for="(e, idx) in sortedEvents"
          :key="idx"
          :lesson="e"
          @selectVideo="selectVideo"
          @beginWebinar="beginWebinar"
          @startWebinar="startWebinar"
        />
      </div>
      <p v-else>{{ t("teacher.didnt.add.lessons") }}</p>

      <NVideoModal
        v-if="showVideo"
        :video="video"
        @closeModal="closeVideoModal"
      />

      <WebinarModal
        v-if="showWebinar"
        @closeModal="showWebinar = false"
        :webinar="webinar"
      />
    </div>
    <div v-else class="wrapper course-lessons__access-denied">
      <p>{{ t("join.for.access") }}</p>
      <CourseSummary
        v-if="user"
        @joinToCourse="$emit('joinToCourse')"
        @leaveCourse="$emit('leaveCourse')"
        @addToFavorites="$emit('addToFavorites')"
        @removeFromFavorites="$emit('removeFromFavorites')"
      />
    </div>
  </div>
  <LoginPlease v-else />
</template>

<script>
import { computed, ref } from "vue";

import { t } from "@/utils/translate";
import { useUserStore } from "@/store/userStore";
import { usePopupStore } from "@/store/popupStore";

import { storeToRefs } from "pinia";
import { customAxios } from "@/service/customAxios";

import NVideoModal from "@/components/NVideoModal.vue";
import LessonPreview from "@/components/LessonPreview.vue";
import CourseSummary from "@/elements/CourseSummary.vue";

import LoginPlease from "@/elements/LoginPlease.vue";
import WebinarModal from "@/components/WebinarModal.vue";

const webinarRedirect = "Открываем вебинарную комнату в новой вкладке";

export default {
  emits: [
    "joinToCourse",
    "leaveCourse",
    "addToFavorites",
    "removeFromFavorites",
  ],
  components: {
    LessonPreview,
    NVideoModal,
    CourseSummary,
    LoginPlease,
    WebinarModal,
  },
  created() {},
  setup() {
    const userStore = useUserStore();
    const { currentCourse, isJoined, user } = storeToRefs(userStore);

    const popupStore = usePopupStore();
    const { showPopup } = popupStore;

    const showVideo = ref(false);
    const showWebinar = ref(false);

    const alertDialogMessage = ref("");

    const webinar = ref(null);
    const video = ref(null);

    const sortedEvents = computed(() => {
      const tempEvents = currentCourse.value.eventSet;
      return tempEvents.sort(dynamicSort("eventDateTime", 1));
    });

    function closeVideoModal() {
      showVideo.value = false;
      video.value = null;
    }

    function selectVideo(v) {
      console.log("selectVideo", v);
      video.value = v;
      showVideo.value = true;
    }

    function startWebinar(v) {
      webinar.value = v;
      showWebinar.value = true;
    }

    function delay(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    }

    function dynamicSort(property, sortOrder) {
      return function (a, b) {
        const result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    }

    async function beginWebinar(item) {
      console.log("Item ID: " + item.id);
      let courseWebinar = {
        webinarId: item.id,
        courseId: currentCourse.value.id,
      };

      try {
        const response = await customAxios.post("webinar/begin", courseWebinar);

        if (response.status === 200) {
          console.log("Seminar Join URL :" + response.data.url);

          if (response.data.url !== null) {
            // webinarRedirectwebinarRedirectDialog.value = true;
            showPopup(webinarRedirect, "warning");
            delay(3000).then(() => {
              window.open(response.data.url, "_blank");
              // webinarRedirectDialog.value = false;
            });
          } else {
            alertDialogMessage.value = response.data.statusComment;
            showPopup(alertDialogMessage.value, "error");
          }
        } else if (response.status === 207) {
          alertDialogMessage.value = response.data.message;
          showPopup(alertDialogMessage.value, "error");
        } else {
          console.log(response);
        }
      } catch (e) {
        console.log(e);
      }
    }

    return {
      user,
      t,
      currentCourse,
      selectVideo,
      sortedEvents,
      showVideo,
      video,
      closeVideoModal,
      startWebinar,
      webinar,
      beginWebinar,
      showWebinar,
      isJoined,
    };
  },
};
</script>

<style lang="scss">
.course-lessons {
  @include page;

  &__access-denied {
   @include access-denied;
  }

  &__close_button {
    @include flex-center;
    margin: 10px;
    width: 100%;

    span {
      white-space: nowrap;
    }

    &:first-of-type {
      margin-top: 10px;
    }

    &--close {
      @include button-default;
      background-color: $accent-2;

      &:hover {
        background-color: rgba($accent-2, 0.7);
      }
    }
  }

  &__play_button {
    @include flex-center;
    margin: 10px;
    width: 160px;

    span {
      white-space: nowrap;
    }

    &:first-of-type {
      margin-top: 10px;
    }

    &--open {
      @include button-default;
      background-color: $accent-2;

      &:hover {
        background-color: rgba($accent-2, 0.7);
      }
    }
  }
}
</style>
