import { createStore } from 'vuex'
import { alert } from './alert.module'
import { authentication } from './authentication.module'
import { users } from './users.module'
import {theme} from "@/store/theme.module";

export const store = new createStore({
  modules: {
    alert,
    authentication,
    users,
    theme
  }
})
