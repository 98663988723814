<template>
  <div class="wave-section" ref="waveRef">
    <div class="wrapper">
      <slot ref="slotRef"></slot>
    </div>

    <svg
      class="wave-section__wave"
      xml:space="preserve"
      viewBox="0 0 1920 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_102_2)">
        <path
          d="M1919 41.75L1869.86 62.625C1820.72 83.5 1722.73 125.25 1623.88 114.812C1526.03 104.375 1427.6 41.75 1330.19 17.4176C1231.33 -7.17578 1132.48 7.17578 1035.06 20.875C936.64 34.5742 838.79 48.9258 739.938 52.1875C641.946 55.4492 543.667 48.9258 444.815 34.7699C347.252 20.875 248.543 0 151.124 0C52.4148 0 -45.1479 20.875 -95.2903 31.3125L-144 41.75V167H-94.8604C-45.7209 167 52.2715 167 151.124 167C248.973 167 347.395 167 444.815 167C543.667 167 642.519 167 739.938 167C838.36 167 936.21 167 1035.06 167C1133.05 167 1231.33 167 1330.19 167C1427.75 167 1526.46 167 1623.88 167C1722.59 167 1820.15 167 1870.29 167H1919V41.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_102_2">
          <rect width="1920" height="167" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import { calculateWaveSectionSize } from "@/utils/common";
import { onUnmounted } from "vue";

export default {
  props: {
    minHeight: {
      type: Number,
      required: false,
    },
  },
  setup() {
    const svgRef = ref();
    const waveRef = ref();

    function setSectionoHeight() {
      const result = calculateWaveSectionSize(300);
      waveRef.value.style.minHeight = result.minHeight;
      waveRef.value.style.paddingBottom = result.paddingBottom;
    }

    window.addEventListener("resize", setSectionoHeight);

    onMounted(() => {
      if (waveRef.value) {
        setSectionoHeight();
      }
    });

    onUnmounted(() => {
      window.removeEventListener("resize", setSectionoHeight);
    });
    return {
      svgRef,
      waveRef
    };
  },
};
</script>

<style lang="scss">
.wave-section {
  position: relative;
  display: flex;
  background-color: #f7f7f7;

  .wrapper {
    padding-top: 40px;
    display: flex;
    align-items: center;
  }

  &__wave {
    width: 100%;
    fill: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
