<template>
  <div class="n-filters">
    <AppButton
        class="n-filters__opener d-flex flex__ai-c"
        @click="menuIsOpen = !menuIsOpen"
    >
      <div v-if="currentFilters" class="n-filters__counter">
        <p>{{ currentFilters.length }}</p>
      </div>

      <transition name="fade" mode="out-in">
        <AppIcon v-if="menuIsOpen" :key="1" icon="cross-icon" />
        <AppIcon v-else icon="filters-icon" :key="2" />
      </transition>

      <span>
        {{ t("filters") }}
      </span>
    </AppButton>
    <transition name="fade">
      <div v-if="menuIsOpen" class="n-filters__filters">
        <div class="n-filters__checkboxies">
          <div class="n-filters__checkbox" v-for="f in filters" :key="f.id">
            <AppCheckbox
                v-if="f.active"
                :id="f.id"
                :label="f.tagName"
                :value="f.tagName"
                @toggle-check="$emit('toggleFilters', f)"
                :checked="isCurrent(f.id)"
            />
          </div>
        </div>
        <ColoredButton @click="$emit('clearFilters')" class="n-filters__clear">{{ t('clear') }}</ColoredButton>
      </div>
    </transition>
  </div>
</template>

<script>
import AppButton from "@/elements/AppButton.vue";
import AppCheckbox from "@/elements/AppCheckbox.vue";
import AppIcon from "@/elements/AppIcon.vue";
import ColoredButton from "@/elements/ColoredButton.vue";

import { t } from "@/utils/translate";
import { ref } from "vue";

export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
    currentFilters: {
      type: Array,
      required: false,
    },
  },
  emits: ["toggleFilters", "clearFilters"],
  setup(props) {
    const menuIsOpen = ref(false);

    function isCurrent(id) {
      return !!props.currentFilters.filter((v) => v.id === id).length;
    }

    return {
      isCurrent,
      t,
      menuIsOpen,
    };
  },
  components: { AppCheckbox, AppButton, AppIcon, ColoredButton },
};
</script>

<style lang="scss">
.n-filters {
  margin-bottom: 60px;

  &__clear {
    margin-top: 16px;
  }

  &__checkboxies {
    text-align-last: left;
  }

  &__checkbox + .n-filters__checkbox {
    margin-top: 16px;
  }

  &__counter {
    position: absolute;
    top: 0;
    right: 0;
    background: $gradient;
    transform: translate(50%, -50%);
    border-radius: 50%;
    @include flex-center;
    min-width: 30px;
    min-height: 30px;
    padding: 4px;
    aspect-ratio: 1/1;
  }

  &__opener {
    @include button-default;
    display: inline-block;
    position: relative;
    padding: 8px 16px;
  }

  svg {
    stroke: #fff;
  }

  &__filters {
    background-color: #f5f5f5;
    padding: 16px;
    margin-top: 16px;
    border-radius: 8px;
  }
}
</style>
