<template>
  <UserContentWrapper :title="t('account')" @back="$emit('back')">
    <div class="n-user-info">
      <AppBackButton
        :linkMode="true"
        link="/user/panel"
      />

      <div class="flex flex__ai-c n-user-info__data">
        <AppIcon icon="user-icon" />
        <p>{{ fullName }}</p>
      </div>

      <div class="flex flex__ai-c n-user-info__data">
        <AppIcon icon="mail-icon" />
        <p v-if="user.personEmail">{{ user.personEmail }}</p>
        <p v-else>{{ t("empty") }}</p>
      </div>

      <div class="n-user-info__data flex n-user-info__data--roles">
        <div class="n-user-info__roles flex">
          <div
            v-for="(r, i) in userRoles.filter((v) => l[v])"
            :key="i"
            class="n-user-info__role"
          >
            <span>{{ l[r] }}</span>
          </div>
        </div>
      </div>
    </div>
  </UserContentWrapper>
</template>

<script>
import { computed } from "vue";
import { t } from "@/utils/translate";

import AppIcon from "@/elements/AppIcon";
import UserContentWrapper from "@/elements/UserContentWrapper.vue";
import AppBackButton from "@/elements/AppBackButton.vue";

import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

export default {
  components: { AppIcon, UserContentWrapper, AppBackButton },
  setup() {
    const store = useUserStore();
    const { user } = storeToRefs(store);

    const userRoles = computed(() => user.value.roles);
    const fullName = computed(
      () => `${user.value.firstname} ${user.value.lastname}`
    );

    const l = {
      ROLE_USER: "Пользователь",
      ROLE_COACH: "Инструктор",
      ROLE_TUTOR: "Наставник",
      ROLE_ADMIN: "Администратор",
      ROLE_MASTER: "Мастер",
      ROLE_PARENT: "Родитель",
      ROLE_CHILD: "Ребенок",
    };

    return {
      l,
      t,
      user,
      fullName,
      userRoles,
    };
  },
};
</script>

<style lang="scss">
.n-user-info {
  &__roles {
    flex-wrap: wrap;
  }

  &__data {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
    font-weight: 700;

    svg {
      margin-right: 16px;
      stroke-width: 3px;
      stroke: $accent-1;
    }
  }

  &__data--roles {
    align-self: flex-start;
  }

  &__role {
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    margin: 8px;

    &:nth-of-type(1n) {
      background-color: $accent-2;
    }

    &:nth-of-type(2n) {
      background-color: $accent-1;
    }

    &.hidden {
      display: none;
    }
  }
}
</style>
