<template>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab value="1" >Пользователи</v-tab>
      <v-tab value="2">Группы пользователей</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item value="1" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Пользователи</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="searchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  v-on:click:clear="filteredUsers = users"
                  rounded
              >
                <template v-slot:append>

                  <v-icon
                      icon="mdi-book-search-outline"
                      @click="filterUsers()"
                  ></v-icon>

                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="dialog=true">
                <span>Добавить</span>
              </AppButton>
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="resendNotification">
                <span>Уведомления</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="headers"
                  :items="filteredUsers"
                  alternating
                  show-index
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >

                <template #item-name="{firstname, lastname, secname}">
                  <strong v-if="!!lastname">{{lastname  + " "}}  </strong>
                  <strong v-if="!!firstname">{{firstname + " "}} </strong>
                  <strong v-if="!!secname">{{secname}} </strong>
                </template>
                username
                <template #item-personEmail="{personEmail, username}">
                  <p><b>E-mail:</b><span v-if="personEmail">{{personEmail}}</span></p>
                  <p><b>Логин:</b><span v-if="username">{{username}}</span></p>
                </template>

                <template #item-telegramBotChatId="{telegramBotChatId}">
                  <p v-if="telegramBotChatId">{{telegramBotChatId}}</p>
                </template>

                <template #item-registrationDate="{registrationDate}">
                  <span v-if="registrationDate">{{ registrationDate.replace('T', ' ')}}</span>
                </template>

                <template #item-active="{active}">
                  <p v-if="active">Да</p>
                  <p v-else>Нет</p>
                </template>
                <template #item-roles="{roles}">
                  <div v-if="!!roles">
                    <v-chip
                        v-for="c in spittedRoles(roles)"
                        class="ma-2"
                        v-bind:key="c"
                        size="x-small"
                    >
                      {{ c }}
                    </v-chip>
                  </div>
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editUserRegalies(item)">
                    <span>Регалии</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editUser(item)">
                    <span>Изменить</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteUser(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
                <template #loading>
                  <img
                      src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                      style="width: 100px; height: 80px;"
                  />
                </template>

              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="2" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0">
          <v-row  class=" ma-2 pa-2" >
            <v-col cols="12">
              <h3>Группы пользователей</h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="groupSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  rounded
                  clearable
                  v-on:click:clear="filtereduserGroups = userGroups"
              >
                <template v-slot:append>

                  <v-icon
                      icon="mdi-book-search-outline"
                      @click="filterGroups()"
                  ></v-icon>

                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="groupDialog=true">
                <span>Добавить</span>
              </AppButton>
            </v-col>

          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="groupHeaders"
                  :items="filtereduserGroups"
                  alternating
                  show-index
                  :rows-of-page-separator-message	="'из'"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >

                <template #item-personGroupCreateDate="{personGroupCreateDate}">
                  {{ personGroupCreateDate.replace('T', ' ')}}
                </template>
                <template #item-isLocked="{isLocked}">
                  <p v-if="isLocked">Нет</p>
                  <p v-else>Да</p>
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editUserGroup(item)">
                    <span>Изменить</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteUserGroup(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
                <template #loading>
                  <img
                      src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                      style="width: 100px; height: 80px;"
                  />
                </template>

              </Vue3EasyDataTable>
            </v-col>
          </v-row>

        </v-card-text>
      </v-window-item>
      <v-window-item :value="3" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0">
          <v-row  class=" ma-2 pa-2" >
            <v-col cols="12">
              <h3>Регалии пользователя {{selectedUser.lastname}} {{selectedUser.firstname}} </h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="regaliaSearchField"
                  label="Что ищем"
                  variant="outlined"
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createRegalia">
                <span>Добавить</span>
              </AppButton>
            </v-col>

          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="regaliaHeaders"
                  :items="userRegalias"
                  alternating
                  show-index
                  :search-value="regaliaSearchField"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editRegalia(item)">
                    <span>Изменить</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteRegalia(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
                <template #loading>
                  <img
                      src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                      style="width: 100px; height: 80px;"
                  />
                </template>

              </Vue3EasyDataTable>
            </v-col>
          </v-row>

        </v-card-text>
      </v-window-item>
    </v-window>
  </div>

  <avatar-cropper
      @uploaded="handleUploaded"
      @error="handlerError"
      v-model="showCropper"
      :upload-url="'https://navigator-backend.geekbase.ru/api/upload/avatar/user/'+id"
      :request-options="jwtHeaders"
      :labels="labels"
      mimes="image/gif, image/jpeg, image/png, image/bmp"
  />
  <div class="rounded vue-avatar-cropper-demo text-center">
  </div>

  <v-dialog v-model="regaliaDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Регалия пользователя</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="regaliaForm">
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Название*"
                    v-model="regaliaName"
                    :rules="regaliaNameRules"
                    hint="Укажите название"
                    :counter="1000"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-16" style="height: 400px;">
                <quill-editor
                    v-model:value="regaliaBody"
                />
              </v-col>


            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeRegaliaForm"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveRegalia"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="dialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Пользователь</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="userForm">
          <v-container>



            <v-row>
              <v-col cols="12" class="text-center">
                <v-avatar
                    size="128px"
                    color="pink-accent-1"
                    @click="addAvatar(id)"
                >
                  <v-img v-if="personAvatar!==''" :src="'https://navigator-backend.geekbase.ru/api/download/avatar/user/' + personAvatar"></v-img>
                </v-avatar>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Фамилия*"
                    v-model="lastname"
                    :rules="lastnameRules"
                    hint="Укажите фамилию"
                    :counter="25"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Имя*"
                    v-model="firstname"
                    :rules="firstnameRules"
                    :counter="25"
                    variant="outlined"
                    hint="Укажите имя"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Отчество*"
                    v-model="secname"
                    :rules="secnameRules"
                    variant="outlined"
                    :counter="60"
                    hint="Укажите отчество"

                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="personEmail"
                    variant="outlined"
                    label="Email*"
                    hint="Укажите e-mail"
                ></v-text-field>
                <v-alert
                    density="compact"
                    type="error"
                    variant="outlined"
                    v-show="existEmail"
                >E-mail уже занят!
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    variant="outlined"
                    label="Имя пользователя*"
                    hint="Укажите имя пользователя (используется для входа в систему)"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="password"
                    label="Пароль*"
                    contenteditable="false"
                    type="text"
                    variant="outlined"
                    hint="Сгенерированный пароль"
                    required
                    persistent
                >
                  <template v-slot:append>
                    <v-icon
                        color="red"
                        v-on:click="generatePassword"
                    >
                      mdi-hammer
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="roles"
                    :items="rolesItems"
                    outlined
                    dense
                    chips
                    small-chips
                    variant="outlined"
                    closable-chips
                    label="Роли"
                    multiple
                ></v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="personGroups"
                    :items="userGroups"
                    :custom-filter="userGroupFilter"
                    filled
                    chips
                    small-chips
                    variant="outlined"
                    closable-chips
                    label="Группы пользователей"
                    item-title="name"
                    item-value="name"
                    multiple
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.personGroupName"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">

                    <v-list-item

                        v-bind="props"
                        :title="item.raw.personGroupName"
                        :subtitle="item.raw.personGroupDescription"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-switch
                    v-model="active"
                    :label="active ? 'Активирован' : 'Не активирован'"
                    hide-details
                >
                </v-switch>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closePersonForm"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="save"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="groupDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Группа пользователей</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
            ref="userGroupForm"
        >
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Название*"
                    v-model="personGroupName"
                    :rules="personGroupNameRules"
                    hint="Укажите название группы"
                    :counter="30"
                    required
                    variant="outlined"
                ></v-text-field>
                <v-alert
                    density="compact"
                    type="error"
                    v-show="existGroupError"
                >Группа уже существует!
                </v-alert>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-textarea
                    label="Описание*"
                    v-model="personGroupDescription"
                    :rules="personGroupDescriptionRules"
                    :counter="255"
                    hint="Укажите описание"
                    variant="outlined"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-container class="px-0" fluid>
                  <v-switch
                      v-model="isLocked"
                      :label="isLocked ? 'Заблокирована' : 'Активна'"
                  ></v-switch>
                </v-container>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeUserGroupForm"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveGroup"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showLoader" persistent >
    <loader-ring></loader-ring>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { customAxios} from "@/service/customAxios";
import {apiUrl} from "@/service/user.service"
import {router} from "@/service/router";
import AvatarCropper from 'vue-avatar-cropper';
import {customStorage} from "@/service/customStorage";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import AppButton from "@/elements/AppButton.vue";
import LoaderRing from "@/elements/LoaderRing.vue";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminUserPage',

  props: [],
  components:{
    LoaderRing,
    AppButton,
    Vue3EasyDataTable,
    AvatarCropper
  },


  data: () => ({
    filtereduserGroups:[],
    personAvatar:'',
    filteredUsers:[],
    regaliaName:'',
    regaliaBody:'',
    regaliaDialog: false,
    selectedUser:undefined,
    regaliaSearchField:'',
    regaliaSearchOptions: [
      {value: "regaliaName", name: "Название"},
      {value: "regaliaBody", name: "Регалии"},

    ],
    regaliaNameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 1000) || 'Значение до должно превышать 1000 символов!',
    ],
    userRegalias:[],
    regaliaHeaders: [
      { text: "id", value: "id" , sortable: true, width: 10 },
      { text: "Название", value: "regaliaName" , width: 680 },
      { text: "Добавлено", value: "createDateTime" , sortable: true, width: 170 },
      { text: "Действие", value: "actions", width: 170}
    ]    ,

    groupSearchField:'',
    groupSearchValue:'',
    labels:{ submit: 'Сохранить', cancel: 'Отмена' },

    showCropper:false,
    showTable: true,

    userSearchOptions: [
      {value: "name", name: "Имя"},
      {value: "personEmail", name: "Email"},
      {value: "username", name: "Логин"},
      {value: "telegramBotChatId", name: "Telegram"},
      {value: "roles", name: "Роли"},
    ],

    userGroupSearchOptions: [
      {value: "personGroupName", name: "Имя"},
      {value: "personGroupDescription", name: "Описание"},
      {value: "personGroupCreateDate", name: "Добавлена"},
      {value: "isLocked", name: "Активна"},
    ],

    headers: [
      { text: "Имя", value: "name" , sortable: true, width: 150 },
      { text: "Email", value: "personEmail" , sortable: true, width: 100 },
      { text: "Tg id", value: "telegramBotChatId" , sortable: true, width: 90 },
      { text: "Роли", value: "roles", sortable: true, width: 120},
      { text: "Зарегистрирован", value: "registrationDate" , sortable: true, width: 120 },
      { text: "Активирован", value: "active" , sortable: true, width: 120 },
      { text: "Действие", value: "actions", width: 130},
    ],
    groupHeaders: [
      { text: "id", value: "id" , sortable: true},
      { text: "Имя", value: "personGroupName" , sortable: true  },
      { text: "Описание", value: "personGroupDescription" , sortable: true},
      { text: "Добавлена", value: "personGroupCreateDate" , sortable: true},
      { text: "Активна", value: "isLocked" , sortable: true},
      { text: "Действие", value: "actions", width: 280},
    ],
    searchField: "name",
    searchValue:'',
    showLoader: false,
    firstnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    lastnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    secnameRules: [
      v => (!v ||(!!v && v.length <= 250)) || 'Значение не должно превышать 50 символов!',
    ],
    usernameRules: [
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],

    rolesItems: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PARENT', 'ROLE_CHILD','ROLE_COACH', 'ROLE_TUTOR', 'ROLE_MASTER'],
    firstname: '',
    id:null,
    page: 1,
    noteCount: 20,
    lastname: '',
    secname: '',
    password: '',
    personEmail: '',
    personGroups: [],
    roles: [],
    //personGroupsSource:[],
    valid: true,
    dialog: false,
    searchBody: '',
    showInput: false,
    loading: false,
    users: [],
    emailForCheck: {},
    existEmail: false,
    username: '',

    groupSearchBody:'',
    groupDialog:false,
    personGroupName:'',
    personGroupDescription:'',
    isLocked:false,
    existGroupError:false,
    personGroupNameForCheck:'',
    groupPage:1,
    userGroups: [],
    tab:1,
    personGroupNameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 30) || 'Значение до должно превышать 30 символов!',
    ],
    personGroupDescriptionRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 255) || 'Значение до должно превышать 255 символов!',
    ],
    userSortKey: 'id',
    userLastSortKey: 'id',
    userSortOrder: 1,


    userGroupSortKey: 'id',
    userGroupLastSortKey: 'id',
    userGroupSortOrder: 1,
    active: false,




  }),
  methods: {
    resendNotification: function (){
      customAxios.getWithAuth('person/resend/' )
          .then(res => {
            if (res.status === 200) {
              alert(res.data.message)
            } else if(res.status === 207) {
              alert(res.data.message)
            }
          }).catch(error => {
        console.log("Ошибка при получении регалий:" + error)
      })

    },
    filterGroups: function (){
      const ss = this.groupSearchValue.toLowerCase();
      let tempUserGroups = this.userGroups;

      this.filtereduserGroups =  tempUserGroups.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );

    },
    filterUsers: function (){
      const ss = this.searchValue.toLowerCase();
      let tempUsers = this.users;

      this.filteredUsers =  tempUsers.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );

    },

    editUserRegalies: function (item) {
      this.selectedUser = item;
      customAxios.getWithAuth('person/regalia/' + item.id)
          .then(res => {
            if (res.status === 200) {
              this.userRegalias = res.data
              this.tab = 3;
            }
          }).catch(error => {
        console.log("Ошибка при получении регалий:" + error)
      })

    },
    closeRegaliaForm(){
      this.regaliaDialog = false
      this.id = ''
      this.regaliaName = ''
      this.regaliaBody = ''
    },
    saveRegalia() {
      this.$refs.regaliaForm.validate().then(responce =>{
        if(responce.valid){
          let regalia = {
            regaliaName:capitalizeFirstLetter(this.regaliaName),
            regaliaBody:capitalizeFirstLetter(this.regaliaBody),
            personId: this.selectedUser.id,
          }
          if (this.id) {
            customAxios.putWithAuth('person/regalia/' + this.id, regalia)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.userRegalias, response.data.id)
                    this.userRegalias.splice(index, 1, response.data)
                    this.regaliaDialog = false
                    this.id = ''
                    this.personId = ''
                    this.regaliaBody = ''
                    this.regaliaName = ''

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('person/regalia', regalia)
                .then(response => {
                  if (response.status === 201) {
                    this.userRegalias.push(response.data)
                    this.regaliaDialog = false
                    this.regaliaDialog = false
                    this.id = ''
                    this.personId = ''
                    this.regaliaBody = ''
                    this.regaliaName = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }


        }
      })

    },
    deleteRegalia(item){
      customAxios.delete("person/regalia/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              this.userRegalias.splice(getIndex(this.userRegalias, item.id), 1);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    editRegalia(item){
      this.id = item.id
      this.regaliaBody = item.regaliaBody
      this.regaliaName = item.regaliaName
      this.regaliaDialog = true

    },
    createRegalia(){
      this.regaliaDialog = true;
    },

    addAvatar(item){
      this.id = item;
      this.showTable = false;
      this.showCropper = true;
    },
    spittedRoles(roles){
      let splittedRoles = String(roles).split(",");
      return splittedRoles;
    },


    handleUploaded(res) {
      console.log("handleUploaded " + res)
      if (res.response.status === 201) {

        customAxios. getWithAuth('person')
            .then(res => {
              if(res.status===200){
                this.users=res.data
                this.showTable = true
              }

            }).catch(error => {
          if(error.response.status===401){
            this.$store.dispatch('authentication/logout')
            this.$store.dispatch('alert/clear')
            localStorage.clear()
            location.reload()

          }
        })

      }
    },



    handlerError() {
      this.message = "Oops! Something went wrong...";
      console.log("MSG: " + this.message)
    },


    editUserGroup: function (event) {
      this.id = event.id
      this.personGroupName = event.personGroupName
      this.personGroupDescription = event.personGroupDescription
      this.isLocked = event.isLocked
      this.groupDialog = true
    },
    deleteUserGroup: function (item) {
      let jwtTokenConfig = {
        headers: {
          Authorization: "Bearer " + this.user.token
        }
      }
      customAxios
          .delete(apiUrl +'/person_group/' + item.id, jwtTokenConfig)
          .then(response => {

            if (response.status === 202) {
              this.userGroups.splice(this.userGroups.indexOf(item), 1)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })
    },
    closeUserGroupForm() {
      this.groupDialog = false
      this.personGroupName = ''
      this.personGroupDescription = ''
      this.isLocked = false
    },
    saveGroup() {

      this.$refs.userGroupForm.validate().then(responce =>{
        if(responce.valid){

          let userGroup = {
            id: this.id,
            personGroupName: capitalizeFirstLetter(this.personGroupName),
            personGroupDescription: capitalizeFirstLetter(this.personGroupDescription),
            isLocked: this.isLocked,
          }

          if(this.id){
            customAxios.putWithAuth('person_group/' + this.id, userGroup)
                .then(response => {

                  if (response.status === 202) {
                    let index = getIndex(this.userGroups, response.data.id)
                    this.userGroups.splice(index, 1, response.data)
                    this.groupDialog = false
                    this.id = null
                    this.personGroupName = ''
                    this.personGroupDescription = ''
                    this.isLocked = false
                    this.existGroupError = false
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }

                })
                .catch(error=> {
                  if(error.response.status===401){
                    this.$store.dispatch('authentication/logout')
                    this.$store.dispatch('alert/clear')
                    localStorage.clear()
                    router.push('/').then(r => {console.log("error: " + r)})
                  }
                })
          } else {
            customAxios.post('person_group', userGroup)
                .then(response => {

                  if (response.status === 201) {
                    this.userGroups.push(response.data)
                    this.groupDialog = false
                    this.personGroupName = ''
                    this.personGroupDescription = ''
                    this.isLocked = false
                    this.existGroupError = false
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }

                })
                .catch(error=> {
                  if(error.response.status===401){
                    this.$store.dispatch('authentication/logout')
                    this.$store.dispatch('alert/clear')
                    localStorage.clear()
                    router.push('/').then(r => {console.log("error: " + r)})
                  }

                })
          }
        }

      })


    },

    closePersonForm() {
      this.personAvatar = ''
      this.firstname = ''
      this.lastname = ''
      this.secname = ''
      this.personEmail = ''
      this.password = ''
      this.active = ''
      this.userEmail = ''
      this.roles = []
      this.personGroups = []
      this.id = null
      this.dialog = false


    },
    clearSearch() {
      this.searchBody = ''
    },
    generatePassword() {
      let text = ""
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

      for (var i = 0; i < 13; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))

      this.password = text
    },
    save() {

      this.$refs.userForm.validate().then(responce =>{
        if(responce.valid){
          if(this.personEmail.length==0) {
            let person = {
              firstname: capitalizeFirstLetter(this.firstname),
              lastname: capitalizeFirstLetter(this.lastname),
              secname: this.secname!=null ? capitalizeFirstLetter(this.secname) : '',
              username: this.username,
              personEmail: this.personEmail,
              password: this.password,
              active: this.active,
              userEmail: this.userEmail,
              roles: this.roles,
              personGroups: this.personGroups,
            }
            if(this.id){
              customAxios.putWithAuth('person/' + this.id, person)
                  .then(response => {

                    if (response.status === 202) {
                      let index = getIndex(this.users, response.data.id)
                      this.users.splice(index, 1, response.data)
                      this.dialog = false
                      this.firstname = ''
                      this.lastname = ''
                      this.secname = ''
                      this.personEmail = ''
                      this.password = ''
                      this.active = false
                      this.userEmail = ''
                      this.username = ''
                      this.roles = []
                      this.personGroups = []
                      this.id = null
                    } else if (response.status === 207) {
                      alert(response.data.message)
                      console.log(response)
                    } else {
                      alert("что то пошло не так")
                      console.log(response)
                    }
                  })
                  .catch(error=> {
                    console.log(error)
                  })
            } else {
              customAxios.post('person', person)
                  .then(response => {

                    if (response.status === 201) {
                      this.users.push(response.data)
                      this.dialog = false
                      this.firstname = ''
                      this.lastname = ''
                      this.secname = ''
                      this.personEmail = ''
                      this.password = ''
                      this.active = false
                      this.userEmail = ''
                      this.username = ''
                      this.roles = []
                      this.personGroups = []
                    }else if (response.status === 207) {
                      alert(response.data.message)
                      console.log(response)
                    } else {
                      alert("что то пошло не так")
                      console.log(response)
                    }
                  })
                  .catch(error=> {
                    console.log(error)
                  })
            }

          } else {

            let person = {
              firstname: capitalizeFirstLetter(this.firstname),
              lastname: capitalizeFirstLetter(this.lastname),
              secname: this.secname!=null ? capitalizeFirstLetter(this.secname) : '',
              username: this.username,
              personEmail: this.personEmail,
              password: this.password,
              active: this.active,
              userEmail: this.userEmail,
              roles: this.roles,
              personGroups: this.personGroups,
            }



            if (this.id) {
              customAxios.putWithAuth('person/' + this.id, person)
                  .then(response => {

                    if (response.status === 202) {
                      let index = getIndex(this.users, this.id)
                      this.users.splice(index, 1, response.data)
                      this.dialog = false
                      this.firstname = ''
                      this.lastname = ''
                      this.secname = ''
                      this.personEmail = ''
                      this.password = ''
                      this.active = false
                      this.userEmail = ''
                      this.username = ''
                      this.roles = []
                      this.personGroups = []
                      this.id = null
                    } else if (response.status === 207) {
                      alert(response.data.message)
                      console.log(response)
                    } else {
                      alert("что то пошло не так")
                      console.log(response)
                    }


                  })
                  .catch(error=> {
                    console.log(error)
                  })
            }  else {

              customAxios.post('person', person)
                  .then(response => {

                    if (response.status === 201) {
                      this.users.push(response.data)
                      this.dialog = false
                      this.firstname = ''
                      this.lastname = ''
                      this.secname = ''
                      this.personEmail = ''
                      this.password = ''
                      this.active = false``
                      this.userEmail = ''
                      this.username = ''
                      this.roles = []
                      this.personGroups = []
                    } else if (response.status === 207) {
                      alert(response.data.message)
                      console.log(response)
                    } else {
                      alert("что то пошло не так")
                      console.log(response)
                    }
                  })
                  .catch(error=> {
                    if(error.response.status===401){
                      console.log("Error:" + error.response.message)
                    }
                  })
            }

          }

        }
      })

    },
    editUser: function (event) {
      this.id = event.id
      this.firstname = event.firstname
      this.lastname = event.lastname
      this.secname = event.secname
      this.personEmail = event.personEmail
      this.active = event.active
      this.userEmail = event.userEmail
      this.username = event.username
      this.roles = event.roles
      this.personGroups = event.personGroups
      this.personAvatar = event.personAvatar
      this.dialog = true
    },
    deleteUser: function (item) {

     customAxios.delete('person/' + item.id)
          .then(response => {

            if (response.status === 202) {
              let index = getIndex(this.users, item.id);
              this.users.splice(index, 1)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
            if(error.response.status===401){
              this.$store.dispatch('authentication/logout')
              this.$store.dispatch('alert/clear')
              localStorage.clear()
              router.push('/').then(r => {console.log("error: " + r)})
            }

          })
    },
    userGroupFilter(item, queryText){
      console.log("filter item: "+ item)
      const textPersonGroupName = item.personGroupName.toLowerCase()
      const textPersonGroupDescription = item.personGroupDescription.toLowerCase()

      const searchText = queryText.toLowerCase()

      return textPersonGroupName.indexOf(searchText) > -1 ||
          textPersonGroupDescription.indexOf(searchText) > -1

    },

  },
  computed: {


    jwtHeaders(){
      let jwtTokenConfig = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + customStorage.get("user-token"),
        },
        responseType: 'blob'
      }
      return jwtTokenConfig
    },

  },
  created: function () {
    this.showLoader=true

    function getUsers() {
      return customAxios.getWithAuth('person')
    }

    function getUserGroups() {
      return customAxios.getWithAuth('person_group')
    }

    Promise.all([
      getUsers(),
      getUserGroups(),

    ])
        .then(results => {
          if(results[0].status===200){
            this.users = results[0].data;
            this.filteredUsers = results[0].data;
          }
          if(results[1].status===200){
            this.userGroups = results[1].data;
            this.filtereduserGroups = results[1].data;

            console.log(this.userGroups)
          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")

    });
  }
}

</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
