export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function setCurrentIdValue(value, currentId) {
  return currentId === value ? null : value;
}

export function getStringDateTime(data) {
  const d = data.split("T")[0];
  const t = data.split("T")[1];

  return `${d} ${t}`;
}

export const salonTypes = [
  { name: "Вебинар", typeValue: 0 },
  { name: "Внешняя ссылка", typeValue: 1 },
];

export function calculateWaveSectionSize(height) {
  const offsetWidth = window.document.body.offsetWidth;
  let minHeight = height;
  let paddingBottom = 100;
  const STANDART_WIDTH = 1000;

  if (offsetWidth > STANDART_WIDTH && offsetWidth <= STANDART_WIDTH * 2) {
    minHeight = minHeight * 2;
    paddingBottom = paddingBottom * 2;
  }

  if (offsetWidth > STANDART_WIDTH * 2 && offsetWidth <= STANDART_WIDTH * 3) {
    minHeight = minHeight * 3;
    paddingBottom = paddingBottom * 3;
  }

  if (offsetWidth > STANDART_WIDTH * 3 && offsetWidth <= STANDART_WIDTH * 4) {
    minHeight = minHeight * 4;
    paddingBottom = paddingBottom * 4;
  }

  if (offsetWidth > STANDART_WIDTH * 4 && offsetWidth <= STANDART_WIDTH * 5) {
    minHeight = minHeight * 5;
    paddingBottom = paddingBottom * 5;
  }

  if (offsetWidth > STANDART_WIDTH * 5) {
    minHeight = minHeight * 6;
    paddingBottom = paddingBottom * 6;
  }

  return {
    minHeight: minHeight + "px",
    paddingBottom: paddingBottom + "px",
  };
}
