<template>
  <div class="n-promo" ref="promoRef">
    <Swiper
      :modules="[EffectFade, Autoplay]"
      effect="fade"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :speed="2000"
      :loop="true"
      class="n-promo__bg"
      ref="swiperRef"
    >
      <SwiperSlide v-for="(v, i) in photos" :key="i">
        <AppImage :image="v" class="n-promo__img" />
      </SwiperSlide>
    </Swiper>

    <div class="wrapper" v-if="promoText">
      <div class="n-promo__title" ref="titleRef">
        <AppTitle mode="h1">
          <template v-slot:top>
            <AppIcon icon="home-icon" />
            <span>{{ t("welcome") }}</span>
          </template>
          <template v-slot:title>{{ promoText.title }}</template>
        </AppTitle>
        <div class="n-promo__subtitle">
          <span>{{ promoText.subtitle }}</span>
        </div>
        <p class="n-promo__description">{{ promoText.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { texts } from "@/app-data/texts";

import { t } from "@/utils/translate";
import { onMounted, ref, onUnmounted } from "vue";

import { promoSectionPhotos } from "@/app-data/static";
import { calculateWaveSectionSize } from "@/utils/common";

import AppIcon from "./AppIcon";
import AppImage from "./AppImage";
import AppTitle from "./AppTitle.vue";

import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/effect-fade";

export default {
  components: { AppIcon, AppImage, Swiper, SwiperSlide, AppTitle },
  setup() {
    const titleRef = ref();
    const promoText = texts.promo;
    const promoRef = ref();
    const photos = ref();
    const svgRef = ref();

    const minHeight = ref(0);

    function setPromoPhotos() {
      const offsetWidth = window.document.body.offsetWidth;
      let array;

      if (offsetWidth > 1200) {
        array = promoSectionPhotos.bigPhotos;
      }

      if (offsetWidth <= 1200) {
        array = promoSectionPhotos.middlePhotos;
      }

      if (offsetWidth <= 768) {
        array = promoSectionPhotos.smallPhotos;
      }

      photos.value = array;
    }

    function setPromoHeight() {
      const result = calculateWaveSectionSize(300);

      promoRef.value.style.minHeight = result.minHeight;
      promoRef.value.style.paddingBottom = result.paddingBottom;
    }

    window.addEventListener("resize", setPromoHeight);
    window.addEventListener("resize", setPromoPhotos);

    onMounted(() => {
      if (promoRef.value) {
        setPromoHeight();
        setPromoPhotos();
      }

      minHeight.value = titleRef.value.offsetHeight;
    });

    onUnmounted(() => {
      window.removeEventListener("resize", setPromoPhotos);
      window.removeEventListener("resize", setPromoHeight);
    });

    return {
      t,
      photos,
      EffectFade,
      Autoplay,
      setPromoHeight,
      promoText,
      promoRef,
      titleRef,
      minHeight,
      svgRef,
    };
  },
};
</script>

<style lang="scss">
.n-promo {
  position: relative;
  color: #fff;
  box-sizing: border-box;
  display: flex;

  &__title {
    height: 100%;
    position: relative;
    padding: 40px 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .app-title p {
    color: #fff;
    display: flex;
    justify-content: center;

    svg {
      stroke: #fff;
      margin-right: 8px;
    }
  }

  .n-promo__description,
  .app-title h1,
  .app-title p {
    transform: translate3d(-10%, 0, 0);
    opacity: 0;
  }

  .n-promo__description {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 50px;
  }

  .n-promo__description,
  &__subtitle {
    transform: translate3d(0, -10%, 0);
    opacity: 0;
  }

  &__subtitle {
    display: flex;
    justify-content: center;
  }

  &__img {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
    }
  }

  &__bg {
    position: absolute;
    @include full-bg;
  }

  .wrapper {
    position: relative;
    z-index: 2;
  }

  @media (max-width: 768px) {
    &__title {
      width: 100%;
    }
  }
}

.animated.n-promo {
  .n-promo__subtitle,
  .app-title h1,
  .app-title p,
  .n-promo__description,
  .n-promo__subtitle {
    animation: slide-fade-in 0.3s cubic-bezier(0.5, 1, 0.89, 1) 0s 1 forwards;
  }

  .app-title h1 {
    animation-delay: 0.2s;
    animation-duration: 0.5s;
  }

  .n-promo__subtitle {
    animation-delay: 0.3s;
  }

  .app-title p {
    animation-delay: 0.4s;
  }

  .n-promo__description {
    animation-delay: 0.6s;
  }
}
</style>
