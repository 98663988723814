export const texts = {
  promo: {
    subtitle: "для родителей",
    title: "Навигатор",
    description: `Cоздан для тех, кто хочет найти ответы по развитию, воспитанию,
        обучению детей и узнать, как воспитывать без наказаний и как учиться
        без домашки`,
  },
};

export const whatUserCanDo = {
  question: "Что можно сделать в Онлайн-навигаторе для родителей?",
  answers: [
    `Выбрать одну из десяти  развивающих программ или методик, в зависимости от возраста и потребностей ребенка и родителей.`,
    `Получить консультацию по выбранной программе или методике.`,
    `Вызвать «скорую педагогическую помощь» и найти ответы на волнующие вопросы.`,
    `Обсудить с экспертами, педагогами и психологами образовательные программы, методики, учебники, пособия.`,
    `Поучаствовать бесплатно в образовательных событиях.`,
    `Воспользоваться «маркетплейсом» и найти подходящую именно вашему ребенку программу обучения.`,
    `Встретиться с такими же неравнодушными родителями.`,
  ],
};

export const questionsAnswer = {
  questions: [
    "Как понять, готов ребенок к школе или нет и как это проверить?",
    "Что делать: обучать или развивать?",
    "Нужно ли родителям с ребенком выполнять домашнее задание или проверять его?",
    "Какие образовательные программы есть для детей определенного возраста?",
  ],
  answer: `Онлайн-навигатор для родителей поможет родителям в решении
              образовательных, воспитательных, учебных проблем с детьми.`,
};

export const companyInformation = {
  contacts: {
    phone: null,
    email: "navigator@eurekanet.ru",
    address: `105187, г. Москва, ул. Щербаковская, д. 53,
        кор. 3, офис 510`,
  },
};
