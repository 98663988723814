<template>
  <div class="event-preview">
    <div class="event-preview__img">
      <AppImage v-if="item.img" :image="item.img" />
    </div>

    <div class="event-preview__content">
      <AppTitle mode="h4" class="event-preview__title">
        <template v-slot:title>{{ item.eventName }}</template>
      </AppTitle>

      <div class="event-preview__text" v-html="item.eventDescription"></div>

      <SalonMetaData :data="{ date: item.createDateTime }" />

      <div class="event-preview__controls">
        <router-link
          v-if="item.course && item.courseId"
          :to="`/course/${item.courseId}`"
          class="event-preview__control"
        >
          {{ item.course.title }}
        </router-link>
        <router-link
            v-if="item.parentSalon && item.parentSalonId"
            :to="`/salon/${item.parentSalonId}`"
            class="event-preview__control"
        >
          {{ item.parentSalon.salonName }}
        </router-link>

        <a
          v-if="item.externalUrl"
          :href="item.externalUrl"
          class="event-preview__control"
          >{{ t("externalUrl") }}</a
        >

        <AppButton
          v-if="item.webinarId"
          class="event-preview__control"
          @click="$emit('startWebinar', item.webinar)"
        >
          {{ t("webinar") }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import AppImage from "@/elements/AppImage.vue";
import AppTitle from "@/elements/AppTitle.vue";
import MetaData from "./MetaData.vue";
import { t } from "@/utils/translate";
import AppButton from "@/elements/AppButton.vue";
import SalonMetaData from "@/components/SalonMetaData.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { t };
  },
  components: {SalonMetaData, AppImage, AppTitle, AppButton },
};
</script>

<style lang="scss">
.event-preview {
  text-decoration: none;
  color: $text;
  box-shadow: $shadow-2;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  display: block;
  transform: scale(1);
  transition: 0.2s transform;

  &:hover {
    transform: scale(1.01);
  }

  &__text {
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__img {
    background-image: $gradient;
    min-height: 50px;
  }

  &__content {
    padding: 32px 16px;
  }

  &__control {
    @include button-default;
    display: flex;
    text-decoration: none;

    &:nth-child(2n) {
      background-color: $accent-2;
    }

    + .event-preview__control {
      margin-top: 16px;
    }
  }

  &__controls {
    margin-top: 20px;
  }
}
</style>
