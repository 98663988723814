<template>
  <div class="n-modal">
    <AppBackButton @click="$emit('closeModal')" class="n-modal__close" />

    <div class="n-modal__content wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";

import AppBackButton from "@/elements/AppBackButton.vue";

export default {
  components: { AppBackButton },
  emits: ["closeModal"],
  setup() {
    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onUnmounted(() => {
      document.body.style.overflow = "";
    });
  },
};
</script>

<style lang="scss">
.n-modal {
  position: fixed;
  @include full-bg;
  z-index: 1000;
  background-color: rgba($text, 0.8);

  &__close {
    color: #fff;
    margin: 16px;

    svg {
      stroke: #fff;
    }
  }

  &__content {
    height: 100%;
    position: relative;
  }
}
</style>
