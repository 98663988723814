<template>
  <div class="questions-page">
    <WaveSection>
      <AppTitle mode="h2">
        <template v-slot:title>
          {{ t("questionnaire") }}:
          <span v-if="!!currentTemplate">
            <!-- {{ currentTemplate }} -->
          </span>
        </template>
      </AppTitle>
    </WaveSection>
    <div class="wrapper">
      <AppTooltip v-if="questionsPageAdvice">
        {{ questionsPageAdvice }}
      </AppTooltip>

      <div v-if="currentTemplate && currentTemplate.cretarias.length">
        <QuestionItem
          v-for="item in currentTemplate.cretarias"
          :key="item.id"
          :item="item"
          @toggleMultipleAnswer="toggleMultipleAnswer"
          @toggleSingleAnswer="toggleSingleAnswer"
        />

        <AppButton class="questions-page__submit" @click="complete">{{
          t("accept")
        }}</AppButton>
      </div>
      <LoaderRing v-else />
    </div>
  </div>
</template>

<script>
import AppTooltip from "@/components/AppTooltip.vue";

import { customAxios } from "@/service/customAxios";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { useQuestionnareStore } from "@/store/questionnareStore";
import { storeToRefs } from "pinia";
import { t } from "@/utils/translate";

import { computed } from "vue";
import { usePopupStore } from "@/store/popupStore";
import { useRouter } from "vue-router";

import AppTitle from "@/elements/AppTitle.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import QuestionItem from "@/components/QuestionItem.vue";

import WaveSection from "@/elements/WaveSection.vue";
import AppButton from "@/elements/AppButton.vue";

const questionsPageAdvice = `Все ваши ответы сразу сохраняются в Черновик. Необязательно сразу
          проходить весь тест. Сделайте сколько хотите и возвращайтесь в удобное
          для вас время.`;

export default {
  components: {
    WaveSection,
    AppTitle,
    QuestionItem,
    LoaderRing,
    AppButton,
    AppTooltip,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const { showPopup } = usePopupStore();
    const questionnareStore = useQuestionnareStore();

    const { getQuestionnare, setCurrentTemplate, setCurrentId, addNewUuid } =
      questionnareStore;

    const { currentTemplateUuid, currentTemplate, currentUuids } =
      storeToRefs(questionnareStore);

    const questionnareId = route.params.id;

    const defaultOptions = computed(() => {
      return {
        id: Number(questionnareId),
        uuid: currentTemplate.value.uuid,
      };
    });

    const hasUnchecked = computed(() => {
      const unchekedResults = currentTemplate.value.cretarias.filter((v) => {
        const allUnchecked = v.optionList.every((v) => v.checked === false);
        const hasFreeAnswer = v.optionList.some((v) => v.freeAnswerBody);

        if (hasFreeAnswer || !allUnchecked) {
          return false;
        }

        return true;
      });

      return unchekedResults.length;
    });

    async function getExistedQuestionnare() {
      try {
        const r = await getQuestionnare(
          questionnareId,
          currentTemplateUuid.value.uuid
        );
        setCurrentTemplate(r.data);
      } catch (e) {
        console.log(e);
      }
    }

    async function getNewTemplate() {
      try {
        const newTemplate = await customAxios.post("checklist/attempt", {
          checklistId: questionnareId,
        });
        addNewUuid(questionnareId, newTemplate.data.uuid);
        setCurrentTemplate(newTemplate.data);
      } catch (e) {
        showPopup("Такого опроса не существует", "error");
        router.push("/404");
      }
    }

    async function init() {
      try {
        if (!currentUuids.value.length) {
          await getNewTemplate();
          console.log('СОздался новый');
        } else {
          const currentUuid = currentUuids.value.findIndex(
            (v) => v.questionnareId === questionnareId
          );

          if (currentUuid !== -1) {
            const currentTemplate = await getQuestionnare(
              questionnareId,
              currentUuid.uuid
            );

            setCurrentTemplate(currentTemplate.data);
            console.log('Взяли старый');
          } else {
           await getNewTemplate();
           console.log('СОздался новый');
          }
        }
      } catch (e) {
        showPopup(t("something.went.wrong"), "error");
      }
    }

    async function complete() {
      console.log("questionnareId: " + questionnareId);
      try {
        const r = await customAxios.post("checklist/attempt/confirm", {
          checklistId: Number(questionnareId),
          uuid: currentTemplate.value.uuid,
          completed: true,
        });

        if (r.status === 202) {
          console.log(currentTemplate.value.uuid);
          questionnareStore.removeUuidFromUser(currentTemplate.value.uuid);

          showPopup(t("questionnaire.finish.message"), "success");
          router.push("/");
        }
      } catch (e) {
        showPopup("Что то пошло не так. Попробуйте еще раз", "error");
      }
    }

    function clearPreviousAnswer(itemOptionList, options) {
      const comparedId = options.optionId;

      itemOptionList.forEach(async (v) => {
        const clearingOptions = { ...options };
        const freeAnswerType =
          Object.hasOwn(clearingOptions, "feeOptionBody") || v.freeAnswer;

        if (v.id !== comparedId) {
          if (Object.hasOwn(clearingOptions, "optionId")) {
            clearingOptions.optionId = v.id;
          }

          if (freeAnswerType) {
            clearingOptions.freeAnswerBody = null;
            delete clearingOptions.state;
            delete clearingOptions.criteriaId;
          }

          if (!v.freeAnswer) {
            clearingOptions.criteriaId = v.criteriaId;
            clearingOptions.state = false;
            delete clearingOptions.freeAnswerBody;
          }

          await customAxios.post("checklist/attempt/criteria", {
            ...defaultOptions.value,
            ...clearingOptions,
          });
        }
      });
    }

    async function saveAnswer({ itemOptionList, options }) {
      console.log("Options: " + options);
      clearPreviousAnswer(itemOptionList, options);

      try {
        await customAxios.post("checklist/attempt/criteria", {
          id: Number(questionnareId),
          uuid: currentTemplate.value.uuid,
          ...options,
        });

        showPopup(t("draft.saved"), "tooltip");
        getExistedQuestionnare();
      } catch (e) {
        showPopup("Что то пошло не так. Попробуйте еще раз", "error");
      }
    }

    async function toggleSingleAnswer(data) {
      const options = {
        id: Number(questionnareId),
        uuid: currentTemplate.value.uuid,
        state: data.status,
        criteriaId: data.option.criteriaId,
        optionId: data.option.id,
      };

      if (data.feeOptionBody) {
        options.feeOptionBody = data.feeOptionBody;
      }

      try {
        await customAxios.post("checklist/attempt/criteria", options);
        showPopup(t("draft.saved"), "tooltip");
        await getExistedQuestionnare();
      } catch (e) {
        showPopup("Что то пошло не так. Попробуйте еще раз", "error");
      }
    }

    // async function toggleSingleFreeAnswer(data) {
    //   const options = {
    //     id: Number(questionnareId),
    //     uuid: currentTemplate.value.uuid,
    //     state: data.status,
    //     criteriaId: data.option.criteriaId,
    //     optionId: data.option.id,
    //     feeOptionBody: data.feeOptionBody,
    //   };
    //   console.log('Сохранен free');

    //   try {
    //     await customAxios.post("checklist/attempt/criteria", options);
    //     await getExistedQuestionnare();

    //     showPopup(t("draft.saved"), "tooltip");
    //   } catch (e) {
    //     showPopup("Что то пошло не так. Попробуйте еще раз", "error");
    //   }
    // }

    async function toggleMultipleAnswer(data) {
      console.log("toggleMultipleAnswer");
      const max = data.item.criteria.maxMultipleAnswersCount;
      const checkedItems = data.item.optionList.filter((v) => v.checked).length;

      const options = {
        id: Number(questionnareId),
        uuid: currentTemplate.value.uuid,
        state: data.status,
        criteriaId: data.option.criteriaId,
        optionId: data.option.id,
      };

      if (data.feeOptionBody) {
        options.feeOptionBody = data.feeOptionBody;
      }

      console.log(options);

      if ((data.status && checkedItems < max) || !data.status) {
        try {
          await customAxios.post("checklist/attempt/criteria", options);
          await getExistedQuestionnare();
          showPopup(t("draft.saved"), "tooltip");
        } catch (e) {
          showPopup("Что то пошло не так. Попробуйте еще раз", "error");
        }
      }
    }

    onMounted(async () => {
      setCurrentId(questionnareId);
      init();
    });

    return {
      toggleSingleAnswer,
      toggleMultipleAnswer,
      questionnareId,
      hasUnchecked,
      saveAnswer,
      t,
      currentTemplate,
      complete,
      questionsPageAdvice,
    };
  },
};
</script>

<style lang="scss">
.questions-page {
  .wrapper {
    @include page;
  }

  .question-item + .question-item {
    margin-top: 40px;
  }

  &__submit,
  &__save-draft {
    @include button-default;
    margin: 40px 16px;
    padding: 8px 16px;
  }

  &__advice {
    padding: 40px 20px;
    border-left: 2px solid $accent-2;
    margin-bottom: 40px;
    p {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
</style>
