import { defineStore } from "pinia";
import { customStorage } from "@/service/customStorage";

export const useUserStore = defineStore("user", {
  state: () => ({
    userToken: customStorage.get("user-token"),
    user: JSON.parse(customStorage.get("navigator-user")),
    courses: [],
    favorites: [],
    currentCourse: null,
    currentWebinar: null,
    previousReview: null,
    editedWebinarData: null,
  }),
  getters: {
    personAvatar() {
      return this.user.personAvatar;
    },
    isAdmin() {
      if (!this.user) {
        return false;
      } else if (this.user) {
        return !!this.user.roles.filter((v) => v === "ROLE_ADMIN").length;
      }
    },
    isJoined() {
      if (!this.currentCourse) {
        return false;
      } else {
        const existed = this.currentCourse.participantSet.findIndex(
          (v) => v.personId === this.user.id
        );
        return existed !== -1;
      }
    },
    inFavorite() {
      if (!this.currentCourse) {
        return false;
      } else if (this.currentCourse && this.favorites) {
        const result = this.favorites.findIndex(
          (v) => v.id === this.currentCourse.id
        );

        return result === -1 ? false : true;
      }
    },
  },
  actions: {
    setToken(token) {
      this.userToken = token
      customStorage.set("user-token", this.userToken)
    },
    setUserFavorites(favorites) {
      this.favorites = favorites;
    },
    setUser(value) {
      this.user = value;
      customStorage.set("navigator-user", JSON.stringify(this.user));
    },
    clearUser() {
      this.user = null
      customStorage.remove("navigator-user");
      customStorage.remove("user-token");
    },
    setCurrentCourse(course) {
      this.currentCourse = course;
    },
    setCurrentWebinar(webinar) {
      this.currentWebinar = webinar;
    },
    setJoiningStatus(v) {
      console.log(v);
      this.joined = v;
    },
    setPreviousReview(text) {
      this.previousReview = text;
    },
    addEditedWebinarData(v) {
      this.editedWebinarData = v;
    },
  },
});
