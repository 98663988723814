<template>
  <UserContentWrapper
    :title="t('salons.events')"
    @back="$emit('back')"
    class="user-salon"
  >
      <div v-if="$route.path === '/user/salon'">
        <AppBackButton :linkMode="true" link="/user/panel" />
        <LinksList :links="commonlinks"></LinksList>
        <LinksList v-if="isAdmin" :links="adminLinks"></LinksList>
      </div>

      <router-view v-else></router-view>
  </UserContentWrapper>
</template>

<script>
import { t } from "@/utils/translate";

import LinksList from "@/elements/LinksList.vue";
import UserContentWrapper from "@/elements/UserContentWrapper.vue";
import AppBackButton from "@/elements/AppBackButton.vue";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

const commonlinks = [
  { id: 1, link: "/user/salon/list", title: "Перейти к списку" },
];
const adminLinks = [
  { id: 1, link: "/user/salon/planning", title: t(`salons.event.add`) },
];
export default {
  components: {
    UserContentWrapper,
    LinksList,
    AppBackButton,
  },
  setup() {
    const userStore = useUserStore();
    const { isAdmin } = storeToRefs(userStore);

    return {
      t,
      adminLinks,
      commonlinks,
      isAdmin,
    };
  },
};
</script>

<style lang="scss">
.user-salon {
  &__menu {
    button + button {
      margin-top: 40px;
    }
  }
}
</style>
