<template>
  <div class="question-item">
    <p class="question-item__question">{{ item.criteria.criteriaBody }}</p>

    <div class="question-item__divider"></div>
    <div v-for="option in item.optionList" :key="option.id">
      <div v-if="typeOfAnswers === 'multiple'" class="question-item__input">
        <input
          :checked="option.checked"
          type="checkbox"
          :id="option.id"
          @change="(e) => toggleAnswer(e, option)"
        />
        <label :for="option.id">{{ option.optionBody }}</label>
      </div>

      <div v-if="typeOfAnswers === 'multiple free'">
        <div class="question-item__input">
          <input
            :checked="option.checked"
            type="checkbox"
            :id="option.id"
            @change="(e) => toggleAnswer(e, option)"
          />
          <label :for="option.id">{{ option.optionBody }}</label>
        </div>

        <div v-if="showTextarea && option.freeAnswer">
          <textarea
            placeholder="Ваш вариант ответа"
            v-model="freeAnswerText"
          ></textarea>
          <ColoredButton @click="saveFreeAnswer(option)">{{
            t("save")
          }}</ColoredButton>
        </div>
      </div>

      <div
        v-if="typeOfAnswers === 'single'"
        @change="(e) => toggleAnswer(e, option)"
        class="question-item__input"
      >
        <input
          :checked="option.checked"
          type="radio"
          :name="item.id"
          :id="option.id"
        />
        <label :for="option.id">{{ option.optionBody }}</label>
      </div>

      <div v-if="typeOfAnswers === 'single free'" class="free-answer">
        <div class="question-item__input">
          <input
            :checked="option.checked"
            type="radio"
            :name="item.id"
            :id="option.id"
            @change="(e) => toggleAnswer(e, option)"
          />
          <label :for="option.id">{{ option.optionBody }}</label>
        </div>

        <div v-if="showTextarea && option.freeAnswer">
          <textarea
            placeholder="Ваш вариант ответа"
            v-model="freeAnswerText"
          ></textarea>
          <ColoredButton @click="saveFreeAnswer(option)">{{
            t("save")
          }}</ColoredButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { ref, onMounted, computed } from "vue";

import ColoredButton from "@/elements/ColoredButton.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emit: [
    "toggleMultipleAnswer",
    "toggleMultipleFreeAnswer",
    "toggleSingleFreeAnswer",
    "toggleSingleAnswer",
  ],

  setup(props, ctx) {
    const showTextarea = ref(false);
    const freeAnswerText = ref("");

    const typeOfAnswers = computed(() => {
      let type;
      let hasFree = props.item.optionList.findIndex((v) => v.freeAnswer);
      if (props.item.multipleAnswers) {
        type = "multiple";
      } else {
        type = "single";
      }
      if (hasFree !== -1) {
        type = type + " free";
      }
      return type;
    });

    function toggleAnswer(e, option) {
      const options = {
        status: e.target.checked,
        item: props.item,
        option: option,
      };

      if (typeOfAnswers.value === "single") {
        ctx.emit("toggleSingleAnswer", options);
        showTextarea.value = false;
      }

      if (typeOfAnswers.value === "multiple") {
        ctx.emit("toggleMultipleAnswer", options);
      }

      if (typeOfAnswers.value === "multiple free" && !option.freeAnswer) {
        ctx.emit("toggleMultipleAnswer", options);
      }

      if (typeOfAnswers.value === "single free" && !option.freeAnswer) {
        ctx.emit("toggleSingleAnswer", options);
        showTextarea.value = false;
      }

      if (option.freeAnswer && e.target.checked) {
        showTextarea.value = true;
      }

      if (option.freeAnswer && !e.target.checked) {
        showTextarea.value = false;
        ctx.emit("toggleSingleAnswer", options);
      }
    }

    function saveFreeAnswer(option) {
      const options = {
        status: true,
        item: props.item,
        option: option,
        feeOptionBody: freeAnswerText.value,
      };

      if (typeOfAnswers.value === "single free") {
        ctx.emit("toggleSingleAnswer", options);
      }

      if (typeOfAnswers.value === "multiple free") {
        ctx.emit("toggleMultipleAnswer", options);
      }
    }

    onMounted(() => {
      if (props.item) {
        const existedFreeAnswer = props.item.optionList.filter(
          (v) => v.freeAnswer
        );
        if (existedFreeAnswer.length) {
          console.log(existedFreeAnswer[0].feeOptionBody);
        }
      }
    });

    return {
      saveFreeAnswer,
      freeAnswerText,
      typeOfAnswers,
      toggleAnswer,
      showTextarea,
      t,
    };
  },
  components: { ColoredButton },
};
</script>

<style lang="scss">
.question-item {
  @include card;
  border-radius: 16px;

  &__divider {
    margin: 16px 0;
    height: 1px;
    background-color: rgba($text, 0.1);
  }

  &__input {
    margin: 8px 0;
    display: flex;
    align-items: center;
    position: relative;

    input {
      margin-right: 8px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    label {
      cursor: pointer;
      flex-grow: 1;
      border-radius: 8px;
      padding: 8px 16px;
      font-size: 20px;
      transition: all 0.2s;
      line-height: 30px;
      background-color: rgba($text, 0.1);

      &:hover {
        background-color: rgba($accent-1, 0.3);
      }
    }

    input:checked + label {
      background-color: rgba($accent-1, 0.5);

      &:hover {
        background-color: rgba($accent-1, 0.3);
      }
    }
  }

  textarea {
    border: 1px solid $text;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    margin: 8px;
  }

  label {
    margin-left: 8px;
  }

  &__question {
    margin-bottom: 20px;
  }
}
</style>
