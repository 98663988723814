import { onMounted, onUnmounted } from "vue";

export function useObserver(refToWatch, callback, options) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry && entry.isIntersecting) {
        callback(entry.target);
  
        // if (once) {
        //   observer.unobserve(entry.target);
        // }
      }
    })
  }, options);

  onMounted(() => {
    if (Array.isArray(refToWatch.value)) {
      refToWatch.value.forEach((el) => {
        observer.observe(el.$el || el);
      })
    } else {
      observer.observe(refToWatch.value.$el || refToWatch.value);
    }
  });

  onUnmounted(() => {
    observer.disconnect();
  });

  return observer;
}
