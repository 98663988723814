<template>
  <div class="login-please">
    <AppTitle mode="h4">
      <template v-slot:title>
        <div v-html="t(`salon.access.denied.steps`)"></div>
      </template>
    </AppTitle>

    <router-link to="/auth/registration" class="login-please__link">
      <span>{{ t("to.auth.page") }}</span>
      <AppIcon icon="arrow-icon" />
    </router-link>
  </div>
</template>

<script>
import AppTitle from "@/elements/AppTitle.vue";
import AppIcon from "./AppIcon.vue";

import { t } from "@/utils/translate";

export default {
  setup() {
    return { t };
  },
  components: { AppTitle, AppIcon },
};
</script>

<style lang="scss">
.login-please {
  padding: 8px;
  position: relative;
  text-align: center;

  .app-title {
    justify-content: center;
    margin-bottom: 30px;

    p {
      margin-bottom: 20px;
    }
  }

  ol {
    text-align: left;
  }

  li {
    margin: 8px 0;
  }

  &__link {
    @include button-default;
    @include flex-center;
    text-decoration: none;

    svg {
      stroke: #fff;
    }
  }

  @media (max-width: 767px) {
    &__link {
      line-height: 50px;
    }
  }
}
</style>
