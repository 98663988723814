<template>
  <NModal @closeModal="$emit('closeModal')" class="webinar-modal">
    <AppTitle mode="h2">
      <template v-slot:title>{{ webinar.webinarName }}</template>
    </AppTitle>
    <iframe :src="webinar.webinarRecordUrl" allowfullscreen></iframe>
  </NModal>
</template>

<script>
import NModal from "@/components/NModal.vue";
import AppTitle from "@/elements/AppTitle.vue";

export default {
  emits: ["closeModal"],
  components: { NModal, AppTitle },
  props: {
    webinar: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.webinar-modal {
  overflow: hidden;
  
  h2 {
    text-align: left;
    color: #fff;
    margin-bottom: 16px;
  }

  iframe {
    width: 100%;
    height: 100%;
    max-width: 960px;
    aspect-ratio: 16 / 9;
    margin: 0 auto;
    border: none;
    border-radius: 8px;
  }
}
</style>
