<template>
  <AuthForm :title="t('entrance')">
    <template v-slot:inputs>
      <AppBackButton @click="$emit('change-form', 'restorePassword')" />

      <AppTitle mode="h3" class="n-auth-code-v__title">
        <template v-slot:title>{{ t("restore.password") }}</template>
        <template v-slot:text>Пожалуйста, введите полученный код</template>
      </AppTitle>

      <AppInput
        v-model="vc$.code.$model"
        :label="t('verification.code')"
        id="f-code"
        :class="setValidationStatusClass(vc$.code)"
        :element="vc$.code"
        @blur="vc$.code.$touch"
      />
    </template>
    <template v-slot:buttons>
      <AppButton
        class="auth-page__btn flex"
        :disabled="vc$.$invalid"
        @click="$emit('approve-code', codeFromEmail)"
      >
        <span>{{ t("approve") }}</span>
      </AppButton>

      <AppButton
        class="n-auth-code-v__btn auth-page__btn flex"
        @click="$emit('change-form', 'restorePassword')"
      >
        <span>Код не пришел</span>
      </AppButton>
    </template>
  </AuthForm>
</template>

<script>
import { t } from "@/utils/translate";
import { ref } from "vue";

import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import AppTitle from "@/elements/AppTitle";
import AppBackButton from "@/elements/AppBackButton.vue";
import AuthForm from "./AuthForm.vue";

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { setValidationStatusClass } from "@/utils/validation";

import { useRoute } from "vue-router";

export default {
  components: { AppInput, AppButton, AppTitle, AppBackButton, AuthForm },
  emits: ["approve-code", "change-form"],
  setup(_, ctx) {
    const codeFromEmail = ref("");
    const cRules = {
      code: { required },
    };

    const route = useRoute();

    if (route.params.code) {
      ctx.emit("change-form", "codeVerification");
      codeFromEmail.value = route.params.code;
    }

    const vc$ = useVuelidate(cRules, {
      code: codeFromEmail,
    });

    return { t, setValidationStatusClass, vc$, codeFromEmail };
  },
};
</script>

<style lang="scss">
.n-auth-code-v {
  &__title {
    margin-bottom: 30px;
  }

  &__btn {
    background-color: #fff;
    color: $accent-1;
    border: 2px solid $accent-1;

    &:hover {
      color: #fff;
    }
  }
}
</style>
