<template>
  <div class="book-shelf-page">
    <StateWrapper
        :content="data"
        :loadingStatus="loadingStatus"
        :checkLogin="false"
    >
      <PageTitle :title="t('bookShelf')" />
      <div class="wrapper">
        <TagFilters
            v-if="tags.length"
            :filters="tags"
            @toggleFilters="toggleFilters"
            @clearFilters="clearFilters"
            :currentFilters="currentFilters"
        />

        <div class="book-shelf-page__results">
          <div class="book-shelf-page__items">
            <AccordionItem
                v-for="(v, idx) in getSlicedArray(
                filteredData,
                itemsPerPage,
                currentPage
              )"
                :key="v.id"
                :isOpen="currentId === v.id"
                @open="setCurrentId"
                :style="{ 'animation-delay': `0.${idx}s` }"
                :id="v.id"
            >
              <template v-slot:top>{{ v.bookName }}</template>
              <template v-slot:bottom>

                <div class="ma-2">
                  <h4>О книге:</h4>
                  <p>{{v.bookAnnotation}}</p>
                </div>

                <div class="ma-2">
                  <h4 v-if="v.authorList.length >1">Авторы:</h4>
                  <h4 v-else>Автор:</h4>
                  <div>
                  <p v-for="c in v.authorList" :key="c.id">
                    {{c.lastname }} {{c.firstname}} {{c.secname}}</p>
                  </div>
                </div>

                <div class="ma-2">
                  <h4>ISBN:</h4>
                  <div>
                    <p>{{v.ISBN }}</p>
                  </div>
                </div>

                <div class="ma-2">
                  <h4 v-if="v.links.length >1">Ссылки:</h4>
                  <h4 v-else>Ссылка:</h4>
                  <div class="book-shelf-page__chips">
                    <span v-for="c in v.links" :key="c.id" v-on:click="openLink(c)">
                      {{c.linkName }}</span>
                  </div>
                </div>

                <div class="ma-2">
                  <h4>Ярлыки:</h4>
                  <div class="book-shelf-page__chips">
                  <span v-for="c in v.tags" :key="c.id">{{
                      c.tagName
                    }}</span>
                  </div>
                </div>



              </template>
            </AccordionItem>
          </div>

          <div class="flex flex__jc-c" v-if="filteredData.length">
            <vue-awesome-paginate
                :total-items="filteredData.length"
                v-model="currentPage"
                :items-per-page="itemsPerPage"
                :max-pages-shown="maxPagesShown"
            />
          </div>
        </div>
      </div>
    </StateWrapper>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { t } from "@/utils/translate";
import { customAxios } from "@/service/customAxios";

import PageTitle from "@/elements/PageTitle.vue";
import StateWrapper from "@/components/StateWrapper.vue";
import AccordionItem from "@/components/AccordionItem.vue";

import { setCurrentIdValue } from "@/utils/common";
import { usePagination } from "@/composables/usePagination";
import { computed } from "vue";
import TagFilters from "@/components/TagFilters.vue";

export default {
  components: {
    TagFilters,
    StateWrapper,
    PageTitle,
    AccordionItem
  },
  setup() {
    const loadingStatus = ref("loading");

    const currentId = ref(null);
    const data = ref([]);
    const tags = ref([]);
    const currentFilters = ref([]);

    const { getSlicedArray } = usePagination();
    const maxPagesShown = ref(5);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);

    async function getBook() {
      try {
        const r = await customAxios.get("bookshelf");
        loadingStatus.value = "loaded";
        return r.data;
      } catch (e) {
        loadingStatus.value = "error";
        console.log(e);
      }
    }

    async function getBookTags() {
      try {
        const t = await customAxios.get("bookshelf/tag");
        return t.data;
      } catch (e) {
        console.log(e);
      }
    }
    async function openLink(item) {
      try {
        window.open(item.linkBody, '_blank');
      } catch (e) {
        console.log(e);
      }
    }

    function antiNbsp(source) {
      return String(source).replace(/&nbsp;/g, " ");
    }

    function setCurrentId(value) {
      currentId.value = setCurrentIdValue(value, currentId.value);
    }

    const filteredData = computed(() => {
      let result = [];

      if (!currentFilters.value.length) {
        result = data.value;
      } else {
        data.value.forEach((d) => {
          currentFilters.value.forEach((f) => {
            d.tags.forEach((t) => {
              if (f.tagName === t.tagName) {
                result.push(d);
                currentPage.value =1
              }
            });
          });
        });
      }

      return result;
    });

    function toggleFilters(f) {
      const existedIndex = currentFilters.value.findIndex((v) => v.id === f.id);
      if (existedIndex === -1) {
        currentFilters.value.push(f);
      } else {
        currentFilters.value.splice(existedIndex, 1);
      }
    }

    function clearFilters() {
      currentFilters.value = []
    }

    onMounted(async () => {
      // if (userToken.value) {
      data.value = await getBook();
      tags.value = await getBookTags();
      // }
    });

    return {
      clearFilters,
      loadingStatus,
      toggleFilters,
      itemsPerPage,
      currentPage,
      maxPagesShown,
      getSlicedArray,
      currentFilters,
      data,
      setCurrentId,
      currentId,
      t,
      tags,
      filteredData,
      antiNbsp,
      openLink
    };
  },
};
</script>

<style lang="scss">
.book-shelf-page {
  text-align: center;

  &__chips {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;

    span {
      color: $accent-1;
      border: 1px dashed $accent-1;
      padding: 8px;
      margin: 8px;
      border-radius: 10px;
    }
  }

  &__filters {
    text-align: left;
    margin-bottom: 60px;
  }

  &__filters-title {
    margin-bottom: 10px;
  }

  &__theme {
    @include button-default-not-accent;
    display: none;
    margin-right: 8px;

    &.active {
      display: flex;
    }

    &.current {
      background-color: $accent-1;
      color: #fff;
    }
  }

  &__search {
    margin-bottom: 60px;
  }

  .wrapper {
    @include page;
  }
  ul{
    margin-left: 40px;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }

  &__btn {
    @include button-default-not-accent;

    &.active {
      @include button-default;
    }

    & + .book-shelf-page__btn {
      margin-left: 16px;
    }
  }
}
</style>
