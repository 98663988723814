<template>
  <div class="user-preview">
    <router-link to="/user/settings/avatar" class="user-preview__avatar">
      <NAvatar :image="person.personAvatar" />
      <div class="user-preview__avatar-overlay">
        <AppIcon icon="plus-icon" />
      </div>
    </router-link>
  
    <router-link to="/user/account">
      <p class="user-preview__name" v-if="person.firstname && person.lastname">
        <span>{{ person.firstname }}</span>
        <span>{{ person.lastname }}</span>
      </p>
    </router-link>
  </div>
</template>

<script>
import AppIcon from "@/elements/AppIcon.vue";
import NAvatar from "@/elements/NAvatar.vue";

import { t } from "@/utils/translate";

export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { t };
  },
  components: { NAvatar, AppIcon },
};
</script>

<style lang="scss">
.user-preview {
  display: flex;
  align-items: center;

  &__avatar {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 16px;
    position: relative;
  }

  &__avatar-overlay {
    @include flex-center;
    position: absolute;
    background-color: $text;
    transform: translate3d(-100%, 0, 0);
    transition: all 0.2s;
    opacity: 0.8;

    @include full-bg;

    svg {
      stroke: #fff;
    }
  }

  &__avatar:hover {
    .user-preview__avatar-overlay {
      transform: translate3d(0, 0, 0);
    }
  }

  &__name {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    span + span {
      margin-left: 8px;
    }
  }

  a {
    text-decoration: none;
    color: $text;
  }
}
</style>
