<template>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab value="1" >{{t('questionnaires')}}</v-tab>
      <v-tab value="2" @click="tab=2" >{{t('questionnaire.types')}}</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item value="1" class="align-self-stretch">
        <v-card-title class="card-anti-glam-header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>{{t('questionnaires')}}</h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="questionnaireSearchValue"
                  label="Что ищем"
                  
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createQuestionnaire">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="questionnaireHeaders"
                  :items="questionnaires"
                  alternating
                  show-index
                  :search-value="questionnaireSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-isActive="{isActive}">
                  <p v-if="isActive">Да</p>
                  <p v-else>нет</p>
                </template>

                <template #item-createDate="{createDate}">
                  {{ createDate.replace('T', ' ')}}
                </template>
                <template #item-checklistType="{checklistType}">
                  <span v-if="checklistType">
                     {{ checklistType.typeName}}
                  </span>

                </template>


                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="checklistCounters(item)"
                  >
                    <span>Статистика ответов</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="сhecklistSubjects(item)"
                  >
                    <span>Попытки</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editChecklist(item)"
                  >
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteChecklist(item)"
                  >
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="2" class="align-self-stretch">
        <v-card-title class="card-anti-glam-header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>{{t('questionnaire.types')}}</h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="questionnaireTypeSearchValue"
                  label="Что ищем"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createChecklistType">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="questionnaireTypeHeaders"
                  :items="questionnaireTypes"
                  alternating
                  show-index
                  :search-value="questionnaireTypeSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-complete="{complete}">
                  <p v-if="complete">Готово к использованию</p>
                  <p v-else>Не готово к использованию</p>

                </template>
                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editTypeChapters(item)"
                  >
                    <span>Разделы</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editTypeQuestions(item)"
                  >
                    <span>Вопросы</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editType(item)"
                  >
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteType(item)"
                  >
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="3" class="align-self-stretch">
        <v-card-title class="card-anti-glam-header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>{{t('questionnaire.type.chapters')}} "{{selectedType.typeName}}" </h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="questionnaireTypeChapterSearchValue"
                  label="Что ищем"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="tab=2">
                <span>Назад</span>
              </AppButton>
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createChapter">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="typeChaptersHeaders"
                  :items="questionnaireTypeChapters"
                  alternating
                  show-index
                  :search-value="questionnaireTypeChapterSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editChapter(item)"
                  >
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteChapter(item)"
                  >
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="4" class="align-self-stretch">
        <v-card-title class="card-anti-glam-header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>{{t('questionnaire.type.questions')}} "{{selectedType.typeName}}" </h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="questionnaireTypeQuestionsSearchValue"
                  label="Что ищем"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="tab=2">
                <span>Назад</span>
              </AppButton>
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createQuestion">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="questionnaireTypeQuestionHeaders"
                  :items="questionnaireTypeQuestions"
                  alternating
                  show-index
                  :search-value="questionnaireTypeQuestionsSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-multipleAnswers="{multipleAnswers}">
                  <span v-if="!!multipleAnswers===true">Да</span>
                  <span v-else>Нет</span>
                </template>
                <template #item-maxMultipleAnswersCount="{maxMultipleAnswersCount,multipleAnswers}">
                  <span v-if="multipleAnswers===true">
                    <span v-if="maxMultipleAnswersCount">{{maxMultipleAnswersCount}}</span>
                    <span v-else>Не задано</span>
                  </span>
                  <span v-else>Не предусмотрено</span>
                </template>
                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="questionAnswers(item)"
                  >
                    <span>Ответы</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editQuestion(item)"
                  >
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteQuestion(item)"
                  >
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="5" class="align-self-stretch">
        <v-card-title class="card-anti-glam-header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Ответы</h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="answerSearchValue"
                  label="Что ищем"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="tab=4">
                <span>Назад</span>
              </AppButton>
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createAnswer">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="answerHeaders"
                  :items="answers"
                  alternating
                  show-index
                  :search-value="answerSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-freeAnswer="freeAnswer">
                  <span v-if="freeAnswer===true">Да</span>
                  <span v-else>Нет</span>
                </template>
                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editAnswer(item)"
                  >
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteAnswer(item)"
                  >
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="6" class="align-self-stretch"  >
        <v-card-title class="card-anti-glam-header">
          <v-row>
            <v-col cols=12>
              <h3 class="ma-2">Ход анкетирования</h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-title class="card-anti-glam-header" style="min-height: 5vh;">
          <v-row>
            <v-col cols=12>
              <v-text-field
                  v-model="checklistSubjectSearchBody"
                  label="Найти"
                  clearable
                  hint="Введите ключевое слово для поиска"
                  rounded
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="min-height: 80vh;">
          <div class="d-none d-sm-block">
            <v-table fixed-header class="h-100">
              <thead>
              <tr>
                <th class="text-left">
                  <b># </b>
                  <font-awesome-icon v-on:click="bySubjectId" icon="fa-solid fa-sort" />
                </th>
                <th class="text-left">
                  <b>Добавлено</b>
                  <font-awesome-icon v-on:click="bySubjectAddeded" icon="fa-solid fa-sort" />
                </th>

                <th class="text-left">
                  <b>Действие</b>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in checklistSubjectPaginatedData" :key="item.id" >
                <td><p>{{sortedСhecklistSubject.indexOf(item) +1}}</p></td>
                <td><p>{{(item.createDate).replace('T', ' ')}}</p></td>
                <td>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="showSubjectOptions(item)"
                  >
                    <span>анкета</span>
                  </AppButton>
                </td>
              </tr>
              </tbody>
            </v-table>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card-glam-footer" style="min-height: 5vh;"  >
          <v-container class="text-center">
            <v-pagination
                v-model="checklistSubjectPage"
                :length="checklistSubjectPageCount"
                :total-visible="3"
                size="small"
            ></v-pagination>
          </v-container>
        </v-card-actions>
      </v-window-item>
    </v-window>
  </div>
  <v-dialog v-model="checklistDialog" scrollable persistent>
    <v-card>
      <v-card-title class="card-glam-header">
        <h5>Опрос</h5>
      </v-card-title>
      <v-card-text>
        <v-form ref="checklistForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="name"
                    :rules="checklistNameRules"
                    
                    hint="Укажите название"
                    :counter="60"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="описание*"
                    v-model="checklistDescription"
                    :rules="checklistDescriptionRules"
                    
                    :counter="255"
                    hint="Укажите описание"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="checklistTypeId"
                    :items="questionnaireTypes"
                    outlined
                    dense
                    chips
                    small-chips
                    closable-chips
                    item-value="id"
                    item-title="typeName"
                    label="Шаблон"
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.typeName"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.typeName"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-row
                    class="ma-auto"
                    fluid
                >
                  <v-switch
                      v-model="isActive"
                      :label="`Активно`"
                      color="success"
                  >
                  </v-switch>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card-glam-footer">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeChecklist"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveChecklist"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="typeDialog" scrollable>
    <v-card>
      <v-card-title class="card-glam-header">
        <h5 v-if="!!this.id">Редактирование шаблона опроса</h5>
        <h5 v-else>Добавление шаблона опроса</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="typeForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="typeName"
                    :rules="typeNameRules"
                    :counter="250"
                    
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="criteriaCount"
                    :items="questionsCounter"
                    dense
                    filled
                    label="Кол-во вопросов"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="chapterCount"
                    :items="chaptersCounter"
                    dense
                    filled
                    label="Кол-во разделов"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-row
                    class="ma-auto"
                    fluid
                >
                  <v-switch
                      v-model="complete"
                      :label="`Готово к использованию`"
                      color="success"
                  >
                  </v-switch>
                  <v-chip v-if="complete">Да</v-chip><v-chip v-else>Нет</v-chip>
                </v-row>
              </v-col>



            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card-glam-footer">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeType"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveType"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="showLoader" persistent >
    <loader-ring></loader-ring>
  </v-dialog>
  <v-dialog v-model="chapterDialog" scrollable persistent>
    <v-card>
      <v-card-title class="card-glam-header">
        <h5>Раздел вопроса</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="chapterForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    label="Название*"
                    v-model="chapterName"
                    :rules="chapterNameRules"
                    
                    hint="Укажите название раздела"
                    :counter="1500"
                    required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="chapterOrder"
                    outlined
                    :items="[1, 2, 3, 4, 5, 6,7 , 8 ,9 , 10]"
                    dense
                    label="Порядковый номер"
                >
                </v-select>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card-glam-footer">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeChapter"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveChapter"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="questionDialog" scrollable persistent>
    <v-card>
      <v-card-title class="card-glam-header">
        <h5>Вопрос</h5>
      </v-card-title>
      <v-card-text>
        <v-form ref="questionForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    label="Вопрос*"
                    v-model="criteriaBody"
                    :rules="criteriaBodyRules"
                    
                    :counter="1000"
                    hint="Укажите вопрос"
                    required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="optionsCount"
                    :items="answersCounter"
                    dense
                    filled
                    label="Кол-во ответов"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="chapterId"
                    :items="questionnaireTypeChapters"
                    dense
                    filled
                    item-value="id"
                    item-title="chapterName"
                    label="Раздел"
                >
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.chapterName"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-row
                    class="ma-auto"
                    fluid
                >
                  <v-switch
                      v-model="multipleAnswers"
                      :label="`Несколько ответов`"
                      color="success"
                  >
                  </v-switch>
                  <v-chip v-if="multipleAnswers">Да</v-chip><v-chip v-else>Нет</v-chip>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="multipleAnswers">
                <v-select
                    v-model="maxMultipleAnswersCount"
                    outlined
                    :items="[1, 2, 3, 4, 5, 6, 7 ,8 ,9,10 ,11,12,13,14,15,16,17,18,19,20]"
                    dense
                    label="Максимум ответов"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card-glam-footer">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeQuestion"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveQuestion"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="answerDialog" scrollable persistent>
    <v-card>
      <v-card-title class="card-glam-header">
        <h5>Ответ</h5>
      </v-card-title>
      <v-card-text>
        <v-form ref="answerForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    label="Ответ*"
                    v-model="optionBody"
                    :rules="optionBodyRules"
                    
                    :counter="1000"
                    hint="Укажите описание"
                    required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-row
                    class="ma-auto"
                    fluid
                >
                  <v-switch
                      v-model="freeAnswer"
                      :label="`Свободный ответ`"
                      color="success"
                  >
                  </v-switch>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-card-actions class="card-glam-footer">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeAnswer"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveAnswer"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showChecklistCounters" scrollable persistent>
    <v-card>
      <v-card-title class="card-glam-header">
        <h5>Статистика анкетирования</h5>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-container >
              <v-row v-for="(item, index) in counters" :key="index">
                <v-col>
                  <v-card  class="ma-6">
                    <v-card-title class="card-glam-header">
                      <span class="text-h6 left">{{item.criteriaBody}}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text v-for="(itemL, findex) in statSortedOptions(item.options)" :key="findex">
                      <v-row  class="text-left"  justify="end">

                        <v-col cols="10"  md="10"  sm="10" xl="11" lg="11" style="display: flex; align-items: center;">
                          {{itemL.optionBody}}
                        </v-col>
                        <v-col cols="2"  md="2"  sm="2" xl="1" lg="1" style="display: flex; align-items: center;"
                        >
                          <span class="text-h6">{{itemL.optionsCount}}</span>
                        </v-col>
                        <v-col v-if="itemL.freeAnswers.length>0" cols="2"  md="2"  sm="2" xl="1" lg="1" style="display: flex; align-items: center;">
                        </v-col>
                      </v-row>
                      <v-row v-for="(itemLL, ffindex) in itemL.freeAnswers" :key="ffindex">
                        <v-col cols="12" >
                          <v-alert v-if="itemLL!==null" color="success">
                            {{itemLL}}
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="card-glam-footer">
        <v-spacer></v-spacer>
        <v-btn rounded variant="outlined"  @click="showChecklistCounters=false">
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
      v-model="checklistSubjectCriteriaDialog"
      scrollable
      persistent
  >
    <v-card>
      <v-card-title class="card-glam-header">
        <h5>Анкетирование от {{selectedSubject.createDate}}</h5>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-container >
              <v-row>
                <v-col>
                  <v-card v-for="(item, index) in sortedCretarias" :key="index" class="ma-6" min-width="600px;">
                    <v-card-title class="card-glam-header">
                      <span class="text-h6 left">{{item.criteria.criteriaBody}}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text v-for="(itemL, findex) in resultSortedOptions(item)" :key="findex" class="text-left">
                      <v-row  justify="end">
                        <v-col style="display: flex; align-items: center;">
                          <v-card-text class="card-glam-answer-checked rounded" v-if="itemL.checked">
                            {{itemL.optionBody}}
                          </v-card-text>
                          <v-card-text class="card-glam-answer-unchecked rounded" v-else>
                            {{itemL.optionBody}}
                          </v-card-text>
                        </v-col>
                        <v-card class="w-100" v-if="((itemL.checked)&&(itemL.freeAnswer))">
                          <v-card-text >
                            <v-col cols="12">
                              <v-textarea
                                  label="Ваш вариант ответа*"
                                  v-model="itemL.feeOptionBody"
                                  :rules="optionBodyRules"

                                  variant="outlined"
                                  :counter="1000"
                                  hint="Введите Ваш вариант ответа и нажмите на конопку сохранить"
                                  required
                              ></v-textarea>
                            </v-col>
                          </v-card-text>
                          <v-card-actions class="card_ligth_actions" >
                            <v-spacer></v-spacer>
                            <v-btn
                                rounded
                                variant="outlined"
                            >
                              Сохранить
                            </v-btn>
                          </v-card-actions>

                        </v-card>


                      </v-row>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="card-glam-footer">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="checklistSubjectCriteriaDialog=false"
        >
          Закрыть
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>



</template>
<script>

import {useRouter} from "vue-router"
import { t } from "@/utils/translate";
import { customAxios} from "@/service/customAxios";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import _ from 'lodash';
import AppButton from "@/elements/AppButton.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
// function capitalizeFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

function dynamicSort(property, sortOrder) {
  return function (a,b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result
    if(property==='person'){
      result = (a[property.lastname] < b[property.lastname]) ? -1 : (a[property.lastname] > b[property.lastname]) ? 1 : 0;
    } else {
      result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    }

    return result * sortOrder;
  }
}


export default {
  name: 'AdminquestionnairesPage',

  props: [],
  components:{
    LoaderRing,
    AppButton,
    Vue3EasyDataTable
  },
  setup() {
    return { t};
  },


  data: () => ({
    questionnaires:[],
    questionnaireTypes:[],
    questionnaireTypeChapters:[],
    selectedType:{},
    
    questionnaireSearchValue:'',
    questionnairesDialog:false,
    typeDialog:false,
    eventDateTime:'',
    eventDateTimeAlert:false,

    questionnaireHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "name" , sortable: true  },
      { text: "Аннотация", value: "checklistDescription"},
      { text: "Активно", value: "isActive" , sortable: true},
      { text: "Добавлено", value: "createDate" , sortable: true},
      { text: "Тип", value: "checklistType"},
      { text: "Действие", value: "actions"},
    ],
    typeChaptersHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "chapterName" , sortable: true  },
      { text: "Порядковый номер", value: "chapterOrder"},
      { text: "Действие", value: "actions"},

    ],
    typeChapters:[],
    questionnaireTypeChapterSearchValue:'',
    questionnaireTypeSearchValue:'',
    questionnaireTypeHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "typeName" , sortable: true  },
      { text: "Вопросов", value: "criteriaCount"},
      { text: "Разделов", value: "chapterCount" , sortable: true},
      { text: "Статус", value: "complete" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
    questionnaireTypeQuestionsSearchValue:'',
    questionnaireTypeQuestionHeaders: [
      { text: "id", value: "id" },
      { text: "Вопрос", value: "criteriaBody" , sortable: true  },
      { text: "Ответов", value: "optionsCount"},
      { text: "Несколько ответов", value: "multipleAnswers" , sortable: true},
      { text: "Максимум ответов", value: "maxMultipleAnswersCount" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
    questionnaireTypeQuestions:[],
    checklistTypeId:'',
    questionOrder:1,
    chapterId:'',
    optionsCount:'',


    answerSearchValue:'',
    answerHeaders: [
      { text: "id", value: "id" },
      { text: "Ответ", value: "optionBody" , sortable: true  },
      { text: "Свободный ответ", value: "freeAnswer"},
      { text: "Действие", value: "actions"},
    ],
    answers:[],

    selectedQuestion:{},

    chapterDialog:false,

    id:'',
    tab:1,
    showLoader: false,
    title:'',
    titleRules: [
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],
    questionnairesAnnotation:'',
    questionnairesAnnotationRules: [
      v => (v && v.length <= 1500) || 'Значение до должно превышать 1500 символов!',
    ],
    questionnairesContent:'',

    published:false,
    externalUrl:'',

    eventName:'',
    eventDescription:'',
    eventBody:'',
    eventTypeId:1,

    chapterName:'',
    chapterOrder:1,
    chapterNameRules: [
      v => (v && v.length <= 250) || 'Значение не должно превышать 250 символов!',
    ],
    typeName:'',
    typeNameRules: [
      v => (v && v.length <= 250) || 'Значение не должно превышать 250 символов!',
    ],
    criteriaCount:1,
    chapterCount:1,

    questionDialog:false,
    criteriaBody:'',
    criteriaBodyRules: [
      v => (v && v.length <= 1000) || 'Значение не должно превышать 250 символов!',
    ],
    multipleAnswers:false,
    maxMultipleAnswersCount:0,
    checklistTypeChapters:[],

    answerDialog: false,
    criteriaId: undefined,
    optionBody: '',
    freeAnswer: false,
    optionBodyRules: [
      v => (v && v.length <= 1000) || 'Значение не должно превышать 250 символов!',
    ],

    complete: false,

    checklistNameRules: [
      v => (v && v.length <= 60) || 'Значение не должно превышать 250 символов!',
    ],
    checklistDescription:'',
    checklistDescriptionRules: [
      v => (v && v.length <= 255) || 'Значение не должно превышать 250 символов!',
    ],
    isActive:false,
    checklistDialog:false,
    showChecklistCounters: false,
    counters: [],
    checklistSubjectSearchBody:'',
    checklistSubjectDialog:false,
    noteCount:15,
    checklistSubjectPage:1,
    checklistSubjectCriteriaDialog: false,



  }),
  methods: {
    showSubjectOptions(item){
      this.selectedSubject = item
      console.log(item)
      this.checklistSubjectCriteriaDialog=true

      console.log(item)
    },

    сhecklistSubjects(item){
      this.selectedChecklist =item
      this.showLoader = true


      customAxios.getWithAuth('checklist/attempt/' + item.id)
          .then(response => {
            if (response.status === 200) {
              this.checklistSubjects =response.data
              this.showLoader = false
              this.tab = 6
            } else {
              alert("что то пошло не так")
              this.showLoader = false
            }
            console.log(response)

          })
          .catch(function (error) {
            console.log(error)
          })
    },

    resultSortedOptions(options) {
      return _.orderBy(options.optionList, 'id', 'asc');
    },
    statSortedOptions(options) {
      return _.orderBy(options, 'optionsCount', 'desc');
    },
    backToChecklists(){
      this.part=1
    },
    checklistCounters(checklist){
      const router = useRouter()
      this.showLoader = true;
      customAxios.getWithAuth('checklist/counters/' + checklist.id)
          .then(response => {
            if (response.status === 200) {
              this.counters = response.data
              this.showLoader = false
              this.showChecklistCounters = true
            } else {
              alert("что то пошло не так")
            }
            console.log(response)
          })
          .catch(function (error) {
            console.log(error)
            if(error.response.status===401){
              router.push('/login').then(r => {console.log("error: " + r)})
            }
          })
    },


    saveChecklist(){
      this.$refs.checklistForm.validate().then(response =>{
        if(response.valid){
          let checklist = {
            name: this.name,
            checklistDescription: this.checklistDescription,
            checklistTypeId: this.checklistTypeId,
            isActive: this.isActive,
          }
          if(this.id){
            customAxios.putWithAuth("checklist/"+ this.id, checklist)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.questionnaires, response.data.id);
                    this.questionnaires.splice(index, 1, response.data);
                    this.checklistDialog = false
                    this.name=''
                    this.checklistDescription=''
                    this.checklistTypeId = undefined
                    this.isActive = false
                    this.id=''

                  } else {
                    alert(response.data.message);
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });

          }else {
            customAxios.post("checklist", checklist)
                .then((response) => {
                  if (response.status === 201) {
                    this.questionnaires.push(response.data)
                    this.checklistDialog = false
                    this.name=''
                    this.checklistDescription=''
                    this.checklistTypeId = undefined
                    this.isActive = false
                    this.id=''
                  } else {
                    alert(response.data.message);
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
          }
        }
      })


    },
    closeChecklist(){
      this.checklistDialog = false
      this.name=''
      this.checklistDescription=''
      this.checklistTypeId = undefined
      this.isActive = false
      this.id=''
    },
    createQuestionnaire(){
      this.id='';
      this.checklistDialog=true
    },
    editChecklist(item){
      this.id = item.id
      this.checklistTypeId = item.checklistTypeId
      this.name = item.name
      this.checklistDescription = item.checklistDescription
      this.isActive = item.isActive
      this.checklistDialog = true

    },
    deleteChecklist(item){
      customAxios.delete("checklist/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.questionnaires, item.id);
              this.questionnaires.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },

    saveType(){
      this.$refs.typeForm.validate().then(response =>{
        if(response.valid){
          let type = {
            typeName: this.typeName,
            criteriaCount: this.criteriaCount,
            chapterCount: this.chapterCount,
            complete: this.complete,
          }
          if(this.id){
            customAxios.putWithAuth("checklist/type/"+ this.id, type)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.questionnaireTypes, response.data.id);
                    this.questionnaireTypes.splice(index, 1, response.data);
                    this.typeDialog = false
                    this.complete = false
                    this.typeName=''
                    this.criteriaCount = 1
                    this.chapterCount = 1
                    this.id=''

                  } else {
                    alert(response.data.message);
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });

          }else {
            customAxios.post("checklist/type", type)
                .then((response) => {
                  if (response.status === 201) {
                    this.questionnaireTypes.push(response.data)
                    this.typeDialog = false
                    this.complete = false
                    this.typeName=''
                    this.criteriaCount = 1
                    this.chapterCount = 1
                  } else {
                    alert(response.data.message);
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
          }
        }
      })


    },
    closeType(){
      this.typeDialog = false
      this.complete = false
      this.typeName=''
      this.criteriaCount = 1
      this.chapterCount = 1
      this.id=''
    },
    editType(item){
      this.id = item.id
      this.typeName = item.typeName
      this.criteriaCount = item.criteriaCount
      this.chapterCount = item.chapterCount
      this.complete = item.complete
      this.typeDialog = true

    },
    deleteType(item){
      customAxios.delete("checklist/type/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.questionnaireTypes, item.id);
              this.questionnaireTypes.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    createChecklistType(){
      this.id = undefined
      this.typeDialog = true
    },
    editTypeChapters(item){
      this.selectedType = item;
      customAxios.getWithAuth("checklist/chapter/" + item.id)
          .then((response) => {
            if (response.status === 200) {
              this.questionnaireTypeChapters=response.data;
              this.tab=3;
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });


    },
    editTypeQuestions(item){
      this.selectedType = item;
      customAxios.getWithAuth("checklist/type/criteria/" + item.id)
          .then((response) => {
            if (response.status === 200) {
              this.questionnaireTypeQuestions=response.data;
              customAxios.getWithAuth("checklist/chapter/" + item.id)
                  .then((response) => {
                    if (response.status === 200) {
                      this.questionnaireTypeChapters=response.data;

                    } else {
                      alert(response.data.message);
                      console.log(response);
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  });


            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
      this.tab=4;

    },

    closeChapter(){
      this.chapterName = ''
      this.chapterOrder=1
      this.id=''
      this.chapterDialog=false
    },
    saveChapter(){
      this.$refs.chapterForm.validate().then(response =>{
        if(response.valid){
          let chapter = {
            chapterName: this.chapterName,
            chapterOrder: this.chapterOrder,
            checklistTypeId: this.selectedType.id,
          }
          if(this.id){
            customAxios.putWithAuth("checklist/chapter/"+ this.id, chapter)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.questionnaireTypeChapters, response.data.id);
                    this.questionnaireTypeChapters.splice(index, 1, response.data);
                    this.chapterName = ''
                    this.chapterOrder=1
                    this.id=''
                    this.chapterDialog=false
                  } else {
                    alert(response.data.message);
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });

          }else {
            customAxios.post("checklist/chapter/", chapter)
                .then((response) => {
                  if (response.status === 201) {
                    this.questionnaireTypeChapters.push(response.data)
                    this.chapterName = ''
                    this.chapterOrder=1
                    this.id=''
                    this.chapterDialog=false
                  } else {
                    alert(response.data.message);
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
          }
        }
      })


    },
    editChapter(item){
      this.id= item.id;
      this.chapterName = item.chapterName;
      this.chapterOrder = item.chapterOrder;
      this.chapterDialog = true
    },
    deleteChapter(item){
      customAxios.delete("checklist/chapter/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.questionnaireTypeChapters, item.id);
              this.questionnaireTypeChapters.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });

    },
    createChapter(){
      this.id = undefined
      this.chapterDialog = true
    },

    closeQuestion(){
      this.questionDialog=false
      this.criteriaBody = ''
      this.optionsCount = 1
      this.chapterId  = undefined
      this.multipleAnswers = false
      this.maxMultipleAnswersCount = 1
    },
    saveQuestion(){
      this.$refs.questionForm.validate().then(response =>{
            if(response.valid){
              let question = {
                criteriaBody: this.criteriaBody,
                optionsCount: this.optionsCount,
                chapterId: this.chapterId,
                multipleAnswers: this.multipleAnswers,
                maxMultipleAnswersCount: this.maxMultipleAnswersCount,
                checklistTypeId: this.selectedType.id
              }
              if(this.id){
                customAxios.putWithAuth("checklist/type/criteria/"+ this.id, question)
                    .then((response) => {
                      if (response.status === 202) {
                        let index = getIndex(this.questionnaireTypeQuestions, response.data.id);
                        this.questionnaireTypeQuestions.splice(index, 1, response.data);
                        this.questionDialog=false
                        this.criteriaBody = ''
                        this.optionsCount = 1
                        this.chapterId  = undefined
                        this.multipleAnswers = false
                        this.maxMultipleAnswersCount = 1
                        this.id=''

                      } else {
                        alert(response.data.message);
                        console.log(response);
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    });

              }else {
                customAxios.post("checklist/type/criteria", question)
                    .then((response) => {
                      if (response.status === 201) {
                        this.questionnaireTypeQuestions.push(response.data)
                        this.questionDialog=false
                        this.criteriaBody = ''
                        this.optionsCount = 1
                        this.chapterId  = undefined
                        this.multipleAnswers = false
                        this.maxMultipleAnswersCount = 1
                        this.id=''
                      } else {
                        alert(response.data.message);
                        console.log(response);
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    });
              }
            }
      })


    },
    createQuestion(){
      this.id = undefined
      this.questionDialog = true
    },
    editQuestion(item){
      this.id= item.id;
      this.checklistTypeId = item.checklistTypeId;
      this.questionOrder = item.questionOrder;
      this.chapterId = item.chapterId;
      this.criteriaBody = item.criteriaBody;
      this.optionsCount = item.optionsCount;
      this.multipleAnswers = item.multipleAnswers;
      this.maxMultipleAnswersCount = item.maxMultipleAnswersCount;
      this.questionDialog = true
    },
    deleteQuestion(item){
      customAxios.delete("checklist/type/criteria/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.questionnaireTypeChapters, item.id);
              this.questionnaireTypeChapters.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });

    },
    questionAnswers(item){
      this.selectedQuestion = item;
      customAxios.getWithAuth("checklist/type/criteria/option/" + item.id)
          .then((response) => {
            if (response.status === 200) {
              this.answers=response.data;
              this.tab=5;
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });


    },

    closeAnswer(){
      this.freeAnswer = false
      this.optionBody=''
      this.id=undefined
      this.criteriaId=undefined
      this.answerDialog=false
    },
    saveAnswer(){
      this.$refs.answerForm.validate().then(response =>{
        if(response.valid){
          let answer = {
            optionBody: this.optionBody,
            freeAnswer: this.freeAnswer,
            criteriaId: this.selectedQuestion.id,
          }
          if(this.id){
            customAxios.putWithAuth("checklist/type/criteria/option/"+ this.id, answer)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.answers, response.data.id);
                    this.answers.splice(index, 1, response.data);
                    this.answerDialog = false
                    this.optionBody=''
                    this.freeAnswer = false
                    this.id=''

                  } else {
                    alert(response.data.message);
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });

          }else {
            customAxios.post("checklist/type/criteria/option", answer)
                .then((response) => {
                  if (response.status === 201) {
                    this.answers.push(response.data)
                    this.answerDialog = false
                    this.optionBody=''
                    this.freeAnswer = false
                    this.id=''
                  } else {
                    alert(response.data.message);
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
          }
        }
      })


    },
    editAnswer(item){
      this.id= item.id;
      this.criteriaId = item.criteriaId;
      this.optionBody = item.optionBody;
      this.freeAnswer = item.freeAnswer;
      this.answerDialog = true
    },
    deleteAnswer(item){
      customAxios.delete("checklist/type/criteria/option/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.answers, item.id);
              this.answers.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });

    },
    createAnswer(){
      this.id = undefined
      this.answerDialog = true
    },
  },
  computed: {
    sortedCretarias() {
      return _.orderBy(this.selectedSubject.cretarias, 'id', 'asc');
    },

    //
    filteredСhecklistSubject() {
      const ss = this.checklistSubjectSearchBody.toLowerCase();

      let tempAnswers = this.checklistSubjects

      return tempAnswers.filter(item =>
          _.some(item, v => {
            if (!(v instanceof Object)) {
              return  _.toLower(v).indexOf(ss)>-1
            }

          }));
    },

    sortedСhecklistSubject() {
      let tempAnswers = this.filteredСhecklistSubject
      return tempAnswers.sort(dynamicSort(this.checklistSubjectSortKey,this.checklistSubjectSortOrder));
    },

    checklistSubjectPageCount(){
      let l = this.sortedСhecklistSubject.length,
          s = parseInt(this.noteCount);
      return Math.ceil(l/s);
    },

    checklistSubjectPaginatedData(){
      const start = (this.currentChecklistSubjectPage -1) * parseInt(this.noteCount),
          end = start + parseInt(this.noteCount);

      return this.sortedСhecklistSubject.slice(start, end);
    },

    currentChecklistSubjectPage (){
      if(this.checklistSubjectPageCount < this.checklistSubjectPage){
        return 1
      } else {
        return this.checklistSubjectPage
      }
    },

    //


    questionsCounter(){
      let questions = []
      for (let i = 0; i < 100; i++) {
        questions[i] =i+1
      }
      return questions
    },

    answersCounter(){
      let answers = []
      for (let i = 0; i < 100; i++) {
        answers[i] =i+1
      }
      return answers
    },
    chaptersCounter(){
      let chapters = []
      for (let i = 0; i < 100; i++) {
        chapters[i] =i+1
      }
      return chapters
    },
  },
  created: function () {
    this.showLoader=true

    function getQuestionnaires() {
      return customAxios.getWithAuth('checklist')
    }

    function getQuestionnaireTypes() {
      return customAxios.getWithAuth('checklist/type')
    }
    
    Promise.all([
      getQuestionnaires(),
      getQuestionnaireTypes()
    ]).then(results => {
      if(results[0].status===200){
        this.questionnaires = results[0].data;
      }
      if(results[1].status===200){
        this.questionnaireTypes = results[1].data;
      }
     
      this.showLoader=false
    }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")
    });
  }
}


</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.card-glam-header {
  background-color: rgba($accent-1, 0.5);
  color: #ffffff;
}
.card-anti-glam-header {
  background-color: #ffffff;
  color: #1d2f61;
}

.card-anti-glam-btn {
  background-color:  #4461c9;
  color: #ffffff;
}

.card-glam-btn {
  background-color:rgba($accent-1, 0.5) ;
  color: #ffffff;
}

.card-anti-glam-header {
  background-color: #ffffff;
  color: #1d2f61;
}


.card-glam-footer {
  background-color: #1d2f61;
  color: #ffffff;
}

.card-glam-answer-unchecked {
  background-color: rgba(143, 166, 232, 0.96);
  color: #ffffff;
}

.card-glam-answer-checked {
  background-color: rgba(252, 134, 149, 0.91);
  color: #ffffff;
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
