// Статичные данные для приложения:
import { t } from "@/utils/translate";

export const tabs = [
  { id: 1, title: `${t("overview")}`, link: "overview" },
  { id: 2, title: `${t("lessons")}`, link: "lessons" },
  { id: 3, title: `${t("instructor")}`, link: "instructor" },
  { id: 4, title: `${t("reviews")}`, link: "reviews" },
];

export const webinarTabs = [
  { id: 1, title: `${t("overview")}`, link: "overview" },
  { id: 2, title: `${t("instructor")}`, link: "instructor" },
];

export const announcementsTabs = [
  { id: 1, title: `${t("news")}`, link: "news" },
  { id: 2, title: `${t("events")}`, link: "events" },
];

export const navigation = [
  { title: t("main"), link: "/" },
  { title: t("library"), link: "/library" },
  { title: t("courses"), link: "/courses" },
  { title: t("bookShelf"), link: "/book_shelf" },
  { title: t("salons"), link: "/salon" },
  { title: t("fast.teacher.help"), link: "/help" },
  //{ title: t("announcements"), link: "/announcements/events" },
  { title: t("questionnaires"), link: "/questionnaires" },

];

export const navigationFooter = [
  { title: t("main"), link: "/" },
  { title: t("library"), link: "/library" },
  { title: t("courses"), link: "/courses" },
  { title: t("salons"), link: "/salon" },
  { title: t("fast.teacher.help"), link: "/help" },
 // { title: t("announcements"), link: "/announcements/events" },
  { title: t("questionnaires"), link: "/questionnaires" },
  { title: t("userAgree"), link: "/useragree" },
  { title: t("politic"), link: "/politic" },
];

export const mobileNavigation = [
  ...navigation,
  { title: t("account"), link: "/user/account" },
  { title: t("userAgree"), link: "/useragree" },
  { title: t("politic"), link: "/politic" },
];

export const programs = [
  {
    id: 1,
    title: "Вальдорфская педагогика",
    img: "lib-1.jpg",
    value: "waldorf",
  },
  { id: 2, title: "Монтессори", img: "lib-2.jpg", value: "montessory" },
  { id: 3, title: "Золотой ключик", img: "lib-3.jpg", value: "goldKey" },
  { id: 4, title: "Петерсон", img: "lib-4.jpg", value: "peterson" },
  {
    id: 5,
    title: "Методика Эльконина-Давыдова",
    img: "lib-5.jpg",
    value: "elkoninDavydov",
  },
  { id: 6, title: "Тьюторы", img: "lib-6.jpg", value: "tutors" },
];

export const filters = [
  {
    id: 1,
    title: "Вальдорфская педагогика",
    value: "waldorf",
    type: "method",
  },
  { id: 2, title: "Монтессори", value: "montessory", type: "method" },
  { id: 3, title: "Золотой ключик", value: "goldKey", type: "method" },
  { id: 4, title: "Петерсон", value: "peterson", type: "method" },
  {
    id: 5,
    title: "Методика Эльконина-Давыдова",
    value: "elkoninDavydov",
    type: "method",
  },
  { id: 6, title: "Тьюторы", value: "tutors", type: "method" },
  { id: 7, title: "Видео", value: "video", type: "content" },
  { id: 8, title: "Плейлист", value: "playlist", type: "content" },
  { id: 9, title: "Статья", value: "article", type: "content" },
];

export const benefits = [
  {
    id: 1,
    title: `Скорая помощь`,
    link: "/help",
    img: "help.png",
  },
  {
    id: 2,
    title: "Библиотека <br/> знаний",
    link: "/library",
    img: "courses.png",
  },
  { id: 3, title: "Каталог <br/> курсов", link: "/courses", img: "market.png" },
  {
    id: 4,
    title: "Родительская <br/> гостиная",
    link: "/salon",
    img: "mom_d.png",
  },
];

export const userSettingsList = [
  { id: 1, title: t("change.bio"), link: "/user/settings/name" },
  { id: 2, title: t("change.email"), link: "/user/settings/email" },
  { id: 3, title: t("change.password"), link: "/user/settings/password" },
  { id: 4, title: t("change.avatar"), link: "/user/settings/avatar" },
];

export const adminTabs = [
  { id: 1, title: `Курсы и вебинары`, link: "ololo" },
  // { id: 2, title: `Клиенты`, link: "clients" },
  { id: 3, title: `Пользователи`, link: "users" },
  { id: 4, title: `Книжная полка`, link: "book_shelf" },
  { id: 5, title: `Библиотека`, link: "library" },
  { id: 6, title: `Скорая помощь`, link: "faq" },
  { id: 7, title: `Новости и события`, link: "news" },
  { id: 8, title: `Опросы`, link: "questionnaire" },
  { id: 9, title: `Оповещения`, link: "notifications" },
  { id: 10, title: `Облачные опросы`, link: "mental" },
];

export const adminQuestionnaireTabs = [
  { id: 1, title: `Опросы`, link: "questionnaires" },
  { id: 2, title: `Типы опросов`, link: "types" },
];

export const salonFilters = [
  {
    id: 0,
    active: true,
    name: "Опубликован",
    value: "1",
    parametrName: "published",
  },
  {
    id: 1,
    active: true,
    name: "Не опубликован",
    value: "0",
    parametrName: "published",
  },
  {
    id: 2,
    active: true,
    name: "Вебинар",
    value: "0",
    parametrName: "salonType",
  },
  {
    id: 3,
    active: true,
    name: "Ссылка на внешний ресурс",
    value: "1",
    parametrName: "salonType",
  },
];

export const publicSalonFilters = [
  {
    id: 2,
    active: true,
    name: "Вебинар",
    value: "0",
    parametrName: "salonType",
  },
  {
    id: 3,
    active: true,
    name: "Ссылка на внешний ресурс",
    value: "1",
    parametrName: "salonType",
  },
];

export const panelUserLinks = [
  { id: 2, title: t("courses"), link: "/user/courses", icon: "academic-icon" },
  { id: 3, title: t("favorites"), link: "/user/favorites", icon: "like-icon" },
  {
    id: 4,
    title: t("settings"),
    link: "/user/settings",
    icon: "settings-icon",
  },
  {
    id: 5,
    title: t("questionnaires"),
    link: "/user/questionnaires",
    icon: "list-icon",
  },
  { id: 6, title: t("salons"), link: "/user/salon", icon: "building-icon" },
];

export const panelAdminLinks = [
  { id: 1, title: t("admin.panel"), link: "/admin", icon: "calendar-icon" },
];

export const promoSectionPhotos = {
  bigPhotos: ["promo-1-wave.png", "promo-2-wave.png", "promo-3-wave.png"],

  middlePhotos: [
    "promo-1200-1-wave.png",
    "promo-1200-2-wave.png",
    "promo-1200-3-wave.png",
  ],

  smallPhotos: [
    "promo-768-1-wave.png",
    "promo-768-2-wave.png",
    "promo-768-3-wave.png",
  ],
};
