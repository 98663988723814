<template>
  <svg class="app-icon">
    <use :xlink:href="`#${icon}`" />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    }
  },
};
</script>

<style lang="scss">
.app-icon {
  stroke: $text;
  flex-shrink: 0;
  stroke-width: 1.5px;
  width: 24px;
  height: 24px;
}
</style>