<template>
  <NModal class="document-modal" @closeModal="$emit('closeModal')">
    <slot></slot>
    <div class="wrapper document-modal__wraper">
      <ColoredButton @click="$emit('agreed')" class="document-modal__button">
        <span>{{ t("i.am.agree") }}</span>
      </ColoredButton>
    </div>
  </NModal>
</template>

<script>
import { t } from "@/utils/translate";
import NModal from "./NModal.vue";
import ColoredButton from "@/elements/ColoredButton.vue";

export default {
  emits: ["agreed", "closeModal"],
  setup() {
    return { t };
  },
  components: { NModal, ColoredButton },
};
</script>

<style lang="scss">
.document-modal {
  overflow: scroll;
  background-color: #fff;

  .app-back-button.n-modal__close {
    color: $text;

    svg {
      stroke: $text;
    }
  }

  .n-modal__content {
    padding: 0;
  }

  &__button {
    margin: 0 auto;
  }

  &__wraper.wrapper {
    padding-top: 0;
  }
}
</style>
