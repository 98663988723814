<template>
  <UserContentWrapper :title="t('favorites')">
    <div class="user-favorites">
      <AppBackButton
        :linkMode="true"
        link="/user/panel"
      />

      <StateWrapper
        :content="favorites"
        :loadingStatus="loadingStatus"
        :checkLogin="true"
      >
        <div class="user-favorites__content">
          <CoursePreview
            v-for="v in favorites"
            :key="v.id"
            :element="v"
            :inFavorite="true"
            @toggleFavoriteStatus="toggleStatus(v)"
          />
        </div>
      </StateWrapper>

      <router-link
        to="/courses"
        class="user-favorites__go"
        v-if="!favorites.length"
      >
        {{ t("to.courses.list") }}
        <AppIcon icon="arrow-icon" />
      </router-link>
    </div>
  </UserContentWrapper>
</template>

<script>
import { t } from "@/utils/translate";
import { onMounted } from "vue";

import CoursePreview from "@/components/CoursePreview.vue";
import UserContentWrapper from "@/elements/UserContentWrapper.vue";
import AppBackButton from "@/elements/AppBackButton.vue";
import StateWrapper from "./StateWrapper.vue";

import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

import { useCourseFavorite } from "@/composables/useCourseFavorite";
import AppIcon from "@/elements/AppIcon.vue";
import { customAxios } from "@/service/customAxios";
import { ref } from "vue";

export default {
  components: {
    AppBackButton,
    CoursePreview,
    UserContentWrapper,
    AppIcon,
    StateWrapper,
  },

  setup() {
    const store = useUserStore();
    const { favorites } = storeToRefs(store);
    const { setUserFavorites } = store;
    const { toggleFavoriteStatus } = useCourseFavorite();
    const loadingStatus = ref("loading");

    async function getUserFavorites() {
      try {
        const r = await customAxios.getWithAuth("course/favorite");
        console.log(r.data);
        setUserFavorites(r.data);
        loadingStatus.value = "loaded";
      } catch (e) {
        console.log(e);
        loadingStatus.value = "error";
      }
    }

    async function toggleStatus(v) {
      try {
        await toggleFavoriteStatus(v);
        await getUserFavorites();
      } catch (e) {
        console.log(e);
      }
    }

    onMounted(async () => {
      await getUserFavorites();
    });

    return {
      loadingStatus,
      toggleStatus,
      favorites,
      t,
    };
  },
};
</script>

<style lang="scss">
.user-favorites {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }

  &__go {
    @include button-default;
    text-decoration: none;
    padding: 8px 16px;
    margin-top: 30px;
    @include flex-center;

    svg {
      stroke: #fff;
    }
  }

  @media (max-width: 640px) {
    &__content {
      grid-template-columns: 1fr;
    }
  }
}
</style>
