<template>
  <UserContentWrapper
    :title="t('questionnaires')"
    @back="$emit('back')"
    class="user-questionnaires"
  >
    <div class="n-user-favorites">
      <AppBackButton :linkMode="true" link="/user/panel" />
    </div>
    <AppTooltip>
      <p>
        Здесь отображаются анкетирования, доступные Вам. Если вы начинали
        заполнять анкету, можно нажать на кнопку Черновик.
      </p>
      <p>
        После того, как вы <strong>cохраните</strong> результаты - черновик
        анкеты будет недоступен.
      </p>
    </AppTooltip>

    <div v-if="checklists.length">
      <div
        v-for="checklist in checklists"
        :key="checklist.id"
        class="user-questionnaires__template"
      >
        <router-link
          :to="`/questionnaire/${checklist.id}`"
          class="flex flex__sb"
        >
          <span>{{ t("questionnaire") }}: {{ checklist.name }}</span>
          <span v-if="currentUuids.includes(checklist.id)">
            {{ t("draft") }}</span
          >
          <span v-else>{{ t("questionnaire.begin") }}</span>
        </router-link>
      </div>
    </div>
  </UserContentWrapper>
</template>

<script>
import UserContentWrapper from "@/elements/UserContentWrapper.vue";
import AppBackButton from "@/elements/AppBackButton.vue";
import AppTooltip from "./AppTooltip.vue";

import { t } from "@/utils/translate";

import { useQuestionnareStore } from "@/store/questionnareStore";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { ref } from "vue";

export default {
  components: { UserContentWrapper, AppBackButton, AppTooltip },
  setup() {
    const questionsStore = useQuestionnareStore();
    const { currentUuids } = storeToRefs(questionsStore);

    const questionnareStore = useQuestionnareStore();
    const { getQuestionnare } = questionnareStore;

    const checklists = ref([]);

    async function getExistedQuestionnare() {
      try {
        currentUuids.value.forEach(async (v) => {
          const r = await getQuestionnare(v.questionnareId, v.uuid);
          checklists.value.push(r.data.checklist);
        });
      } catch (e) {
        console.log(e);
      }
    }

    onMounted(async () => {
      console.log(currentUuids.value);
      if (currentUuids.value.length) {
       
        await getExistedQuestionnare();
      }
    });

    return { t, checklists, currentUuids };
  },
};
</script>

<style lang="scss">
.user-questionnaires {
  &__template {
    @include card;
    padding: 0;

    & + .user-questionnaires__template {
      margin-top: 16px;
    }

    a {
      @include link-default;
      line-height: 30px;
      padding: 16px;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;
    }

    span:last-child {
      background-color: rgba($text, 0.1);
      padding: 8px;
      border-radius: 4px;
      align-items: center;
      transition: all 0.2s;
    }

    &:hover {
      background-color: $accent-1;

      a,
      span {
        color: #fff;
      }

      span {
        background-color: transparent;
      }
    }
  }

  @media (max-width: 480px) {
    &__template {
      a {
        flex-direction: column;
      }

      span:first-child {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
