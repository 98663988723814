<template>
  <UserContentWrapper :title="t('change.avatar')">
    <div class="user-avatar-settings">
      <AppBackButton
        :linkMode="true"
        link="/user/settings"
        class="user-avatar-settings__back"
      />

      <div class="user-avatar-settings__btns">
        <AppButton
          @click="() => (showCropper = true)"
          class="user-avatar-settings__select-photo flex flex__ai-c"
        >
          <span>Выбрать новое фото</span>
          <AppIcon icon="plus-icon" />
        </AppButton>
        <AppButton
          @click="save"
          class="user-avatar-settings__save"
          :disabled="nothingToSave"
          >{{ t("save") }}
        </AppButton>
      </div>

      <div class="user-avatar-settings__preview">
        <NAvatar :image="personAvatar" v-if="!image" />
        <AppImage v-else :image="image" class="user-avatar-settings__photo" />
      </div>

      <AvatarCropper
        v-model="showCropper"
        :uploadHandler="uploadHandler"
        @error="handlerError"
      />
    </div>
  </UserContentWrapper>
</template>

<script>
import UserContentWrapper from "@/elements/UserContentWrapper.vue";
import AppBackButton from "@/elements/AppBackButton.vue";
import AvatarCropper from "vue-avatar-cropper";

import { t } from "@/utils/translate";
import { ref } from "vue";

import AppButton from "@/elements/AppButton.vue";
import AppIcon from "@/elements/AppIcon.vue";
import FormData from "form-data";

import { usePopupStore } from "@/store/popupStore";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

import NAvatar from "@/elements/NAvatar.vue";
import AppImage from "@/elements/AppImage.vue";

export default {
  emits: ["changeAvatar"],
  components: {
    AppBackButton,
    UserContentWrapper,
    AvatarCropper,
    AppButton,
    AppIcon,
    NAvatar,
    AppImage,
  },
  setup(_, ctx) {
    let canvas;

    const image = ref("");
    const showCropper = ref(false);
    const nothingToSave = ref(true);
    const message = ref("ready");

    const userStore = useUserStore();
    const { showPopup } = usePopupStore();
    const { personAvatar } = storeToRefs(userStore);

    function handlerError() {
      showPopup(t("something.went.wrong"), "error");
    }

    function uploadHandler(cropper) {
      nothingToSave.value = false;
      image.value = cropper.getCroppedCanvas().toDataURL("image/jpeg");
      canvas = cropper.getCroppedCanvas();
    }

    function save() {
      canvas.toBlob(async (blob) => {
        const bodyFormData = new FormData();
        bodyFormData.append("file", blob);

        ctx.emit("changeAvatar", bodyFormData);
      });
    }

    return {
      uploadHandler,
      handlerError,
      t,
      personAvatar,
      showCropper,
      message,
      nothingToSave,
      save,
      image,
    };
  },
};
</script>

<style lang="scss">
.user-avatar-settings {
  &__btns {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }

  &__save {
    @include button-default;
    margin-left: 16px;
  }

  &__select-photo {
    @include button-default;

    svg {
      stroke: #fff;
      stroke-width: 3px;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__photo {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    img {
      object-fit: cover;
    }
  }

  &__preview {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 150px;
    height: 150px;
    border: 2px solid $accent-1;
    margin: 0 auto;

    .n-avatar {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    &__btns {
      display: block;

      button {
        margin: 0 auto;
      }

      button + button {
        margin-top: 20px;
      }
    }
  }
}

.avatar-cropper-overlay {
  background-color: rgba($text, 0.9);
}
</style>
