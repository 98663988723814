import { defineStore } from 'pinia'

export const usePopupStore = defineStore('popup', {
    state: () => ({
        isOpen: false,
        msg: '',
        status: ''
    }),
    actions: {
        showPopup(msg, status) {
            this.isOpen = true
            this.msg = msg
            this.status = status
        },
        closePopup() {
            this.isOpen = false
            this.msg = ''
            this.status = ''
        }
    }
})