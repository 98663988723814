<template>
  <div class="p-home">
    <NPromo ref="promoRef" class="p-home__promo" />

    <section class="wrapper">
      <SlidesWithText
        :slidesText="questionsAnswer.questions"
        ref="AnimationedTextRef"
        :bottomText="questionsAnswer.answer"
      />
    </section>

    <section class="wrapper" v-if="whatUserCanDo">
      <TitledList
        :list="whatUserCanDo.answers"
        icon="check-icon"
        :title="whatUserCanDo.question"
        ref="listRef"
      />
    </section>

    <section class="wrapper">
      <AppTitle mode="h3" :ref="setTitleRef" class="p-home__title">
        <template v-slot:top>
          {{ t("start") }}
        </template>
        <template v-slot:title>{{ t("our.modules") }}</template>
      </AppTitle>

      <NBenefits
        v-if="benefits.length"
        ref="benefitsRef"
        :benefits="benefits"
      />
    </section>

    <section class="wrapper">
      <AppTitle mode="h3" :ref="setTitleRef" class="p-home__title">
        <template v-slot:top>
          {{ t("learn") }}
        </template>
        <template v-slot:title>{{ t("our.programs") }}</template>
      </AppTitle>
      <NPrograms :list="programs" />
    </section>

    <section class="wrapper">
      <TelegramBot />
    </section>

    <section class="wrapper">
      <FreeEntry />
    </section>
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { ref } from "vue";
import { useObserver } from "@/composables/useObserver";

import AppTitle from "@/elements/AppTitle";
import TitledList from "@/elements/TitledList.vue";
import NBenefits from "@/components/NBenefits.vue";

import NPromo from "@/elements/NPromo.vue";
import NPrograms from "@/components/NPrograms.vue";
import SlidesWithText from "@/elements/SlidesWithText.vue";
import TelegramBot from "@/components/TelegramBot.vue";

import {
  questionsAnswer,
  whatUserCanDo,
  companyInformation,
} from "@/app-data/texts";
import { programs, benefits } from "@/app-data/static";
import FreeEntry from "@/components/FreeEntry.vue";

export default {
  components: {
    AppTitle,
    TitledList,
    NBenefits,
    NPrograms,
    NPromo,
    SlidesWithText,
    TelegramBot,
    FreeEntry
},

  setup() {
    const promoRef = ref({});
    const AnimationedTextRef = ref({});
    const listRef = ref({});
    const titlesRef = ref([]);
    const benefitsRef = ref();
    const programPreviews = ref([]);

    function setTitleRef(el) {
      titlesRef.value.push(el);
    }

    useObserver(promoRef, (el) => {
      el.classList.add("animated");
    });

    useObserver(
      programPreviews,
      (el) => {
        el.classList.add("animated");
      },
      { threshold: 0.5 }
    );

    useObserver(AnimationedTextRef, (el) => {
      el.classList.add("animated");
    });

    useObserver(listRef, (el) => {
      el.classList.add("animated");
    });

    useObserver(
      titlesRef,
      (el) => {
        el.classList.add("animated");
      },
      { threshold: 0.8 }
    );

    useObserver(benefitsRef, (el) => {
      el.classList.add("animated");
    });

    return {
      benefits,
      benefitsRef,
      questionsAnswer,
      whatUserCanDo,
      programs,
      companyInformation,
      t,
      promoRef,
      AnimationedTextRef,
      listRef,
      setTitleRef,
    };
  },
};
</script>

<style lang="scss">
.p-home {
  padding-bottom: 40px;

  &__promo {
    margin-bottom: 100px;
  }

  &__title {
    align-items: center;
    margin-bottom: 60px;

    .app-title__title {
      opacity: 0;
      transform: translate3d(20%, 0, 0);
    }

    .app-title__top {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  }

  section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 768px) {
    &__promo {
      flex-direction: column;
      margin-bottom: 20px;
    }

    section {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

.animated.p-home__title {
  .app-title__top,
  .app-title__title {
    animation: slide-fade-in 0.3s ease-in 0s 1 forwards;
  }

  .app-title__title {
    animation-delay: 0.2s;
  }
}
</style>
