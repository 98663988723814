<template>
  <div class="n-pop-up" :class="status">
    <div class="overlay" v-if="status !== 'tooltip'"></div>
    <div class="n-pop-up__content" v-if="status !== 'tooltip'">
      <div class="n-pop-up__status">
        <p v-if="status === 'error'">{{ t("error") }}</p>
        <p v-if="status === 'success'">{{ t("success") }}</p>
        <p v-if="status === 'warning'">{{ t("warning") }}</p>
      </div>

     
        <AppTitle mode="h4" class="n-pop-up__msg">
          <template v-slot:title>{{ msg }}</template>
        </AppTitle>

        <ColoredButton @click="$emit('popupClose')">
          <span>{{ t("ok") }}</span>
        </ColoredButton>
  
    </div>

    <div
      class="n-pop-up__content n-pop-up__content--tooltip"
      v-if="status === 'tooltip'"
    >
      <div class="n-pop-up__text">
        <AppTitle mode="h4" class="n-pop-up__msg">
          <template v-slot:title>{{ msg }}</template>
        </AppTitle>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/elements/AppTitle.vue";
import ColoredButton from "@/elements/ColoredButton.vue";

import { t } from "@/utils/translate";

export default {
  components: { AppTitle, ColoredButton },
  emits: ["popupClose"],
  props: {
    msg: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    if (props.status === "tooltip") {
      setTimeout(() => {
        ctx.emit("popupClose");
      }, 1000);
    }

    return { t };
  },
};
</script>

<style lang="scss">
.n-pop-up {
  position: fixed;
  z-index: 1000;

  @include full-bg;
  @include flex-center;

  &__status {
    font-weight: 700;
    padding: 8px 16px;
    border-bottom: 2px solid #000;
  }

  &__msg {
    padding: 30px 16px;
  }

  &__content {
    z-index: 101;
    background-color: #fff;
    border-radius: 8px;
    width: 320px;
    text-align: center;
    padding-bottom: 16px;
    button {
      margin: 0 auto;
    }
  }

  &.success {
    .n-pop-up__content {
      border: 2px solid $success;
    }

    .n-pop-up__status {
      color: $success;
      border-color: $success;
    }
  }

  &.error {
    .n-pop-up__content {
      border: 2px solid $error;
    }

    .n-pop-up__status {
      color: $error;
      border-color: $error;
    }
  }

  &.warning {
    .n-pop-up__content {
      border: 2px solid $accent-2;
    }

    .n-pop-up__status {
      color: $accent-2;
      border-color: $accent-2;
    }
  }

  &__content--tooltip {
    transform: translate3d(10%, 0, 0);
    opacity: 0;
  }

  &.tooltip {
    .n-pop-up__content {
      position: fixed;
      top: 10%;
      right: 10%;
      @include card;
      padding: 8px;
    }

    .n-pop-up__msg {
      margin-bottom: 0;
    }

    .n-pop-up__content--tooltip {
      animation: slide-fade-in 0.2s cubic-bezier(0.32, 0, 0.67, 0) 1 forwards;
    }
  }
}
</style>
