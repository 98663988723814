<template>
  <div class="n-tabs">
    <AppButton
      class="n-tabs__menu-opener"
      :class="{ 'mobile-menu-show': menuIsShowed }"
      @click="$emit('toggleMenu')"
    >
      <span>{{ t("menu") }}</span>
      <AppIcon icon="chevron-icon" />
    </AppButton>

    <div class="n-tabs__tabs" :class="{ 'mobile-menu-show': menuIsShowed }">
      <router-link
        v-for="(v, idx) in tabs"
        :to="startUrl ? `${startUrl}/${v.link}` : '/'"
        :key="idx"
        class="n-tabs__tab"
        :class="{ active: $route.path.includes(v.link) }"
        @click="$emit('closeMenu')"
      >
        <span>{{ v.title }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import AppIcon from "@/elements/AppIcon.vue";
import AppButton from "@/elements/AppButton.vue";
import { t } from "@/utils/translate";

export default {
  emits: ["closeMenu", "toggleMenu"],
  components: { AppButton, AppIcon },
  props: {
    menuIsShowed: {
      type: Boolean,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    startUrl: {
      type: String,
      required: false,
    },
  },
  setup() {
    return { t };
  },
};
</script>

<style lang="scss">
.n-tabs {
  &__tabs {
    display: flex;
    border-bottom: 2px solid $accent-1;
    overflow: auto;
  }

  &__menu-opener {
    display: none;
  }

  &__tab {
    flex-shrink: 0;
    white-space: nowrap;
    @include link-default;
    transition: all 0.2s;
    color: $accent-1;
    background-color: none;
    padding: 8px 32px;
    @include flex-center;
    text-align: center;

    &.active {
      background-color: $accent-1;
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    &__menu-opener {
      width: 100%;
      line-height: 50px;
      padding: 8px;
      @include flex-center;
      border: 1px solid $accent-1;

      font-weight: 700;
      border-radius: 8px;

      svg {
        margin-left: 4px;
        transform: rotate(-90deg);
        transition: all 0.2s;
      }

      &.mobile-menu-show {
        border-radius: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        svg {
          transform: rotate(90deg);
        }
      }
    }

    &__tabs {
      grid-template-columns: 1fr;
      grid-gap: 0;
      border-bottom: none;
      display: none;
      opacity: 0;
      transform: translate3d(0, -10%, 0);
      animation: slide-fade-in 0.2s cubic-bezier(0.32, 0, 0.67, 0) 1 forwards;

      &.mobile-menu-show {
        display: grid;
      }
    }

    &__tab {
      border: 1px solid $accent-1;
      margin: 0;
      border-radius: 0;
      border-top: none;

      &:hover {
        background-color: rgba($accent-1, 0.5);
        color: #fff;
      }

      &.active {
        border-radius: 0;

        &:hover {
          background-color: $accent-1;
        }
      }

      &:last-child {
        border-bottom: 1px solid $accent-1;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}
</style>
