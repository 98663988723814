<template>
  <div class="admin-courses">
    <AccordionItem
      v-for="(v, idx) in items"
      :key="v.id"
      :isOpen="currentId === v.id"
      @open="setCurrentId"
      :style="{ 'animation-delay': `0.${idx}s` }"
      :id="v.id"
      class="admin-courses__item"
    >
      <template v-slot:top>
        <AppTitle mode="h4">
          <template v-slot:title>{{ v.title }}</template>
        </AppTitle>
      </template>
      <template v-slot:bottom>
        <div>
          <div
            v-for="l in v.lessons"
            :key="l.id"
            class="flex flex__ai-c admin-courses__lesson"
          >
            <p>{{ l.title }}</p>
            <div>{{ getStringDateTime(l.date) }}</div>
          </div>
        </div>

        <div class="admin-courses__btns">
          <router-link
            to="/admin/courses/course/1/shedule"
            class="admin-courses__btn"
          >
            Редактировать
          </router-link>
        </div>
      </template>
    </AccordionItem>
  </div>
</template>

<script>
import { ref } from "vue";

import AccordionItem from "@/components/AccordionItem.vue";
import AppTitle from "@/elements/AppTitle.vue";

import { setCurrentIdValue, getStringDateTime } from "@/utils/common";

const items = [
  {
    id: 1,
    title: "Вебинар 1",
    lessons: [
      { id: 1, title: "Урок 1", date: "2023-13-04T13:00:00" },
      { id: 2, title: "Урок 2", date: "2023-14-04T13:00:00" },
      { id: 3, title: "Урок 3", date: "2023-15-04T13:00:00" },
    ],
  },
  {
    id: 2,
    title: "Курс 123",
    lessons: [
      { id: 1, title: "Урок 1", date: "2023-13-04T13:00:00" },
      { id: 2, title: "Урок 2", date: "2023-14-04T13:00:00" },
      { id: 3, title: "Урок 3", date: "2023-15-04T13:00:00" },
    ],
  },
];
export default {
  components: { AccordionItem, AppTitle },

  setup() {
    const currentId = ref(null);

    function setCurrentId(value) {
      currentId.value = setCurrentIdValue(value, currentId.value);
    }

    return { items, setCurrentId, currentId, getStringDateTime };
  },
};
</script>

<style lang="scss">
.admin-courses {
  @include page;

  &__item + .admin-courses__item {
    margin-top: 16px;
  }

  &__top {
    margin-bottom: 40px;
    color: $accent-1;
  }

  &__btn {
    @include link-button;
    border: 2px solid $accent-1;
    padding: 8px;

    &:hover {
      border-color: $accent-2;
    }
  }

  &__btns {
    margin-top: 32px;
  }

  &__lesson {
    padding: 16px;
    justify-content: space-between;
    background-color: rgba($accent-1, 0.1);
    font-size: 18px;
    line-height: 22px;

    &:nth-child(2n) {
      background-color: rgba($accent-1, 0.3);
    }
  }
}
</style>
