<template>
  <div class="n-program-overview">
    <div v-if="description">
      <AppTitle mode="h3">
        <template v-slot:top>{{ t("more") }}</template>
        <template v-slot:title>{{ t("about.course") }}</template>
      </AppTitle>
      <div class="n-program-overview__content">
        <p>{{ description }}</p>
      </div>
      <CourseSummary
        v-if="user"
        @joinToCourse="$emit('joinToCourse')"
        @leaveCourse="$emit('leaveCourse')"
        @addToFavorites="$emit('addToFavorites')"
        @removeFromFavorites="$emit('removeFromFavorites')"
      />
    </div>
    <LoaderRing v-else />
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { computed } from "vue";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

import CourseSummary from "@/elements/CourseSummary.vue";
import AppTitle from "@/elements/AppTitle.vue";
import LoaderRing from "@/elements/LoaderRing.vue";

export default {
  components: { AppTitle, LoaderRing, CourseSummary },
  emits: [
    "joinToCourse",
    "leaveCourse",
    "addToFavorites",
    "removeFromFavorites",
  ],
  setup() {
    const userStore = useUserStore();
    const { currentCourse, user } = storeToRefs(userStore);
    const description = computed(() => currentCourse.value.description);

    return {
      t,
      description,
      user,
    };
  },
};
</script>

<style lang="scss">
.n-program-overview {
  @include page;

  &__content {
    margin: 40px 0;
    @include card;

    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
