<template>
  <router-link to="/auth/login" class="free-entry">
    <p>Регистрация на сайте полностью БЕСПЛАТНА</p>
    <span>Присоединяйтесь</span>
  </router-link>
</template>

<script>
export default {
  setup() {
    return {};
  }
};
</script>

<style lang="scss">
.free-entry {
    display: block;
    border: 2px dashed $accent-1;
    border-radius: 8px;
    background-color: rgba($accent-1, 0.1);
    padding: 16px 8px;
    text-decoration: none;
    text-align: center;
    color: $accent-1;
    transition: all 0.2s;

    p {
       
        margin-bottom: 16px;
    }

    p, span {
        display: block;
    }

    span {
        font-weight: 700;
    }

    &:hover {
        transform: scale(1.02);
    }
}
</style>