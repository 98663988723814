<template>
  <AuthForm :title="t('restore.password')">
    <template v-slot:inputs>
      <AppBackButton @click="$emit('change-form', 'login')" />
      <AppInput
        v-model="vr$.email.$model"
        :label="t('email')"
        id="f-email"
        :class="setValidationStatusClass(vr$.email)"
        :element="vr$.email"
        @blur="vr$.email.$touch"
      />

      <AppCheckbox
        id="approveRestorePass"
        label="Отмечая это поле, я даю согласие на сброс пароля"
        value="approvedPassRestore"
        @toggle-check="userApprovedPassRestore = !userApprovedPassRestore"
        class="n-restore-password__checkbox"
      />
    </template>
    <template v-slot:buttons>
      <ColoredButton @click="$emit('send-email', rEmail)">
        <span>{{ t("restore") }}</span>
      </ColoredButton>
    </template>
  </AuthForm>
</template>

<script>
import { ref } from "vue";

import AppInput from "@/elements/AppInput";
import ColoredButton from "@/elements/ColoredButton.vue";

import AppBackButton from "@/elements/AppBackButton.vue";
import AppCheckbox from "@/elements/AppCheckbox.vue";
import AuthForm from "./AuthForm.vue";

import { t } from "@/utils/translate";

import { useVuelidate } from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
import { setValidationStatusClass } from "@/utils/validation";

export default {
  components: {
    AppInput,
    AppBackButton,
    AppCheckbox,
    AuthForm,
    ColoredButton,
  },
  emits: ["send-email", "change-form"],
  setup() {
    const rEmail = ref("");
    const userApprovedPassRestore = ref(false);

    const fRules = {
      userApproved: { sameAs: sameAs(true) },
      email: { required, email },
    };

    const vr$ = useVuelidate(fRules, {
      email: rEmail,
      userApproved: userApprovedPassRestore,
    });

    return {
      t,
      vr$,
      rEmail,
      setValidationStatusClass,
    };
  },
};
</script>

<style lang="scss">
.n-restore-password {
  &__title {
    margin-bottom: 30px;
  }

  &__checkbox {
    margin: 30px 0;

    & label::before {
      border: 1px solid $text;
    }
  }
}
</style>
