<template>
  <DocumentWrapper :title="t('userAgree')">
    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      1. Общие положения
    </h4>

    <ul class="text-justify">
      <li class="list-unstyled">
        Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует
        отношения между АНО «Институт проблем образовательной политики Эврика»
        (Администрация) с одной стороны и пользователем сайта с другой. Сайт
        Конференция не является средством массовой информации. Используя сайт,
        Вы соглашаетесь с условиями данного соглашения. Если Вы не согласны с
        условиями данного соглашения, не используйте сайт!
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      2. Предмет соглашения
    </h4>

    <ul class="text-justify">
      <li class="list-unstyled">
        Администрация предоставляет пользователю право на размещение и получение
        на сайте текстовой информации, информации и прохождении опроса в виде
        ответов на вопросы.
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      3. Права и обязанности сторон
    </h4>

    <span class="subheading text-center">Пользователь имеет право:</span>
    <ul class="text-justify">
      <li class="list-unstyled">Осуществлять поиск информации на сайте;</li>
      <li class="list-unstyled">Получать информацию на сайте;</li>
      <li class="list-unstyled">Создавать информацию для сайта;</li>
      <li class="list-unstyled">
        Использовать информацию сайта в личных некоммерческих целях.
      </li>
    </ul>

    <span class="subheading text-center">Администрация имеет право:</span>
    <ul class="text-justify">
      <li class="list-unstyled">
        По своему усмотрению и необходимости создавать, изменять, отменять
        правила;
      </li>
      <li class="list-unstyled">
        Ограничивать доступ к любой информации на сайте;
      </li>
      <li class="list-unstyled">Создавать, изменять, удалять информацию;</li>
      <li class="list-unstyled">Удалять учетные записи;</li>
      <li class="list-unstyled">
        Отказывать в регистрации без объяснения причин.
      </li>
    </ul>

    <span class="subheading text-center">Пользователь обязуется:</span>
    <ul class="text-justify">
      <li class="list-unstyled">
        Обеспечить достоверность предоставляемой информации;
      </li>
      <li class="list-unstyled">
        Обеспечивать сохранность личных данных от доступа третьих лиц;
      </li>
      <li class="list-unstyled">
        Обновлять Персональные данные, предоставленные при регистрации, в случае
        их изменения;
      </li>
      <li class="list-unstyled">
        Не распространять информацию, направленную на пропаганду войны,
        разжигание национальной, расовой или религиозной ненависти и вражды, а
        также иной информации, за распространение которой предусмотрена
        уголовная или административная ответственность;
      </li>
      <li class="list-unstyled">Не нарушать работоспособность сайта;</li>
      <li class="list-unstyled">
        Не создавать несколько учётных записей на Сайте, если фактически они
        принадлежат одному и тому же лицу;
      </li>
      <li class="list-unstyled">
        Не передавать в пользование свою учетную запись и/или логин и пароль
        своей учетной записи третьим лицам;
      </li>
      <li class="list-unstyled">
        Не регистрировать учетную запись от имени или вместо другого лица за
        исключением случаев, предусмотренных законодательством РФ;
      </li>
      <li class="list-unstyled">
        Не размещать материалы рекламного, эротического, порнографического или
        оскорбительного характера, а также иную информацию, размещение которой
        запрещено или противоречит нормам действующего законодательства РФ;
      </li>
      <li class="list-unstyled">
        Не использовать скрипты (программы) для автоматизированного сбора
        информации и/или взаимодействия с Сайтом и его Сервисами.
      </li>
    </ul>

    <span class="subheading text-center">Администрация обязуется:</span>
    <ul class="text-justify">
      <li class="list-unstyled">
        - Поддерживать работоспособность сайта за исключением случаев, когда это
        невозможно по независящим от Администрации причинам.
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      4. Ответственность сторон
    </h4>
    <ul class="text-justify">
      <li class="list-unstyled">
        Пользователь лично несет полную ответственность за распространяемую им
        информацию;
      </li>
      <li class="list-unstyled">
        Администрация не несет никакой ответственности за услуги,
        предоставляемые третьими лицами;
      </li>
      <li class="list-unstyled">
        В случае возникновения форс-мажорной ситуации (боевые действия,
        чрезвычайное положение, стихийное бедствие и т. д.) Администрация не
        гарантирует сохранность информации, размещённой Пользователем, а также
        бесперебойную работу информационного ресурса.
      </li>
    </ul>

    <h4 class="h4 card-subtitle text-center mb-2 text-muted sub-header">
      5. Условия действия Соглашения
    </h4>
    <ul class="text-justify">
      <li class="list-unstyled">
        Данное Соглашение вступает в силу при любом использовании данного сайта.
      </li>
      <li class="list-unstyled">
        Соглашение перестает действовать при появлении его новой версии.
      </li>
      <li class="list-unstyled">
        Администрация оставляет за собой право в одностороннем порядке изменять
        данное соглашение по своему усмотрению.
      </li>
      <li class="list-unstyled">
        Администрация не оповещает пользователей об изменении в Соглашении.
      </li>
    </ul>
  </DocumentWrapper>
</template>

<script>
import { t } from "@/utils/translate";

import DocumentWrapper from "@/components/DocumentWrapper.vue";

export default {
  components: {
    DocumentWrapper,
  },
  setup() {
    return {
      t,
    };
  },
};
</script>
