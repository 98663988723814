<template>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab value="1" >Новости</v-tab>
      <v-tab value="2" >События</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item value="1" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Новости</h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="newsSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createNews">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="newsHeaders"
                  :items="news"
                  alternating
                  show-index
                  :search-value="newsSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-active="{published}">
                  <p v-if="published">Да</p>
                  <p v-else>нет</p>
                </template>

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-newsAuthor="{newsAuthor}">
                  <p></p>
                  <p></p>
                  {{ newsAuthor.firstname}}  {{ newsAuthor.lastname}}
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editNews(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteNews(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="2" class="align-self-stretch">
        <v-card v-if="salonDialog">
          <v-card-title class="card_ligth_header">
            <h5 v-if="!!this.id">Редактирование события</h5>
            <h5 v-else>Добавление события</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="salonForm">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Заголовок*"
                        v-model="salonName"
                        :rules="titleRules"
                        :counter="250"
                        variant="outlined"
                        hint="Укажите Вопрос"
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pb-16" style="height: 350px;">
                    <quill-editor
                        style="height: 200px;"
                        v-model:value="salonAnnonce"
                        :options=" {
                        modules: {
                        imageDrop: true,
                        imageResize: true,
                          htmlEditButton: {
                          debug: true,
                          syntax: false,
                          buttonTitle: 'Показать HTML код',
                          msg: 'Отредактируйте HTML  в этом окне,  для сохранения нажмите - сохранить',
                          okText: 'Сохранить',
                          cancelText: 'Отмена',
                          buttonHTML: '&lt;&gt;',
                          prependSelector: 'div#myelement',
                          editorModules: {}

                },
                        imageCompress: {
                         quality: 0.7, // default
                         maxWidth: 1000, // default
                        // maxHeight: 1000, // default
                         imageType: 'image/jpeg', // default
                         debug: true, // default
                         suppressErrorLogging: false, // default
                         insertIntoEditor: undefined, // default
                        }
                        //theme: 'snow'
                        }
                      }"
                    />

                  </v-col>
                  <v-col cols="12" class="pb-16" style="height: 550px;">
                    <quill-editor
                        style="height: 450px;"
                        v-model:value="salonDescription"
                        :options=" {
                        modules: {
                        imageDrop: true,
                        imageResize: true,
                          htmlEditButton: {
                          debug: true,
                          syntax: false,
                          buttonTitle: 'Показать HTML код',
                          msg: 'Отредактируйте HTML  в этом окне,  для сохранения нажмите - сохранить',
                          okText: 'Сохранить',
                          cancelText: 'Отмена',
                          buttonHTML: '&lt;&gt;',
                          prependSelector: 'div#myelement',
                          editorModules: {}

                },
                        imageCompress: {
                         quality: 0.7, // default
                         maxWidth: 1000, // default
                        // maxHeight: 1000, // default
                         imageType: 'image/jpeg', // default
                         debug: true, // default
                         suppressErrorLogging: false, // default
                         insertIntoEditor: undefined, // default
                        }
                        //theme: 'snow'
                        }
                      }"
                    />

                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                        v-model="salonType"
                        :items="eventTypes"
                        outlined
                        dense
                        variant="outlined"
                        label="Тип"
                        item-title="name"
                        item-value="id"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" v-if="salonType===1">
                    <v-autocomplete
                        v-model="webinarId"
                        :items="webinars"
                        outlined
                        dense
                        item-title="webinarName"
                        item-value="id"
                        label="Вебинар"
                        required
                    >
                      <template v-slot:item="{ props, item }">

                        <v-list-item
                            v-bind="props"
                            :title="item.raw.webinarName"

                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>


                  <v-col cols="12" v-if="salonType===2">
                    <v-text-field
                        label="Внешняя ссылка*"
                        v-model="externalLink"
                        :rules="titleRules"
                        :counter="250"
                        variant="outlined"
                        hint="Укажите ссылку"
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" v-if="salonType===2">
                    <v-text-field
                        label="Описание ссылки*"
                        v-model="externalLinkDescription"
                        :rules="titleRules"
                        :counter="250"
                        variant="outlined"
                        hint="Укажите описание ссылки"
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-label>Дата проведения*</v-label>
                    <datePicker
                        v-model="salonDate"
                        autoApply
                        inline
                        textInput
                        locale="ru"
                    />
                    <v-alert
                        v-if="eventDateTimeAlert === true"
                        border="top"
                        color="red lighten-2"
                        dark
                    >
                      Укажите дату проведения !
                    </v-alert>
                  </v-col>

                  <v-col cols="12" class="py-16">
                    <v-autocomplete
                        v-model="videoFiles"
                        :items="libraryVideoFiles"
                        clearable
                        return-object
                        multiple
                        outlined
                        dense
                        small-chips
                        chips
                        closable-chips
                        item-title="videoDescription"
                        label="Видео"
                        v-on:click:clear="libraryVideoFiles = []"
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                            v-bind="props"
                            :text="item.raw.videoDescription.length>30 ? item.raw.videoDescription.substring(0, 28) + '...' : item.raw.videoDescription"
                        ></v-chip>
                      </template>
                      <template v-slot:item="{ props, item }">

                        <v-list-item
                            v-bind="props"
                            :title="item.raw.videoDescription"
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>


                  <v-col cols="12" class="py-16">
                    <v-row
                        class="px-4"
                        fluid
                    >
                      <v-switch
                          v-model="published"
                          :label="'Опубликовано'"
                          color="success"
                      >
                      </v-switch>
                      <v-chip class="ma-2" v-if="published"> Опубликовано</v-chip><v-chip v-else class="ma-2"> Не опубликовано</v-chip>
                    </v-row>
                  </v-col>


                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>

            <AppButton
                class="admin-lib__opener d-flex flex__ai-c"
                @click="closeEventForm"
            >
              <span>Отмена</span>
            </AppButton>
            <AppButton
                class="admin-lib__opener d-flex flex__ai-c"
                @click="saveSalon"
            >
              <span>Сохранить</span>
            </AppButton>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-title class="card_ligth_header ma-0 pa-0" >
            <v-row cols=6 class="pa-2 ma-2">
              <v-col cols="12">
                <h3>События</h3>
              </v-col>

              <v-col cols=12>
                <v-text-field
                    v-model="eventSearchValue"
                    label="Что ищем"
                    variant="outlined"
                    clearable
                    rounded
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <AppButton
                    class="n-parametrs__opener d-flex flex__ai-c"
                    @click="createEvent">
                  <span>Добавить</span>
                </AppButton>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text clas="ma-0 pa-0" >
            <v-row>
              <v-col cols="12">
                <Vue3EasyDataTable
                    class="mb-16 pb-16"
                    :headers="salonHeaders"
                    :items="salons"
                    alternating
                    show-index
                    :search-value="eventSearchValue"
                    :rows-per-page-message="'Записей на страницу'"
                    :rows-per-page = "10"
                    :rows-items="[10,20,50]"
                    :rows-of-page-separator-message	="'из'"
                    buttons-pagination
                    :empty-message="'Данные не добавлены'"
                >
                  <template #item-salonType="{salonType}">
                    <p v-if="salonType===1">Вебинар</p>
                    <p v-else-if="salonType===2">Курс</p>
                    <p v-else-if="salonType===3">Внешняя ссылка</p>
                  </template>

                  <template #item-newsAnnotation="{newsAnnotation}">
                    <div v-html="newsAnnotation"></div>
                  </template>

                  <template #item-published="{published}">
                    <p v-if="published">Да</p> <p v-else>Нет</p>
                  </template>
                  <template #item-salonDate="{salonDate}">
                    {{ salonDate.replace('T', ' ')}}
                  </template>
                  <template #item-creator="{creator}">
                    <p v-if="creator.firstname!==null & creator.lastname!==null">{{ creator.firstname}}  {{ creator.lastname}}</p>
                    <p v-else>{{ creator.firstname}}</p>
                  </template>
                  <template #item-event="{course, webinar, externalUrl, salonType }">
                    <p v-if="salonType===1">{{webinar}}</p>
                    <p v-else-if="salonType===2">{{course}}</p>
                    <p v-else-if="salonType===3">{{externalUrl}}</p>
                  </template>

                  <template #item-actions="item">
                    <AppButton
                        class="admin-lib__table_button d-flex flex__ai-c"
                        @click="editSalon(item)">
                      <span>Редактировать</span>
                    </AppButton>
                    <AppButton
                        class="admin-lib__table_button d-flex flex__ai-c"
                        @click="deleteSalon(item)">
                      <span>Удалить</span>
                    </AppButton>
                  </template>
                </Vue3EasyDataTable>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>


      </v-window-item>
    </v-window>
  </div>
  <v-dialog v-model="newsDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование новости</h5>
        <h5 v-else>Добавление новости</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="newsForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Заголовок*"
                    v-model="title"
                    :rules="titleRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Заголовок*"
                    v-model="newsAnnotation"
                    :rules="newsAnnotationRules"
                    :counter="1500"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12" class="pb-16" style="height: 400px;">
                <quill-editor
                    v-model:value="newsContent"
                />

              </v-col>

              <v-col cols="12" class="py-16">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="published"
                      :label="'Опубликовано'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="published"> Опубликовано</v-chip><v-chip v-else class="ma-2"> Не опубликовано</v-chip>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="Источник*"
                    v-model="sourceUrl"
                    :rules="titleRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>


            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeNewsForm"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveNews"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>

 
  <v-dialog v-model="showLoader" persistent >
    <loader-ring></loader-ring>
  </v-dialog>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import { customAxios} from "@/service/customAxios";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import AppButton from "@/elements/AppButton.vue";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminNewsPage',

  props: [],
  components:{
    LoaderRing,
    AppButton,
    Vue3EasyDataTable
  },


  data: () => ({
    salonAnnonce:'',
    salonName:'',
    salonDescription:'',
    salonDate:'',
    salonType:1,
    externalLink:'',
    externalLinkDescription:'',
    videoFiles:[],
    libraryVideoFiles:[],


    news:[],
    events:[],
    newsSearchValue:'',
    newsDialog:false,
    salonDialog:false,
    eventDateTime:'',
    eventDateTimeAlert:false,


    newsHeaders: [
      { text: "id", value: "id" },
      { text: "Заголовок", value: "title" , sortable: true  },
      { text: "Опубликован", value: "published" , sortable: true},
      { text: "Дата публикации", value: "createDateTime" , sortable: true},
      { text: "Автор", value: "newsAuthor" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
    eventSearchValue:'',
    salonHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "salonName" , sortable: true  },
      { text: "Дата проведения", value: "salonDate" , sortable: true},
      { text: "Тип", value: "salonType" , sortable: true},
      { text: "Автор", value: "creator" , sortable: true},
      { text: "Опубликован", value: "published" , sortable: true},
      { text: "Действие", value: "actions"},
    ],

    id:'',
    tab:1,
    webinarId:'',
    courseId:'',
    webinars:[],
    courses:[],
    salons:[],
    parentSalonId:'',
    eventTypes: [
      { name: "Вебинар", id: 1 },
      { name: "Внешняя ссылка", id: 2},
    ],


    showLoader: false,
    title:'',
    titleRules: [
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],
    newsAnnotation:'',
    newsAnnotationRules: [
      v => (v && v.length <= 1500) || 'Значение до должно превышать 1500 символов!',
    ],
    newsContent:'',

    published:false,
    externalUrl:'',

    eventName:'',
    eventDescription:'',
    eventBody:'',
    eventTypeId:1,

  }),
  methods: {
    createNews(){
      this.id='';
      this.newsDialog=true
    },
    createEvent(){
      this.id='';
      this.salonDialog=true
    },
    editNews(item){
      this.id = item.id
      this.title = item.title
      this.newsAnnotation= item.newsAnnotation
      this.newsContent= item.newsContent
      this.published = item.published
      this.sourceUrl = item.sourceUrl
      this.newsDialog = true
    },
    saveNews() {
      this.$refs.newsForm.validate().then(responce =>{
        if(responce.valid){
          let newsNote = {
            title:capitalizeFirstLetter(this.title),
            newsAnnotation:capitalizeFirstLetter(this.newsAnnotation),
            newsContent: this.newsContent,
            published:this.published,
            sourceUrl: this.sourceUrl,

          }

          if (this.id) {
            customAxios.putWithAuth('news/' + this.id, newsNote)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.news, response.data.id)
                    this.news.splice(index, 1, response.data)
                    this.id=''
                    this.newsDialog = false
                    this.newsAnnotation = ''
                    this.published = false
                    this.newsContent = ''
                    this.title = ''
                    this.sourceUrl = ''

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('news', newsNote)
                .then(response => {

                  if (response.status === 201) {
                    this.news.push(response.data)
                    this.id=''
                    this.newsDialog = false
                    this.newsAnnotation = ''
                    this.published = false
                    this.newsContent = ''
                    this.title = ''
                    this.sourceUrl = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })

    },
    closeNewsForm(){
      this.id=''
      this.newsDialog = false
      this.newsAnnotation = ''
      this.published = false
      this.newsContent = ''
      this.title = ''
      this.sourceUrl = ''

    },
    deleteNews(item){
      customAxios.delete("news" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.news, item.id);
              this.news.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },

    editSalon(item){
      this.salonName=item.salonName;
      this.salonAnnonce=item.salonAnnonce;
      this.salonDescription=item.salonDescription;
      this.salonDate=new Date(item.salonDate);
      this.webinarId=item.webinarId;
      this.courseId=item.courseId;
      this.salonType=item.salonType;
      this.externalLink=item.externalLink;
      this.externalLinkDescription=item.externalLinkDescription;
      this.eventDateTime=item.eventDateTime;
      this.id=item.id;
      this.videoFiles = item.videoFiles;
      this.published = item.published;
      this.salonDialog = true;
    },
    clearSalonForm(){
      this.salonDialog = false;
      this.salonName='';
      this.videoFiles = [];
      this.salonAnnonce='';
      this.salonDescription='';
      this.salonDate='';
      this.webinarId='';
      this.courseId='';
      this.salonType=1
      this.externalLink='';
      this.externalLinkDescription='';
      this.eventDateTime='';
      this.id='';
      this.published = false;
    },

    saveSalon() {
      this.$refs.salonForm.validate().then(responce =>{
        if(responce.valid){
          const userTimezoneOffset =
              this.salonDate.getTimezoneOffset() * 60000;

          let salon = {
            salonName:capitalizeFirstLetter(this.salonName),
            salonAnnonce:this.salonAnnonce,
            salonDescription:this.salonDescription,
            salonDate: new Date(this.salonDate.getTime() - userTimezoneOffset),
            webinarId: this.webinarId,
            courseId: this.courseId,
            salonType: this.salonType,
            externalLink: this.externalLink,
            externalLinkDescription: this.externalLinkDescription,
            eventDateTime: this.eventDateTime,
            published: this.published,
            videoFiles: this.videoFiles
          }

          if (this.id) {
            customAxios.putWithAuth('salon/' + this.id, salon)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.salons, response.data.id)
                    this.salons.splice(index, 1, response.data)
                    this.clearSalonForm()
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('salon', salon)
                .then(response => {

                  if (response.status === 201) {
                    this.salons.push(response.data)
                    this.clearSalonForm();
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })

    },
    closeEventForm(){
      this.salonDialog = false
      this.id = ''
      this.eventName = ''
      this.eventDescription = ''
      this.salonAnnonce = ''
      this.eventTypeId = 1
      this.webinarId=''
      this.courseId = ''
      this.sourceUrl = ''
      this.parentSalonId = ''
    },
    deleteSalon(item){
      customAxios.delete("salon/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.salons, item.id);
              this.salons.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
  },
  computed: {


  },
  created: function () {
    this.showLoader=true

    function getNews() {
      return customAxios.getWithAuth('news/all')
    }

    function getEvents() {
      return customAxios.getWithAuth('event')
    }

    function getCourses() {
      return customAxios.getWithAuth('course/all')
    }

    function getWebinars() {
      return customAxios.getWithAuth('webinar')
    }

    function getSalons() {
      return customAxios.getWithAuth('salon')
    }

    function getVideos() {
      return customAxios.getWithAuth('library/video')
    }



    Promise.all([
        getNews(),
        getEvents(),
        getCourses(),
        getWebinars(),
        getSalons(),
        getVideos(),
    ]).then(results => {
      if(results[0].status===200){
        this.news = results[0].data;
      }
      if(results[1].status===200){
        this.events = results[1].data;
      }
      if(results[2].status===200){
        this.courses = results[2].data;
      }
      if(results[3].status===200){
        this.webinars = results[3].data;
      }
      if(results[4].status===200){
        this.salons = results[4].data;
      }
      if(results[5].status===200){
        this.libraryVideoFiles = results[5].data;
      }
      this.showLoader=false
    }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")
    });
  }
}
</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
