<template>
  <div class="video-preview">
    <AppTitle v-if="video.videoDescription" mode="h4">
      <template v-slot:title>{{ video.videoDescription }}</template>
    </AppTitle>
    <div class="video-preview__poster">
      <AppImage
        v-if="video.posterName"
        :image="`https://navigator.eurekacenter.ru/video/${video.posterName}`"
      />
      <AppIcon icon="play-icon" />
    </div>
  </div>
</template>

<script>
import AppIcon from "@/elements/AppIcon.vue";
import AppImage from "@/elements/AppImage.vue";
import AppTitle from "@/elements/AppTitle.vue";

export default {
  components: { AppImage, AppTitle, AppIcon },
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.video-preview {
  max-width: 320px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.2s;
  background: $gradient;
  border-radius: 8px;
  box-shadow: $shadow-1;

  &__poster {
    position: relative;

    image {
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .app-icon {
      width: 50%;
      height: 50%;
    }

    svg {
      font-size: 30px;
      transition: all 0.3s;
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      stroke: #fff;
    }

    &::after {
      content: "";
      position: absolute;
      @include full-bg;
      transition: all 0.2s;
      background-color: rgba($text, 0);
    }
  }

  &:hover .video-preview__poster {
    &::after {
      background-color: rgba($text, 0.5);
    }

    svg {
      opacity: 1;
      z-index: 20;
    }
  }

  &:hover {
    box-shadow: $shadow-2;
    transform: scale(1.01);
  }

  h4 {
    padding: 16px;
    color: #fff;
  }
}
</style>
