<template>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab value="1">Облачные опросы</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item value="1" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0">
          <v-row  class=" ma-2 pa-2" >
            <v-col cols="12">
              <h3>Опросы</h3>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="qDialog=true">
                <span>Добавить</span>
              </AppButton>
            </v-col>

          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text  class="ml-0 mr-0 pb-16">
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="questionnaireHeaders"
                  :items="questionnaires"
                  alternating
                  show-index
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >

                <template #item-createDate="{createDate}">
                  {{ createDate.replace('T', ' ')}}
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="addQuestions(item)">
                    <span>Вопросы</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editQuestionnaire(item)">
                    <span>Изменить</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteQuestionnaire(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>

        </v-card-text>
      </v-window-item>
      <v-window-item :value="2" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Вопросы</h3>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="questionDialog=true">
                <span>Добавить вопрос</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="questionHeaders"
                  :items="questions"
                  alternating
                  show-index
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-freeAnswer="{freeAnswer}">
                  <p v-if="freeAnswer">Да</p>
                  <p>Нет</p>
                </template>
                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editQuestion(item)">
                    <span>Изменить</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteQuestion(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="3" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Вопросы</h3>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="backToQuestions">
                <span>Назад</span>
              </AppButton>
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="answerDialog=true">
                <span>Добавить ответ</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="questionHeaders"
                  :items="questionDialog"
                  alternating
                  show-index
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-freeAnswer="{freeAnswer}">
                  <p v-if="freeAnswer">Да</p>
                  <p>Нет</p>
                </template>
                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="questionAnswers(item)">
                    <span>Изменить</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editQuestion(item)">
                    <span>Изменить</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteQuestion(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
    </v-window>
  </div>

  <v-dialog v-model="qDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Опрос</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="questionnaireForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="name"
                    :rules="nameRules"
                    hint="Укажите название"
                    :counter="240"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Описание*"
                    v-model="description"
                    :rules="descriptionRules"
                    :counter="240"
                    variant="outlined"
                    hint="Укажите описание"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="questionCount"
                    :items="options"
                    item-title="text"
                    item-value="value"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton class="admin-lib__opener d-flex flex__ai-c" @click="closeQuestionnaireDialog">
          <span>Отмена</span>
        </AppButton>
        <AppButton class="admin-lib__opener d-flex flex__ai-c" @click="saveQuestionnaire">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="questionDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Вопрос</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="questionForm">
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field
                    label="Вопрос*"
                    v-model="questionBody"
                    :rules="descriptionRules"
                    :counter="240"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-label>
                  Кол-во ответов:
                </v-label>
                <v-select
                    v-model="answerCount"
                    :items="options"
                    item-title="text"
                    item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-label>
                  Порядковый номер:
                </v-label>
                <v-select
                    v-model="questionOrder"
                    :items="options"
                    item-title="text"
                    item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="12" class="py-16">
                  <v-row class="px-4" fluid>
                    <v-switch
                        v-model="freeAnswer"
                        :label="'Свободный ответ'"
                        color="success">
                    </v-switch>
                    <v-chip class="ma-2" v-if="freeAnswer">Свободный ответ</v-chip><v-chip v-else class="ma-2"> Заданный ответ</v-chip>
                  </v-row>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton class="admin-lib__opener d-flex flex__ai-c" @click="closeQuestionDialog">
          <span>Отмена</span>
        </AppButton>
        <AppButton class="admin-lib__opener d-flex flex__ai-c" @click="saveQuestion">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="answerDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Ответ</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="answerForm">
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field
                    label="Ответ"
                    v-model="answerBody"
                    :rules="descriptionRules"
                    :counter="240"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-label>
                  Порядковый номер:
                </v-label>
                <v-select
                    v-model="answerOrder"
                    :items="options"
                    item-title="text"
                    item-value="value"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton class="admin-lib__opener d-flex flex__ai-c" @click="closeAnswerDialog">
          <span>Отмена</span>
        </AppButton>
        <AppButton class="admin-lib__opener d-flex flex__ai-c" @click="saveAnswer">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="showLoader" persistent >
    <loader-ring></loader-ring>
  </v-dialog>
</template>

<script>

import { customAxios} from "@/service/customAxios";
import {customStorage} from "@/service/customStorage";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import AppButton from "@/elements/AppButton.vue";
import LoaderRing from "@/elements/LoaderRing.vue";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminMental',

  props: [],
  components:{
    LoaderRing,
    AppButton,
    Vue3EasyDataTable
  },


  data: () => ({
    qDialog: false,
    questionnaireHeaders: [
      { text: "Имя", value: "name" , sortable: true },
      { text: "Описание", value: "description" , sortable: true },
      { text: "Вопросов", value: "questionCount" , sortable: true },
      { text: "Добавлено", value: "createDate" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
    id:'',
    name:'',
    nameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 240) || 'Значение до должно превышать 1500 символов!',
    ],
    description:'',
    descriptionRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 240) || 'Значение до должно превышать 1500 символов!',
    ],
    questionCount: 0,
    questionnaires:[],
    selectedQuestionnaire:'',
    questions:[],

    questionDialog: false,
    questionnaireId:'',
    questionOrder:1,
    freeAnswer:false,
    questionBody:'',
    answerCount:1,
    questionHeaders: [
      { text: "Вопрос", value: "questionBody" , sortable: true },
      { text: "Ответов", value: "answerCount" , sortable: true },
      { text: "Прядковый №", value: "questionOrder" , sortable: true },
      { text: "Свободный ответ", value: "freeAnswer" , sortable: true },
      { text: "Действие", value: "actions"},
    ],

    answerDialog: false,
    answerHeaders: [
      { text: "Ответ", value: "answerBody" , sortable: true },
      { text: "Прядковый №", value: "answerOrder" , sortable: true },
      { text: "Действие", value: "actions"},
    ],

    answerOrder:1,
    answerBody:'',
    selectedQuestion:'',
    answers:[],














    subject:'',
    subjectRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 1500) || 'Значение до должно превышать 1500 символов!',
    ],
    body:'',
    complete: false,

    firstname:'',
    lastname:'',
    secname:'',
    unsubscribeUuid:'',
    emailAddress:'',
    agreement:true,

    notifications:[],
    recipients:[],
    recipientsList:[],
    notificationDialog: false,
    recipientDialog: false,


    recipientsHeaders: [
      { text: "Имя", value: "name" , sortable: true, width: 100 },
      { text: "Email", value: "emailAddress" , sortable: true, width: 100 },
      { text: "Согласие", value: "agreement" , sortable: true, width: 120 },
      { text: "Действие", value: "actions", width: 130},
    ],
    notificationHeaders: [
      { text: "id", value: "id" , sortable: true},
      { text: "Тема", value: "subject" , sortable: true  },
      { text: "Добавлено", value: "createDateTime" , sortable: true},
      { text: "Завершено", value: "complete" , sortable: true},
      { text: "Действие", value: "actions", width: 280},
    ],

    showLoader: false,
    firstnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    lastnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    secnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    usernameRules: [
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],


    page: 1,
    noteCount: 20,
    tab:1,

  }),
  methods: {
    addQuestions(item){
      console.log("creating questions")
      this.selectedQuestionnaire = item;
      customAxios.getWithAuth('mental_questionnaire_question/' + item.id)
          .then(response => {
            if (response.status === 200) {
              this.questions=response.data;
              this.tab = 2;

            } else if (response.status === 207) {
              alert(response.data.message)
              console.log(response)
            } else {
              alert("что то пошло не так")
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })
    },
    editQuestionnaire(item){
      this.id = item.id;
      this.name = item.name;
      this.description = item.description;
      this.questionCount = item.questionCount;
      this.qDialog = true;
    },

    closeQuestionnaireDialog(){
      this.qDialog = false;
      this.id = '';
      this.name = '';
      this.description = '';
      this.questionCount = 0;
    },

    deleteQuestionnaire(item){
      customAxios.delete('mental_questionnaire/' + item.id)
          .then(response => {
            if (response.status === 202) {
              let index = getIndex(this.questionnaires, response.data.id)
              this.questionnaires.splice(index)
              this.closeQuestionnaireDialog();

            } else if (response.status === 207) {
              alert(response.data.message)
              console.log(response)
            } else {
              alert("что то пошло не так")
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })
    },

    saveQuestionnaire(){
      this.$refs.questionnaireForm.validate().then(responce =>{
        if(responce.valid){
          let questionnaireForm = {
            name:capitalizeFirstLetter(this.name),
            description:capitalizeFirstLetter(this.description),
            questionCount: this.questionCount
          }
          if (this.id) {
            customAxios.putWithAuth('mental_questionnaire/' + this.id, questionnaireForm)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.questionnaires, response.data.id)
                    this.questionnaires.splice(index, 1, response.data)
                    this.closeQuestionnaireDialog();

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {
            customAxios.post('mental_questionnaire', questionnaireForm)
                .then(response => {
                  if (response.status === 201) {
                    this.questionnaires.push(response.data)
                    this.closeQuestionnaireDialog()
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })

    },


    closeQuestionDialog(){
      this.questionDialog = false;
      this.id = '';
      this.questionBody = '';
      this.answerCount = 1;
      this.questionOrder = 1;
      this.answerCount = 1;
      this.freeAnswer = false;
    },

    saveQuestion(){
      console.log("saving")
      this.$refs.questionForm.validate().then(responce =>{
        if(responce.valid){
          let question = {
            questionBody:capitalizeFirstLetter(this.questionBody),
            questionOrder: this.questionOrder,
            freeAnswer: this.freeAnswer,
            answerCount: this.answerCount,
            questionnaireId: this.selectedQuestionnaire.id
          }
          if (this.id) {
            customAxios.putWithAuth('mental_questionnaire_question/' + this.id, question)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.questions, response.data.id)
                    this.questions.splice(index, 1, response.data)
                    this.closeQuestionDialog();

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {
            customAxios.post('mental_questionnaire_question', question)
                .then(response => {
                  if (response.status === 201) {
                    this.questions.push(response.data)
                    this.closeQuestionDialog()
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })

    },
    editQuestion(item){
      this.id = item.id;
      this.questionnaireId = item.questionnaireId;
      this.questionOrder = item.questionOrder;
      this.freeAnswer = item.freeAnswer;
      this.questionBody = item.questionBody;
      this.answerCount = item.answerCount;
      this.questionDialog = true;
    },
    deleteQuestion(item){
      customAxios.delete('mental_questionnaire_question/' + item.id)
          .then(response => {
            if (response.status === 202) {
              let index = getIndex(this.questions, response.data.id)
              this.questions.splice(index)
            } else if (response.status === 207) {
              alert(response.data.message)
              console.log(response)
            } else {
              alert("что то пошло не так")
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })
    },

    questionAnswers(item){
      this.selectedQuestion = item;
      customAxios.getWithAuth('mental_questionnaire_answer/' + item.id)
          .then(response => {
            if (response.status === 200) {
              this.answers.push(response.data);
              this.tab = 3;

            } else if (response.status === 207) {
              alert(response.data.message)
              console.log(response)
            } else {
              alert("что то пошло не так")
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })

    },

    closeAnswerDialog(){
      this.answerDialog = false;
      this.id = '';
      this.answerBody = '';
      this.answerOrder = 1;
    },
    editAnswer(item){
      this.id = item.id;
      this.questionId = item.questionId;
      this.answerOrder = item.answerOrder;
      this.answerBody = item.answerBody;
      this.answerDialog = true;
    },
    deleteAnswer(item){
      customAxios.delete('mental_questionnaire_answer/' + item.id)
          .then(response => {
            if (response.status === 202) {
              let index = getIndex(this.answers, response.data.id)
              this.answers.splice(index)
            } else if (response.status === 207) {
              alert(response.data.message)
              console.log(response)
            } else {
              alert("что то пошло не так")
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })
    },
    saveAnswer(){
      this.$refs.answerForm.validate().then(responce =>{
        if(responce.valid){
          let question = {
            answerBody:capitalizeFirstLetter(this.answerBody),
            answerOrder:this.answerOrder,
            questionId: this.selectedQuestion.id,
          }
          if (this.id) {
            customAxios.putWithAuth('mental_questionnaire_answer/' + this.id, question)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.answers, response.data.id)
                    this.answers.splice(index, 1, response.data)
                    this.closeAnswerDialog();

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {
            customAxios.post('mental_questionnaire_answer', question)
                .then(response => {
                  if (response.status === 201) {
                    this.answers.push(response.data)
                    this.closeAnswerDialog()
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })

    },

    backToQuestions(){
      this.tab=2
    },


  },
  computed: {
    options(){
      let opts = [];
      for(let i = 1; i <= 50; i++){
        opts.push({text:i, value:i});
      }
      return opts
    },

    jwtHeaders(){
      let jwtTokenConfig = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + customStorage.get("user-token"),
        },
        responseType: 'blob'
      }
      return jwtTokenConfig
    },

  },
  created: function () {
    this.showLoader=true

    function getQ() {
      return customAxios.getWithAuth('mental_questionnaire')
    }



    Promise.all([
      getQ()

    ])
        .then(results => {
          if(results[0].status===200){
            this.questionnaires = results[0].data;
          }

          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")

    });
  }
}

</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
