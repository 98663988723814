<template>
  <AppButton class="colored-button">
    <slot></slot>
  </AppButton>
</template>

<script>
import AppButton from "./AppButton.vue";

export default {
  setup() {
    return {};
  },
  components: { AppButton },
};
</script>

<style lang="scss">
.colored-button {
  background-color: $accent-1;
  color: #fff;
  border-radius: 16px;

  svg {
    stroke: #fff;
    margin-right: 8px;
  }

  & + .colored-button {
    margin-top: 16px;
  }

  &:hover {
    background-color: rgba($accent-1, 0.8);
  }

  &:active {
    background-color: rgba($accent-1, 0.9);
  }

  &:nth-child(2n) {
    background-color: $accent-2;

    &:hover {
      background-color: rgba($accent-2, 0.8);
    }

    &:active {
      background-color: rgba($accent-2, 0.9);
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: rgba($text, 0.1);
    color: #fff;
    cursor: not-allowed;
  }
}
</style>
