<template>
  <div class="events-page">
    <StateWrapper
      :content="events"
      :loadingStatus="loadingStatus"
      :checkLogin="true"
    >
      <div class="wrapper">
        <AppTitle mode="h2" class="auth-form__title">
          <template v-slot:title>{{ t("events") }}</template>
        </AppTitle>

        <masonry-wall
          :items="getSlicedArray(events, itemsPerPage, currentPage)"
          :ssr-columns="1"
          :column-width="300"
          :padding="16"
          :gap="16"
        >
          <template #default="{ item, index }">
            <div style="height: auto">
              <EventPreview
                :key="index"
                :item="item"
                @startWebinar="startWebinar"
              />
            </div>
          </template>
        </masonry-wall>

        <WebinarModal
          v-if="showWebinar"
          @closeModal="showWebinar = false"
          :webinar="webinar"
        />
        <div class="flex flex__jc-c" v-if="events.length">
          <vue-awesome-paginate
            :total-items="events.length"
            v-model="currentPage"
            :items-per-page="itemsPerPage"
            :max-pages-shown="maxPagesShown"
          />
        </div>
      </div>
    </StateWrapper>
  </div>
</template>

<script>
import { customAxios } from "@/service/customAxios";
import { onMounted, ref } from "vue";
import { t } from "@/utils/translate";

import EventPreview from "@/components/EventPreview.vue";
import WebinarModal from "@/components/WebinarModal.vue";

import { usePagination } from "@/composables/usePagination";
import StateWrapper from "@/components/StateWrapper.vue";
import AppTitle from "@/elements/AppTitle.vue";

const containerId = 1;

export default {
  components: { EventPreview, WebinarModal, StateWrapper, AppTitle },

  setup() {
    const events = ref([]);
    const showWebinar = ref(false);
    const webinar = ref(null);

    const currentPage = ref(1);
    const maxPagesShown = ref(5);
    const itemsPerPage = ref(10);
    const { getSlicedArray } = usePagination();

    const loadingStatus = ref("loading");

    async function getEvents() {
      try {
        const r = await customAxios.getWithAuth("event");
        loadingStatus.value = "loaded";
        console.log(r.data);
        return r.data;
      } catch (e) {
        loadingStatus.value = "error";
      }
    }

    function startWebinar(w) {
      console.log(w);
      showWebinar.value = true;
      webinar.value = w;
    }

    onMounted(async () => {
      events.value = await getEvents();
    });

    return {
      t,
      itemsPerPage,
      currentPage,
      maxPagesShown,
      getSlicedArray,
      events,
      containerId,
      showWebinar,
      startWebinar,
      webinar,
      loadingStatus,
    };
  },
};
</script>

<style lang="scss">
.events-page {
  @include page;
}
</style>
