<template>
  <AuthForm :title="t('registration')" class="n-register">
    <template v-slot:top>
      <span>{{ t("have.account") }}</span>

      <TransparentButton @click="$emit('change-form', 'login')">
        <span>{{ t("come.in") }}</span>
      </TransparentButton>
    </template>

    <template v-slot:inputs>
      <AppInput
        v-model="vr$.name.$model"
        :label="t('name')"
        id="r-name"
        :class="setValidationStatusClass(vr$.name)"
        @blur="vr$.name.$touch"
        :element="vr$.name"
      />

      <AppInput
        v-model="vr$.surname.$model"
        :label="t('surname')"
        id="r-surname"
        :class="setValidationStatusClass(vr$.surname)"
        @blur="vr$.surname.$touch"
        :element="vr$.surname"
      />

      <AppInput
        inputType="email"
        v-model="vr$.email.$model"
        :label="t('email')"
        id="r-email"
        :class="setValidationStatusClass(vr$.email)"
        :element="vr$.email"
        @blur="vr$.email.$touch"
      />

      <AppInput
        inputType="password"
        v-model="vr$.password.$model"
        :label="t('password')"
        id="e-password"
        :class="setValidationStatusClass(vr$.password)"
        :element="vr$.password"
        @blur="vr$.password.$touch"
      />

      <div class="n-register__agreements" ref="agreementsRef">
        <AppTooltip>
          <p>
            Для завершения регистрации необходимо прочитать и согласиться с
            нашими:
            <AppButton @click="userAgreeModalShow = true"
              >Пользовательским соглашением</AppButton
            >
            <AppButton @click="politicModalShow = true"
              >Политикой в отношении персональных данных</AppButton
            >
          </p>
        </AppTooltip>

        <AppCheckbox
          :id="1"
          :label="t('userAgree')"
          :value="t('userAgree')"
          :checked="userAgreeChecked"
          @toggle-check="userAgreeChecked = !userAgreeChecked"
        ></AppCheckbox>

        <AppCheckbox
          :id="2"
          :label="t('politic')"
          :value="t('politic')"
          :checked="politicChecked"
          @toggle-check="politicChecked = !politicChecked"
        ></AppCheckbox>
      </div>
      <DocumentModal
        v-if="politicModalShow"
        @closeModal="politicModalShow = false"
        @agreed="agreedWithPolitic"
      >
        <PoliticPage></PoliticPage>
      </DocumentModal>
      <DocumentModal
        v-if="userAgreeModalShow"
        @closeModal="userAgreeModalShow = false"
        @agreed="agreedWithUserAgreements"
      >
        <UserAgreePage>111</UserAgreePage>
      </DocumentModal>
    </template>

    <template v-slot:buttons>
      <ColoredButton
        :disabled="vr$.$invalid || !politicChecked || !userAgreeChecked"
        @click="
          $emit('register', {
            firstName: rName,
            lastName: rSurname,
            email: rEmail,
            password: rPassword,
          })
        "
      >
        <span>{{ t("register") }}</span>
      </ColoredButton>
    </template>
  </AuthForm>
</template>

<script>
import { ref } from "vue";
import { t } from "@/utils/translate";

import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { setValidationStatusClass } from "@/utils/validation";

import AuthForm from "./AuthForm.vue";
import AppInput from "@/elements/AppInput";
import TransparentButton from "@/elements/TransparentButton.vue";

import AppCheckbox from "@/elements/AppCheckbox.vue";
import ColoredButton from "@/elements/ColoredButton.vue";
import AppTooltip from "./AppTooltip.vue";

import DocumentModal from "./DocumentModal.vue";
import PoliticPage from "@/pages/PoliticPage.vue";
import UserAgreePage from "@/pages/UserAgreePage.vue";

import AppButton from "@/elements/AppButton.vue";
import { onMounted } from "vue";

export default {
  components: {
    AppInput,
    AuthForm,
    TransparentButton,
    ColoredButton,
    AppCheckbox,
    AppTooltip,
    DocumentModal,
    PoliticPage,
    UserAgreePage,
    AppButton,
  },
  emits: ["change-form", "register"],
  setup(_, ctx) {
    const rEmail = ref("");
    const rPassword = ref("");
    const rName = ref("");
    const rSurname = ref("");

    const userAgreeChecked = ref(false);
    const politicChecked = ref(false);
    const userAgreeModalShow = ref(false);
    const politicModalShow = ref(false);

    const rRules = {
      password: { required, min: minLength(6) },
      name: { required },
      surname: { required },
      email: { required, email },
    };

    const vr$ = useVuelidate(rRules, {
      email: rEmail,
      password: rPassword,
      name: rName,
      surname: rSurname,
    });

    function agreedWithPolitic() {
      politicModalShow.value = false;
      politicChecked.value = true;
    }

    function agreedWithUserAgreements() {
      userAgreeModalShow.value = false;
      userAgreeChecked.value = true;
    }

    function clearForm() {
      rEmail.value = "";
      rPassword.value = "";
      rName.value = "";
      rSurname.value = "";
      userAgreeChecked.value = false;
      userAgreeChecked.value = false;
    }

    onMounted(clearForm);

    return {
      vr$,
      rEmail,
      rPassword,
      rName,
      rSurname,
      t,
      setValidationStatusClass,
      userAgreeChecked,
      politicChecked,
      userAgreeModalShow,
      politicModalShow,
      agreedWithPolitic,
      agreedWithUserAgreements,
    };
  },
};
</script>

<style lang="scss">
.n-register {
  &__agreements {
    .app-checkbox + .app-checkbox {
      margin-top: 20px;
    }

    .app-button {
      margin-top: 12px;

      @include link-button;
    }
  }
}
</style>
