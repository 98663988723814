<template>
  <div class="app-radio" :class="{ checked: checked }">
    <input
      type="radio"
      :id="id"
      @change="$emit('update:modelValue', $event.target.value)"
      :value="value"
      :name="name"
    />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.app-radio {
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
  }

  label {
    cursor: pointer;
    position: relative;
    transition: all 0.2s;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      position: static;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff;
      margin-right: 8px;
      box-shadow: inset 0 0 0 1px $text;
      transition: all 0.2s;
    }

    &:hover {
      color: $accent-1;

      &::before {
        box-shadow: inset 0 0 0 1px $accent-1;
      }
    }
  }

  input:checked + label {
    color: $accent-1;

    &::before {
      background-color: $accent-1;
      border: none;
      box-shadow: inset 0 0 0 1px $accent-1;
    }
  }

  &.checked {
    label {
      color: $accent-1;

      &::before {
        background-color: $accent-1;
        border: none;
        box-shadow: inset 0 0 0 1px $accent-1;
      }
    }
  }

  & + .app-radio {
    margin-top: 16px;
  }
}
</style>
