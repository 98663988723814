import { usePopupStore } from "@/store/popupStore";
import { customAxios } from "@/service/customAxios";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

export function useCourseFavorite() {
  const popupStore = usePopupStore();
  const { showPopup } = popupStore;

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  async function addToFavorite(course) {
    try {
      await customAxios.post("course/favorite", {
        courseId: course.id,
        personId: user.id,
      });

      showPopup("Курс добавлен в избранное", "success");
    } catch (e) {
      showPopup("Что то пошло не так. Попробуйте еще раз", "error");
    }
  }

  async function removeFromFavorite(course) {
    try {
      await customAxios.delete(`course/favorite/${course.id}`);

      showPopup("Курс удален из избранного", "success");
    } catch (e) {
      showPopup("Что то пошло не так. Попробуйте еще раз", "error");
    }
  }

  async function toggleFavoriteStatus(course) {
    try {
      if (course.inFavorite) {
        await removeFromFavorite(course);
      } else {
        await addToFavorite(course);
      }
    } catch (e) {
      showPopup("Что то пошло не так. Попробуйте еще раз", "error");
    }
  }

  return {
    addToFavorite,
    removeFromFavorite,
    toggleFavoriteStatus,
  };
}
