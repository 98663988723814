<template>
  <StateWrapper
    :content="salons"
    :loadingStatus="loadingStatus"
    :checkLogin="false"
  >
    <div class="parents-salon">
      <PageTitle :title="t(`salons`)" />

      <div class="wrapper">
        <div class="n-program-overview__content">
          <p>
             Как понять, готов ребенок к школе или нет и как это проверить; что
            делать: обучать или развивать; нужно ли родителям с ребенком
            выполнять домашнее задание или проверять его, а также какие
            образовательные программы есть для детей определенного возраста?
          </p>
          <p>
            Родительская гостиная - это место встреч родителей и педагогической
            общественности. Здесь можно задать вопросы и получить необходимые
            ответы.
          </p>
        </div>
      </div>
      <div class="wrapper">
        <SalonItems
          :isAdmin="false"
          :salons="salons"
          :loadingStatus="loadingStatus"
          :filters="publicSalonFilters"
        />
      </div>
    </div>
  </StateWrapper>
</template>

<script>
import SalonItems from "@/components/SalonItems.vue";
import PageTitle from "@/elements/PageTitle.vue";

import { customAxios } from "@/service/customAxios";
import { t } from "@/utils/translate";
import { onMounted, ref } from "vue";
import { publicSalonFilters } from "@/app-data/static";
import StateWrapper from "@/components/StateWrapper.vue";

export default {
  setup() {
    const salons = ref([]);
    const loadingStatus = ref("loading");

    async function getPublicSalonData() {
      try {
        const r = await customAxios.getWithAuth("salon/public");
        loadingStatus.value = "loaded";
        return r.data;
      } catch (e) {
        loadingStatus.value = "error";
      }
    }

    onMounted(async () => {
      salons.value = await getPublicSalonData();
    });

    return { t, salons, loadingStatus, publicSalonFilters };
  },
  components: { SalonItems, PageTitle, StateWrapper },
};
</script>

<style lang="scss">
.parents-salon {
  .wrapper {
    @include page;
  }
}
</style>
