<template>
  <div>
    <v-dialog v-model="bookDialog" scrollable>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5 v-if="!!this.id">Добавление книги</h5>
          <h5 v-else>Редактирование книги</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="BookForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Название*"
                      v-model="bookName"
                      :rules="bookNameRules"
                      :counter="500"
                      variant="outlined"
                      hint="Укажите описание"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      label="Аннотация*"
                      v-model="bookAnnotation"
                      :rules="bookAnnotationRules"
                      :counter="1500"
                      variant="outlined"
                      hint="Укажите аннотацию"
                      required
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="ISBN*"
                      v-model="ISBN"
                      :rules="bookNameRules"
                      :counter="500"
                      variant="outlined"
                      hint="Укажите ISBN"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="authorList"
                      :items="filteredAuthors"
                      outlined
                      auto-select-first
                      dense
                      clearable
                      v-on:click:clear="authorList=[]"
                      chips
                      small-chips
                      multiple
                      variant="outlined"
                      label="Авторы"

                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"

                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.lastname + ' ' + item.raw.firstname + ' ' + item.raw.secname"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                      v-model="bookTags"
                      :items="filteredTags"
                      auto-select-first
                      outlined
                      dense
                      chips
                      clearable
                      v-on:click:clear="bookTags=[]"
                      small-chips
                      multiple
                      variant="outlined"
                      label="Ярлыки"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                          v-bind="props"
                          :text="item.raw.tagName"
                      ></v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :title="item.raw.tagName"
                      ></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-container class="px-0" fluid>
                    <v-switch
                        v-model="published"
                        :label="published ? 'Опубликовано' : 'не опубликовано'"
                    ></v-switch>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <AppButton
              class="admin-lib__opener d-flex flex__ai-c"
              @click="clearBook">
            <span>Отмена</span>
          </AppButton>
          <AppButton
              class="admin-lib__opener d-flex flex__ai-c"
              @click="saveBook">
            <span>Сохранить</span>
          </AppButton>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="authorDialog" scrollable>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5 v-if="!!this.id">Добавление книги</h5>
          <h5 v-else>Редактирование книги</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="AuthorForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Фамилия*"
                      v-model="lastname"
                      :rules="lastnameRules"
                      :counter="250"
                      variant="outlined"
                      hint="Укажите фамилию"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Имя*"
                      v-model="firstname"
                      :rules="firstnameRules"
                      :counter="250"
                      variant="outlined"
                      hint="Укажите имя"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Отчество*"
                      v-model="secname"
                      :rules="secnameRules"
                      :counter="250"
                      variant="outlined"
                      hint="Укажите отчество"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <AppButton
              class="admin-lib__opener d-flex flex__ai-c"
              @click="clearAuthor">
            <span>Отмена</span>
          </AppButton>
          <AppButton
              class="admin-lib__opener d-flex flex__ai-c"
              @click="saveAuthor">
            <span>Сохранить</span>
          </AppButton>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="tagDialog" scrollable>
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5 v-if="!!this.id">Добавление ярлыка</h5>
          <h5 v-else>Редактирование ярлыка</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="TagForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Ярлык*"
                      v-model="tagName"
                      :rules="tagNameRules"
                      :counter="250"
                      variant="outlined"
                      hint="Укажите название ярлыка"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-container class="px-0" fluid>
                    <v-switch
                        v-model="active"
                        :label="active ? 'Акетивен' : 'не активен'"
                    ></v-switch>
                  </v-container>
                </v-col>


              </v-row>
            </v-container>
            <small>*обязательно для заполнения</small>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <AppButton
              class="admin-lib__opener d-flex flex__ai-c"
              @click="clearTag">
            <span>Отмена</span>
          </AppButton>
          <AppButton
              class="admin-lib__opener d-flex flex__ai-c"
              @click="saveTag">
            <span>Сохранить</span>
          </AppButton>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="bookLinkDialog" scrollable>
      <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Добавление ссылки</h5>
        <h5 v-else>Редактирование ссылки</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="bookLinkForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Имя ссылки*"
                    v-model="linkName"
                    :rules="tagNameRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите имя ссылки"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Адрес ссылки*"
                    v-model="linkBody"
                    :rules="tagNameRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите адрес ссылки"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="clearBookLink">
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveBookLink">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog v-model="showLoader" persistent >
      <loader-ring></loader-ring>
    </v-dialog>
  </div>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab :value="1" >Книги</v-tab>
      <v-tab value="2" >Авторы</v-tab>
      <v-tab value="3" >Ярлыки</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item :value="1" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Книги</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="bookSearchBody"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
                  v-on:click:clear="filteredBooks = books"
              >
                <template v-slot:append>

                  <v-icon
                      icon="mdi-book-search-outline"
                      @click="filterBooks()"
                  ></v-icon>

                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createBook">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="bookHeaders"
                  :items="filteredBooks"
                  alternating
                  show-index
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                 :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-published="item">
                  <b v-if="item.published===true">Да</b>
                  <b v-else>Нет</b>
                </template>

                <template #item-tags="item">
                  <v-chip-group
                      column
                      multiple>
                    <v-chip
                        v-for="i in item.tags"
                        :key="i"
                        :text="i.tagName"
                        size="small" density="comfortable"
                    >
                    </v-chip>
                  </v-chip-group>
                </template>
                <template #item-authorList="item">
                  <v-chip-group
                      column
                      multiple>
                    <v-chip
                        v-for="i in item.authorList"
                        :key="i"
                        :text="i.lastname + ' ' + i.firstname + ' ' + i.secname"
                        size="small" density="comfortable"
                    >
                    </v-chip>
                  </v-chip-group>
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="openBookLinks(item)">
                    <span>Ссылки</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editBook(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteBook(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="2" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Авторы</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="authorSearchBody"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
                  v-on:click:clear="filteredAuthors = authors"
              >
                <template v-slot:append>

                  <v-icon
                      icon="mdi-book-search-outline"
                      @click="filterAuthors()"
                  ></v-icon>

                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createAuthor">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="authorHeaders"
                  :items="filteredAuthors"
                  alternating
                  show-index
                  :search-value="authorSearchBody"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                 :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-authorName="{firstname, lastname, secname }">
                  <p>{{lastname}} {{firstname}} {{secname}}</p>
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editAuthor(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteAuthor(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="3" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Ярлыки</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="tagSearchBody"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  v-on:click:clear="filteredTags = tags"
                  rounded
              >
                <template v-slot:append>
                  <v-icon
                      icon="mdi-book-search-outline"
                      @click="filterTags()"
                  ></v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createTag">
                <span>Добавить</span>
              </AppButton>

            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="tagHeaders"
                  :items="filteredTags"
                  alternating
                  show-index
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                 :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-active="item">
                  <b v-if="item.active">Да</b>
                  <b v-else >Нет</b>
                </template>
                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editTag(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteTag(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="4" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Ссылки на книгу "{{selectedBook.bookName}}"</h3>
            </v-col>

            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="this.tab=1">
                <span>Назад</span>
              </AppButton>
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createBookLink">
                <span>Добавить</span>
              </AppButton>

            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="bookLinkHeaders"
                  :items="bookLinks"
                  alternating
                  show-index
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="openLink(item)">
                    <span>Перейти</span>
                  </AppButton>

                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editBookLink(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteBookLink(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
    </v-window>
  </div>

</template>

<script>
import { customAxios} from "@/service/customAxios";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import AppButton from "@/elements/AppButton.vue";
import theme from "quill/core/theme";
import LoaderRing from "@/elements/LoaderRing.vue";
import _ from "lodash";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminBookShelfPage',

  props: [],
  components:{
    LoaderRing,
    AppButton,
    Vue3EasyDataTable
  },


  data: () => ({
    tab: 1,

    selectedBook: {},
    bookLinks:[],
    bookLinkHeaders: [
      { text: "id", value: "id", width: 10 },
      { text: "Название", value: "linkName" , sortable: true, width: 200 },
      { text: "Адрес", value: "linkBody", sortable: true,  width:650},
      { text: "Действие", value: "actions", width: 70},
    ],



    bookDialog:false,
    authorDialog: false,
    tagDialog:false,

    bookSearchBody:'',
    authorSearchBody:'',
    tagSearchBody:'',
    bookLinkDialog:false,
    linkName:'',
    linkBody:'',



    tags:[],
    filteredTags:[],

    books:[],
    filteredBooks:[],

    authors:[],
    filteredAuthors:[],

    tagName: '',
    tagNameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],
    active: false,

    tagHeaders: [
      { text: "id", value: "id", width: 10 },
      { text: "Ярлык", value: "tagName" , sortable: true, width: 750 },
      { text: "Активность", value: "active", sortable: true,  width:70},
      { text: "Действие", value: "actions", width: 70},
    ],




    authorList:[],
    bookTags:[],
    published:false,
    ISBN:'',
    ISBNRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],

    bookAnnotation:'',
    bookAnnotationRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],

    bookName:'',
    bookNameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],


    bookHeaders: [
      { text: "id", value: "id", width: 10 },
      { text: "Название", value: "bookName" , sortable: true},
      { text: "Аннотация", value: "bookAnnotation"},
      { text: "ISBN", value: "ISBN" , sortable: true},
     // { text: "Рейтинг", value: "bookRate"},
      { text: "Авторы", value: "authorList" , sortable: true},
      { text: "Теги", value: "tags" , sortable: true},
      { text: "Опубликовано", value: "published" , sortable: true},
     // { text: "Отзывы", value: "reviews" , sortable: true},
      { text: "Действие", value: "actions", width: 70},
    ],

    firstname:'',
    firstnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение не должно превышать 250 символов!',
    ],
    secname:'',
    secnameRules: [
      v => (!v ||(!!v && v.length <= 250)) || 'Значение не должно превышать 250 символов!',
    ],
    lastname:'',
    lastnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение не должно превышать 250 символов!',
    ],

    authorHeaders: [
      { text: "id", value: "id" },
      { text: "ФИО", value: "authorName" , sortable: true, width: 830 },
      { text: "Действие", value: "actions", width: 170},
    ],

    showLoader: false,
    id:'',

  }),
  methods: {
    openLink: function (item){
      window.open(item.linkBody, '_blank');
    },
    openBookLinks: function (item){
      this.selectedBook = item;
      customAxios.getWithAuth('bookshelf/book/link/'+item.id)
          .then(response => {
            if (response.status === 200) {
              this.bookLinks = response.data;
              this.tab = 4;
            }  else {
              alert("что то пошло не так")
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })

    },
    filterBooks: function (){
      const ss = this.bookSearchBody.toLowerCase();
      let tempBooks = this.books;

      this.filteredBooks =  tempBooks.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );

    },

    filterAuthors: function (){
      const ss = this.authorSearchBody.toLowerCase();
      let tempData = this.authors;

      this.filteredAuthors =  tempData.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );

    },
    filterTags: function (){
      const ss = this.tagSearchBody.toLowerCase();
      let tempData = this.tags;

      this.filteredTags =  tempData.filter((item) =>
          _.some(item, (v) => {
            if (!(v instanceof Object)) {
              return _.toLower(v).indexOf(ss) > -1;
            }
          })
      );

    },

    createBookLink(){
      this.id='';
      this.bookLinkDialog=true
    },

    editBookLink(item){
      this.id = item.id
      this.linkName = item.linkName
      this.linkBody= item.linkBody
      this.bookLinkDialog = true
    },

    clearBookLink(){
      this.id = ''
      this.linkName = ''
      this.linkBody= ''
      this.bookLinkDialog = false
    },
    deleteBookLink(item){
      customAxios.delete("bookshelf/book/link/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.bookLinks, item.id);
              this.bookLinks.splice(index, 1);
            } else if(response.status === 207){
              alert(response.data.message);
            }else {
              alert("что то пошло не так");
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },

    saveBookLink() {
      this.$refs.bookLinkForm.validate().then(responce =>{
        if(responce.valid){

          let bookLink = {
            linkName:capitalizeFirstLetter(this.linkName),
            linkBody:this.linkBody,
            bookId: this.selectedBook.id,
          }

          if (this.id) {
            customAxios.putWithAuth('bookshelf/book/link/' + this.id, bookLink)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.bookLinks, response.data.id)
                    this.bookLinks.splice(index, 1, response.data)
                    this.clearBookLink();
                  }  else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }else {
            customAxios.post('bookshelf/book/link', bookLink)
                .then(response => {
                  if (response.status === 201) {
                    this.bookLinks.push(response.data)
                    this.clearBookLink();
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })
    },


    createAuthor(){
      this.id='';
      this.authorDialog=true
    },
    editAuthor(item){
      this.id = item.id
      this.firstname = item.firstname
      this.secname= item.secname
      this.lastname = item.lastname
      this.authorDialog = true
    },
    clearAuthor(){
      this.id = ''
      this.firstname = ''
      this.secname= ''
      this.lastname = ''
      this.authorDialog = false
    },
    deleteAuthor(item){
      customAxios.delete("bookshelf/author/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.authors, item.id);
              this.authors.splice(index, 1);
            } else if(response.status === 207){
              alert(response.data.message);
            }else {
              alert("что то пошло не так");
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    saveAuthor() {
      this.$refs.AuthorForm.validate().then(responce =>{
        if(responce.valid){

          let author = {
            firstname:capitalizeFirstLetter(this.firstname),
            secname:capitalizeFirstLetter(this.secname),
            lastname:capitalizeFirstLetter(this.lastname),
          }

          if (this.id) {
            customAxios.putWithAuth('bookshelf/author/' + this.id, author)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.filteredAuthors, response.data.id)
                    this.filteredAuthors.splice(index, 1, response.data)
                    this.clearAuthor();
                  }  else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }else {
            customAxios.post('bookshelf/author', author)
                .then(response => {
                  if (response.status === 201) {
                    this.filteredAuthors.push(response.data)
                    this.clearAuthor();
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })
    },

    createBook(){
      this.id='';
      this.bookDialog=true
    },
    editBook(item){
      this.id = item.id
      this.published = item.published
      this.ISBN= item.ISBN
      this.bookAnnotation = item.bookAnnotation
      this.bookTags = item.tags
      this.authorList = item.authorList
      this.bookName = item.bookName
      this.bookDialog = true
    },
    clearBook(){
      this.id = ''
      this.published = false
      this.ISBN= ''
      this.bookAnnotation = ''
      this.bookName = ''
      this.bookDialog = false
      if(this.filteredAuthors.length>0){
        this.authorList = []
      this.authorList.push(this.filteredAuthors[0]);
      } else {
        this.authorList = []
      }
      if(this.filteredTags.length>0){
        this.bookTags = []
        this.bookTags.push(this.filteredTags[0]);
      } else {
        this.bookTags = []
      }
    },
    deleteBook(item){
      customAxios.delete("bookshelf/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.filteredBooks, item.id);
              this.filteredBooks.splice(index, 1);
            } else if(response.status === 207){
              alert(response.data.message);
            }else {
              alert("что то пошло не так");
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    saveBook() {
      this.$refs.BookForm.validate().then(responce =>{
        if(responce.valid){

          let book = {
            bookAnnotation:capitalizeFirstLetter(this.bookAnnotation),
            bookName:capitalizeFirstLetter(this.bookName),
            ISBN: this.ISBN,
            published: this.published,
            authorList : this.authorList,
            tags: this.bookTags,
          }

          if (this.id) {
            customAxios.putWithAuth('bookshelf/' + this.id, book)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.filteredBooks, response.data.id)
                    this.filteredBooks.splice(index, 1, response.data)
                    this.clearBook();
                  }  else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }else {
            customAxios.post('bookshelf', book)
                .then(response => {
                  if (response.status === 201) {
                    this.filteredBooks.push(response.data)
                    this.clearBook();
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })
    },

    createTag(){
      this.id='';
      this.tagDialog=true
    },
    editTag(item){
      this.id = item.id
      this.tagName = item.tagName
      this.active= item.active
      this.tagDialog = true
    },
    clearTag(){
      this.id = ''
      this.tagName = ''
      this.active= false
      this.tagDialog = false
    },
    deleteTag(item){
      customAxios.delete("bookshelf/tag/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.filteredTags, item.id);
              this.filteredTags.splice(index, 1);
            } else if(response.status === 207){
              alert(response.data.message);
            }else {
              alert("что то пошло не так");
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    saveTag() {
      this.$refs.TagForm.validate().then(responce =>{
        if(responce.valid){

          let tag = {
            tagName:capitalizeFirstLetter(this.tagName),
            active: this.active,
          }
          if (this.id) {
            customAxios.putWithAuth('bookshelf/tag/' + this.id, tag)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.filteredTags, response.data.id)
                    this.filteredTags.splice(index, 1, response.data)
                    this.clearTag();
                  }  else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }else {
            customAxios.post('bookshelf/tag', tag)
                .then(response => {
                  if (response.status === 201) {
                    this.filteredTags.push(response.data)
                    this.clearTag();
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })
    },
  },
  computed: {
    theme() {
      return theme
    },


    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    authentication() {
      return this.$store.state.authentication
    },

  },
  created: function () {
    this.showLoader=true

    function getBooks() {
      return customAxios.getWithAuth('bookshelf/all')
    }

    function getAuthors() {
      return customAxios.getWithAuth('bookshelf/author')
    }
    function getTags() {
      return customAxios.getWithAuth('bookshelf/tag/all')
    }


    Promise.all([
      getBooks(),
      getAuthors(),
      getTags(),
    ])
        .then(results => {
          if(results[0].status===200){
            this.books = results[0].data;
            this.filteredBooks = results[0].data;
          }
          if(results[1].status===200){
            this.authors = results[1].data;
            this.filteredAuthors = results[1].data;

            if(this.filteredAuthors.length>0){
              this.authorList = []
              this.authorList.push(this.filteredAuthors[0]);
            } else {
              this.authorList = []
            }

          }
          if(results[2].status===200){
            this.tags = results[2].data;
            this.filteredTags = results[2].data;
            if(this.filteredTags.length>0){
              this.bookTags = []
              this.bookTags.push(this.filteredTags[0]);
            } else {
              this.bookTags = []
            }

          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")
    });
  }
}
</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}

.v-dialog--fullscreen .v-overlay__content {
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100%;
  overflow-y: auto;
  top: 0;
  left: 0;
}

.img {
  max-width: none;
  width: 80%;
  margin: 20px;
}

.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.admin-lib {
  margin-bottom: 60px;

  &__checkboxies {
    text-align-last: left;
  }

  &__checkbox + .n-parametrs__checkbox {
    margin-top: 16px;
  }

  &__counter {
    position: absolute;
    top: 0;
    right: 0;
    background: $gradient;
    transform: translate(50%, -50%);
    border-radius: 50%;
    @include flex-center;
    min-width: 30px;
    min-height: 30px;
    padding: 4px;
    aspect-ratio: 1/1;
  }

  &__opener {
    @include button-default-small;
    display: inline-block;
    position: relative;
    padding: 8px 16px;
  }

  &__table_button {
    @include button-default-for-table;
    display: inline-block;
    position: relative;
    padding: 8px 16px;
  }


  svg {
    stroke: #fff;
  }

  &__parametrs {
    background-color: #f5f5f5;
    padding: 16px;
    margin-top: 16px;
    border-radius: 8px;
  }
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
