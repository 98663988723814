<template>
  <UserContentWrapper :title="t('change.password')" @back="$emit('back')">
    <div class="n-user-set-password">
      <AppBackButton
        :linkMode="true"
        link="/user/settings"
        class="n-user-set-password__back"
      />

      <AppInput
        v-model="vp$.password.$model"
        :label="t('password')"
        id="u-password"
        :class="setValidationStatusClass(vp$.password)"
        @blur="vp$.password.$touch"
        :element="vp$.password"
      />

      <AppButton
        class="n-user-set-password__save"
        :disabled="vp$.$invalid"
        @click="$emit('changePassword',{ password: password })"
      >
        {{ t("save") }}
      </AppButton>
    </div>
  </UserContentWrapper>
</template>

<script>
import AppInput from "@/elements/AppInput.vue";
import AppButton from "@/elements/AppButton";
import UserContentWrapper from "@/elements/UserContentWrapper.vue";

import { setValidationStatusClass } from "@/utils/validation";
import { t } from "@/utils/translate";
import { ref } from "vue";

import { useVuelidate } from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import AppBackButton from "@/elements/AppBackButton.vue";

export default {
  components: { AppInput, AppButton, UserContentWrapper, AppBackButton },
  emits: ['changePassword'],
  setup() {
    const password = ref("");

    const pRules = {
      password: { required, min: minLength(6) },
    };

    const vp$ = useVuelidate(pRules, {
      password: password,
    });

    return { password, t, vp$, setValidationStatusClass };
  },
};
</script>

<style lang="scss">
.n-user-set-password {
  &__save {
    @include button-default;
    margin-top: 30px;
  }
}
</style>
