<template>
  <div class="user-panel" v-if="user">
    <UserPreview :person="user" class="user-panel__user-preview" />

    <LinksList :links="panelUserLinks" />
    <LinksList v-if="isAdmin" :links="panelAdminLinks" />

    <ColoredButton @click="userExit">
      <AppIcon icon="exit-icon" />
      <span>{{ t("exit") }}</span>
    </ColoredButton>
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { ref } from "vue";
import { useUserStore } from "@/store/userStore";

import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { panelUserLinks, panelAdminLinks } from "@/app-data/static";

import UserPreview from "./UserPreview.vue";
import LinksList from "@/elements/LinksList.vue";
import AppIcon from "@/elements/AppIcon";
import ColoredButton from "@/elements/ColoredButton.vue";

export default {
  components: { AppIcon, UserPreview, LinksList, ColoredButton },
  setup() {
    const userStore = useUserStore();
    const { user, avatar, isAdmin } = storeToRefs(userStore);
    const {clearUser} = userStore
    const menuOpen = ref(false);
    const router = useRouter();

    function userExit() {
      clearUser()
      router.push({ path: "/auth/login" });
    }

    return {
      t,
      menuOpen,
      user,
      avatar,
      isAdmin,
      userExit,
      panelUserLinks,
      panelAdminLinks,
    };
  },
};
</script>

<style lang="scss">
.user-panel {
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;
  flex-shrink: 0;
  min-width: 300px;

  &__user-preview {
    margin-bottom: 30px;
  }

  button {
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    padding: 16px;
    min-width: 100%;
  }
}
</style>
