<template>
  <ul class="list n-programs">
    <li v-for="v,i in list" :key="v.id" class="n-programs__item" :ref="setItemsRef">
      <router-link to="/library">
        <AppImage :image="v.img" class="n-programs__img" />
        <div class="n-programs__cover" :style="{'animation-delay': `0.${i + 1}`}">
          <div class="n-programs__title">{{ v.title }}</div>
          <div class="n-programs__go">
            <span>{{ t("go.to.library") }}</span>
            <AppIcon icon="chevron-icon" />
          </div>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
import AppIcon from "@/elements/AppIcon";
import AppImage from "@/elements/AppImage";

import { t } from "@/utils/translate";
import { ref } from "vue";
import { useObserver } from "@/composables/useObserver";

export default {
  components: { AppIcon, AppImage },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const itemsRef = ref([])

    function setItemsRef(el) {
      itemsRef.value.push(el)
    }

    useObserver(itemsRef, (el) => {
      el.classList.add("animated")
    }, { threshold: 0.5 });


    return {
      setItemsRef,
      t,
    };
  },
};
</script>

<style lang="scss">
.n-programs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4px;

  &__cover {
    @include flex-center;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    background-color: rgba($text, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 1;
    color: #fff;
    text-align: center;
    padding: 8px;
    transition: all 0.2s;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  &__go {
    display: none;

    svg {
      stroke: #fff;
      margin-left: 8px;
    }
  }

  &__img {
    display: block;
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
    }
  }

  &__item {
    aspect-ratio: 1/1;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    opacity: 0;
    transition: all 0.3s;

    &:hover .n-programs__cover {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      background-color: $text;

      .n-programs__title {
        display: none;
      }

      .n-programs__go {
        display: flex;
        align-items: center;
      }
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
}

.animated.n-programs__item {
  opacity: 1;
  
  .n-programs__cover {
    animation: slide-fade-in 0.5s ease-in 0.2s 1 forwards;
  }
}
</style>