<template>
  <NModal @closeModal="$emit('closeModal')" class="video-modal">
    <AppTitle mode="h2" v-if="video.videoDescription">
      <template v-slot:title>{{ video.videoDescription }} </template>
    </AppTitle>
    <div class="video-modal__video">
      <Vue3VideoPlayer
        v-if="video.sourceHlsIndex"
        :src="`https://navigator.eurekacenter.ru/video/${video.sourceHlsIndex}`"
        :cover="
          video.posterName &&
          `https://navigator.eurekacenter.ru/video/${video.posterName}`
        "
        :core="HLSCore"
        :view-core="viewCore.bind(null, 'video')"
      />

      <Vue3VideoPlayer
        v-if="!video.sourceHlsIndex && video.externalLinkUrl"
        :src="`https://navigator.eurekacenter.ru/video/${video.externalLinkUrl}`"
        :cover="
          video.posterName &&
          `https://navigator.eurekacenter.ru/video/${video.posterName}`
        "
        :core="HLSCore"
        :view-core="viewCore.bind(null, 'video')"
      />
    </div>
  </NModal>
</template>

<script>
import NModal from "./NModal.vue";
import AppTitle from "@/elements/AppTitle.vue";

import { Vue3VideoPlayer } from "@cloudgeek/vue3-video-player";
import { t } from "@/utils/translate";
import { ref } from "vue";
import HLSCore from "@cloudgeek/playcore-hls";

export default {
  emits: ["closeModal"],
  components: { NModal, Vue3VideoPlayer, AppTitle },
  props: {
    video: {
      type: Object,
      reauired: true,
    },
  },
  setup() {
    const players = ref({});

    // videoSrc.value = "https://navigator.eurekacenter.ru/video" + v.sourceHlsIndex;
    //   posterSrc.value = "https://navigator.eurekacenter.ru/video" + v.posterName;
    //   videoDescription.value = v.videoDescription;

    function viewCore(id, player) {
      players.value[id] = player;
    }

    return { t, HLSCore, viewCore };
  },
};
</script>

<style lang="scss">
.video-modal {
  height: 100%;
  padding-bottom: 30px;

  h2 {
    text-align: left;
    color: #fff;
    margin-bottom: 16px;
  }

  &__video {
    max-width: 960px;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
    display: block;
  }
}
</style>
