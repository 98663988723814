<template>
  <div class="page-not-found wrapper">
    <router-link to="/" class="flex flex__ai-c page-not-found__link">
      <AppIcon icon="back-arrow-icon" />
      <span>Вернуться на главную</span>
    </router-link>

    <AppTitle mode="h3">
      <template v-slot:title>{{ t("nothing.found") }}...</template>
    </AppTitle>
  </div>
</template>

<script>
import AppIcon from "@/elements/AppIcon.vue";
import AppTitle from "@/elements/AppTitle.vue";
import { t } from "@/utils/translate";

export default {
  setup() {
    return { t };
  },
  components: { AppIcon, AppTitle },
};
</script>

<style lang="scss">
.page-not-found {
  @include page;
  min-height: 400px;

  &__link {
    @include link-button;
  }

  h3 {
    margin-top: 100px;
    text-align: center;
  }
}
</style>
