<template>
  <div class="videoFile-preview">
    <AppTitle mode="h4" class="videoFile-preview__title">
      <template v-slot:title>{{ t("video") }}</template>
    </AppTitle>

    <div class="videoFile-preview__content">
      <div class="videoFile-preview__subtitle">


        <AppTitle mode="h4">
          <template v-slot:title>{{ videoFile.videoDescription }}</template>
        </AppTitle>
      </div>

      <AppButton
          v-if="(videoFile.sourceHlsIndex || videoFile.externalLinkUrl)"
          @click="$emit('selectVideo', videoFile)"
          class="videoFile-preview__button"
      >
        <span> {{ t("play") }}</span>
      </AppButton>

      <AppButton v-else disabled>{{ t("unavailable") }}</AppButton>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/elements/AppTitle.vue";
import { t } from "@/utils/translate";
import AppButton from "@/elements/AppButton.vue";

export default {
  components: { AppTitle, AppButton },

  props: {
    videoFile: {
      type: Object,
      required: true,
    },
  },

  emits: ["selectVideo"],
  setup() {
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
.videoFile-preview {
  border-radius: 8px;
  overflow: hidden;
  margin: 30px 0;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: $shadow-1;

  &__title {
    color: #fff;
    padding: 16px;
    background-color: rgba($accent-1, 0.9);
  }

  &:nth-child(2n) {
    .videoFile-preview__title {
      background-color: rgba($accent-2, 0.9);
    }
  }

  &__content {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__subtitle {
    margin-right: 40px;
  }

  &__button {
    @include button-default;
    flex-shrink: 0;
  }

  a,
  p {
    padding: 8px 16px;
    @include link-default;
    line-height: 30px;
  }

  p:hover {
    color: $text;
    cursor: not-allowed;
  }

  &__status {
    svg {
      margin: 16px;
      stroke: rgba($text, 0.1);
    }

    svg.active {
      stroke: $accent-1;
    }
  }

  &.blocked {
    background-color: rgba($text, 0.1);
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    &__content {
      display: block;
    }

    &__subtitle {
      margin: 0;
      margin-bottom: 40px;
    }
  }
}
</style>
