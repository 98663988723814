<template>
  <div class="main-template">
    <div class="main-template__content">
      <NHeader :navList="navigation" @menu-open="menuIsOpen = true" />

      <router-view v-slot="{ Component }">
        <transition name="slide-from-right" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>

    <PageScroller v-if="scrollerShow" @click="scrollTop" />

    <NFooter class="main-template__footer" />

    <NPopUp
      v-if="isOpen"
      :msg="msg"
      :status="status"
      @popup-close="closePopup"
    />

    <transition name="fade">
      <div v-if="menuIsOpen" class="overlay" @click="menuIsOpen = false" />
    </transition>

    <transition name="slide100">
      <NSideMenu
        v-if="menuIsOpen"
        :navigation="mobileNavigation"
        class="main-template__menu"
        @menu-close="menuIsOpen = false"
      />
    </transition>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { t } from "@/utils/translate";

import NHeader from "@/components/NHeader";
import NFooter from "@/components/NFooter";
import NSideMenu from "@/components/NSideMenu.vue";
import NPopUp from "@/components/NPopUp.vue";

import { navigation, mobileNavigation } from "@/app-data/static";
import { usePopupStore } from "@/store/popupStore";

import { storeToRefs } from "pinia";

import { useRoute } from "vue-router";
import PageScroller from "@/elements/PageScroller.vue";

export default {
  components: { NHeader, NFooter, NSideMenu, NPopUp, PageScroller },
  setup() {
    const menuIsOpen = ref(false);
    const store = usePopupStore();
    const { isOpen, msg, status } = storeToRefs(store);
    const { closePopup } = store;
    const route = useRoute();

    watch(route, (n) => {
      if (n.path.includes("/user") || n.path.includes("/auth")) {
        window.document.body.style.backgroundColor = "#e7eaef";
      } else {
        window.document.body.style.backgroundColor = "#ffffff";
      }
    });

    const scrollerShow = ref(false);

    window.addEventListener("scroll", showPageScroller);

    function showPageScroller() {
      scrollerShow.value = window.pageYOffset >= window.document.body.clientHeight
    }

    function scrollTop() {
      window.document.body.scrollIntoView({ behavior: "smooth" });
    }

    return {
      scrollTop,
      mobileNavigation,
      scrollerShow,
      closePopup,
      isOpen,
      msg,
      status,
      menuIsOpen,
      t,
      navigation,
    };
  },
};
</script>

<style lang="scss">
.main-template {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__content {
    position: relative;
    flex-grow: 1;
  }

  &__footer {
    flex-shrink: 0;
  }

  &__menu {
    width: 50%;
  }

  @media (max-width: 768px) {
    &__menu {
      width: 90%;
    }
  }
}
</style>
