<template>
  <div class="courses-page">
    <PageTitle :title="t(`courses`)" />

    <StateWrapper
      :content="courses"
      :loadingStatus="loadingStatus"
      :checkLogin="false"
    >
      <div class="wrapper">
        <div class="courses-page__items">
          <CoursePreview
            :element="v"
            v-for="v in getSlicedArray(courses, itemsPerPage, currentPage)"
            :key="v.id"
            @toggleFavoriteStatus="toggleStatus(v)"
          />
        </div>

        <div class="flex flex__jc-c" v-if="courses.length">
          <vue-awesome-paginate
            :total-items="courses.length"
            v-model="currentPage"
            :items-per-page="itemsPerPage"
            :max-pages-shown="maxPagesShown"
          />
        </div>
      </div>
    </StateWrapper>
  </div>
</template>

<script>
import { customAxios } from "@/service/customAxios";
import { t } from "@/utils/translate";
import { ref, onMounted } from "vue";

import PageTitle from "@/elements/PageTitle.vue";
import CoursePreview from "@/components/CoursePreview.vue";
import StateWrapper from "@/components/StateWrapper.vue";

import { useCourseFavorite } from "@/composables/useCourseFavorite";
import { usePagination } from "@/composables/usePagination";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

export default {
  components: { CoursePreview, PageTitle, StateWrapper },
  setup() {
    const userStore = useUserStore();
    const { userToken } = storeToRefs(userStore);

    const courses = ref([]);
    const isLoggedin = ref(userToken.value);
    const { toggleFavoriteStatus } = useCourseFavorite();

    const currentPage = ref(1);
    const maxPagesShown = ref(5);
    const itemsPerPage = ref(10);
    const { getSlicedArray } = usePagination();

    const loadingStatus = ref("loading");

    async function getCourses() {
      try {
        if (isLoggedin.value) {
          const r = await customAxios.getWithAuth("course/active");
          loadingStatus.value = "loaded";
          return r.data;
        } else {
          const r = await customAxios.get("course/active");
          loadingStatus.value = "loaded";
          return r.data;
        }
      } catch (e) {
        loadingStatus.value = "error";
        // console.log(e);
      }
    }

    async function toggleStatus(v) {
      try {
        await toggleFavoriteStatus(v);
        courses.value = await getCourses();
      } catch (e) {
        // console.log(e);
      }
    }

    onMounted(async () => {
      courses.value = await getCourses();
    });

    return {
      loadingStatus,
      itemsPerPage,
      currentPage,
      maxPagesShown,
      getSlicedArray,
      toggleStatus,
      isLoggedin,
      courses,
      t,
    };
  },
};
</script>

<style lang="scss">
.courses-page {
  &__title {
    margin-bottom: 40px;
    text-align: center;
  }

  &__items {
    @include custom-grid;
  }

  .wrapper {
    @include page;
  }
}
</style>
